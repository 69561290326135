import { useStore } from '@nuxtjs/composition-api'

const useCategoryName = () => {
  const store = useStore()

  /**
   * カテゴリ名を取得する
   * 親カテゴリと子カテゴリのIDを受け取り、カテゴリ名を返す
   * @param parentCategoryId
   * @param childCategoryId
   */
  const getCategoryName = (parentCategoryId?: number, childCategoryId?: number): string => {
    if (!parentCategoryId || !childCategoryId) return ''

    // @ts-ignore
    const parentCategory = store.state.master.masterCategories.find(
      masterCategory => masterCategory.id === parentCategoryId
    )
    if (parentCategory.subCategories.length) {
      return parentCategory.subCategories.find(c => c.id === childCategoryId).name
    } else {
      return parentCategory.name
    }
  }

  return { getCategoryName }
}

export { useCategoryName }
