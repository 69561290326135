<template>
  <section class="c-recommendCategories">
    <ContentHeadline
      class="c-recommendCategories_headline"
      title-tag="h2"
      title-text="おすすめカテゴリ"
      :right-link="headlineLink"
    />
    <div
      class="c-recommendCategories_list"
      data-translate
    >
      <RecommendCategory
        v-for="category in filteredRecommendCategoriesList"
        :key="category.id"
        class="c-recommendCategories_item"
        v-bind="category"
      />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import ContentHeadline from '~/components/molecules/ContentHeadline'
import RecommendCategory from './RecommendCategory'

const DISPLAY_CATEGORY_COUNT = 8

export default {
  name: 'RecommendCategories',
  components: {
    ContentHeadline,
    RecommendCategory
  },
  props: {
    recommendCategoriesList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headlineLink: {
        text: 'すべてのカテゴリを見る',
        link: '/search',
        isNuxtLink: true
      }
    }
  },
  computed: {
    ...mapState('my/header', ['privateAccountInfo']),
    filteredRecommendCategoriesList() {
      let categories = this.recommendCategoriesList
      if (this.privateAccountInfo.isMzhc) {
        categories = categories.filter(category => category.isBusiness)
      }
      return categories.slice(0, DISPLAY_CATEGORY_COUNT)
    }
  }
}
</script>

<style lang="scss" scoped>
.c-recommendCategories {
  &_headline {
    margin-bottom: 16px;
    line-height: 1;
  }
  &_list {
    display: flex;

    justify-content: flex-start;
  }
  &_item {
    width: 12.5%;
  }
}

@media (max-width: breakpoint(Home, M)) {
  .c-recommendCategories {
    padding: 0 12px;
    &_list {
      flex-wrap: wrap;
    }
    &_item {
      width: 25%;
      &:nth-child(n + 5) {
        margin-top: 16px;
      }
    }
  }
}
</style>
