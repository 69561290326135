<template>
  <a
    :href="`${LEGAL_URL}/bbses/${legalQa.id}?cn_device=pc&amp;cn_clicked=ranking_02`"
    target="_blank"
    class="c-legalItem"
  >
    <div
      class="c-legalItem_rank"
      :class="`c-legalItem_rank-rank${itemNumber}`"
      v-if="isRanking"
    >
      {{ itemNumber }}
    </div>

    <div class="c-legalItem_image">
      <div
        class="c-legalItem_imageInner"
        v-if="!isSkeleton"
      >
        <img
          :src="`${LEGAL_URL}${legalQa.categoryThumbnailPath}`"
          :alt="legalQa.title"
          loading="lazy"
          decoding="async"
          class="c-legalItem_imageStatic"
        />
      </div>
    </div>
    <div class="c-legalItem_info">
      <div class="c-legalItem_title">
        {{ $util.truncate(legalQa.title, 19) }}
      </div>
      <div class="c-legalItem_categoryName c-legalItemCategoryName">
        <span>{{ legalQa.categoryName }}</span>
      </div>
    </div>
  </a>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'CarouselLegalItem',
  props: {
    legalQa: {
      type: Object,
      required: true
    },
    itemNumber: {
      type: Number,
      default: null
    },
    isRanking: {
      type: Boolean,
      default: false
    },
    isSkeleton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('constants', ['LEGAL_URL']),
    ...mapGetters('ui', ['isPC', 'isSP', 'isTB'])
  }
}
</script>
<style lang="scss" scoped>
@use 'sass:math';

// 画像アスペクト比
$image-aspect-ratio: math.div(106, 128);

.c-legalItem {
  @include link-effect;

  position: relative;
  display: block;
  overflow: hidden;
  width: 153px;
  border: 1px solid $ds2-color-gray-50;

  &_rank {
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $ds2-color-gray-50;
    color: $ds2-color-gray-600;
    text-align: center;
    font-size: 11px;
    line-height: 20px;

    &-rank1 {
      background: $color-gold-300;
      color: $color-white;
    }

    &-rank2 {
      background: $ds2-color-gray-400;
      color: $color-white;
    }

    &-rank3 {
      background: $color-orange-900;
      color: $color-white;
    }
  }

  &_image {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    background: $ds2-color-gray-25;

    &::after {
      display: block;
      padding-top: 100% * $image-aspect-ratio;
      width: 100%;
      content: '';
    }
  }
  &_imageInner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &_info {
    padding: 8px 4px;
  }

  &_title {
    overflow: hidden;
    margin-bottom: 4px;
    height: 26px;
    color: $ds2-color-gray-900;
    font-size: 11px;
    line-height: 13px;
  }

  &_categoryName {
    overflow: hidden;
    margin-top: 4px;
    height: 12px;
    color: $ds2-color-gray-400;
    font-size: 10px;
    line-height: 12px;
  }
}
</style>
