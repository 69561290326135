<template>
  <div
    class="d-input"
    :class="{
      'd-input-counterPositionRight': isCounterPositionRight,
      'd-input-counterShow': isShowTextCounter
    }"
  >
    <OInput
      v-bind="$attrs"
      v-on="$listeners"
      v-model="innerValue"
      :maxlength="maxInputLength"
      :type="inputType"
      :pattern="inputType === 'number' ? '\\d*' : null"
      :autocomplete="autocomplete"
      :is-error="isError"
      class="d-input_text"
      :class="{ 'd-input_text-error': isError }"
      :id="innerId"
      @blur="onBlur"
      @keydown.native.enter="emitEnterEvent"
      @compositionstart.native="composing = true"
      @compositionend.native="composing = false"
      :disabled="isDisabled"
    ></OInput>
    <small
      v-if="maxLength"
      class="help counter d-input_counter"
      :class="{
        'd-input_counter-right': isCounterPositionRight,
        'd-input_counter-error': isError
      }"
    >
      {{ valueLength }} / {{ maxLength }}
    </small>
  </div>
</template>
<script>
export const INPUT_TYPE = Object.freeze([
  'text',
  'email',
  'password',
  'textarea',
  'number',
  'tel',
  'search'
])
const PATTERN = Object.freeze([null, 'number'])
const AUTOCOMPLETE = Object.freeze(['on', 'off', 'new-password', 'one-time-code'])
const NEW_LINE_CODE_TYPE = Object.freeze(['LF', 'CRLF']) // 改行の文字数カウントの扱い（LF: 1文字扱い、CRLF: 2文字扱い）
const CONVERT_WIDTH_TYPE = Object.freeze([null, 'to-half', 'to-full']) // 全角、半角の変換(to-half: 半角へ変換、to-full: 全角へ変換)

export default {
  name: 'DInput',
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    maxInputLength: {
      // 入力制限する文字数
      type: Number,
      default: null
    },
    maxLength: {
      type: [Number, String],
      validator(v) {
        return v >= 0
      }
    },
    isTruncatable: {
      type: Boolean,
      default: false
    },
    isEnableEnterEvent: {
      type: Boolean,
      default: false
    },
    isShowTextCounter: {
      type: Boolean,
      default: false
    },
    inputType: {
      type: String,
      validator(v) {
        return INPUT_TYPE.includes(v)
      }
    },
    pattern: {
      // 入力文字種の制限
      type: String,
      default: null,
      validator(v) {
        return PATTERN.includes(v)
      }
    },
    autocomplete: {
      type: String,
      default: 'on',
      validator(v) {
        return AUTOCOMPLETE.includes(v)
      }
    },
    isError: {
      type: Boolean,
      default: false
    },
    changedValue: {
      // 定型文の挿入等で親コンポーネントから値を変更したいとき、これで渡す
      // modelの値を直接操作すると、文字数カウントやバリデーションが発火しないため
      type: String,
      default: ''
    },
    innerId: {
      type: String,
      default: null
    },
    isCounterPositionRight: {
      type: Boolean,
      default: false
    },
    newLineCodeType: {
      // 文字数カウントの表示がある為UXを考えると改行を1文字扱いとする'LF'に今後は統一していきたい（改行が'CRLF'で入力されてもリアルタイム変換される）
      // 2024年1月現在、改行を2文字扱いとしている箇所もある為、改行1文字扱いとするかは実装時にBE含めて要確認
      type: String,
      default: 'CRLF',
      validator(v) {
        return NEW_LINE_CODE_TYPE.includes(v)
      }
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    convertWidthType: {
      // 全角、半角の変換
      type: String,
      default: null,
      validator(v) {
        return CONVERT_WIDTH_TYPE.includes(v)
      }
    }
  },
  data() {
    return {
      newValue: this.value,
      composing: false
    }
  },
  watch: {
    changedValue() {
      this.innerValue = this.changedValue
    }
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.setInnerValue(val)
      }
    },
    valueLength() {
      let string = ''
      if (typeof this.newValue === 'string') {
        string = this.newValue
      } else if (typeof this.newValue === 'number') {
        string = this.newValue.toString()
      }
      const replaceNewLineCode = this.newLineCodeType === 'LF' ? '\n' : '\r\n'
      return string ? string.replace(/(\r\n|\r|\n)/g, replaceNewLineCode).length : 0
    }
  },
  methods: {
    emitEnterEvent() {
      // IME確定時のEnterは無視
      if (this.composing) {
        return
      }
      if (this.isEnableEnterEvent) {
        this.$emit('enter-clicked')
      }
    },
    setInnerValue(val) {
      let value = this.maxLength && this.isTruncatable ? val.substring(0, this.maxLength) : val

      if (this.pattern === 'number' && value.length) {
        // 数字のみ入力許可
        value = value.replace(/\D/g, '') // 数字以外削除
        value = parseInt(value, 10) || '' // 先頭の0を消す
        if (value === 0) {
          value = '' // 0は許可しない
        }
      }

      if (this.newLineCodeType === 'LF') value = value.replace(/(\r\n|\r|\n)/g, '\n')

      this.newValue = value
      // b-inputのemitの方が後で走り、上書きされるため
      this.$nextTick(function () {
        this.$emit('input', value)
      })
    },
    onBlur(e) {
      if (this.convertWidthType) {
        if (this.convertWidthType === 'to-half') {
          this.setInnerValue(this.$util.toHalfWidth(e.target.value))
        } else if (this.convertWidthType === 'to-full') {
          this.setInnerValue(this.$util.toFullWidth(e.target.value))
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.d-input {
  .control {
    ::v-deep .input {
      height: 48px;
      border: 1px solid $ds2-color-gray-200;
      border-radius: 4px;
      &:focus {
        border-color: $ds2-color-brand-primary;
        box-shadow: none;
      }
    }
    ::v-deep .textarea {
      height: auto;
      border: 1px solid $ds2-color-gray-200;
      border-radius: 4px;
      &:focus {
        border-color: $ds2-color-brand-primary;
        box-shadow: none;
      }
    }
  }
  &_text {
    ::v-deep .input {
      color: $ds2-color-gray-900;
    }
    &-error {
      ::v-deep .input {
        border-color: $ds2-color-alert !important;
      }
      ::v-deep .textarea {
        border-color: $ds2-color-alert !important;
      }
    }
  }
  &-counterPositionRight {
    position: relative;
  }
  &-counterShow {
    ::v-deep {
      .help {
        &.counter {
          &.is-invisible {
            visibility: visible !important;
          }
        }
      }
    }
  }
  &_counter {
    &-right {
      position: absolute;
      right: 0;
    }
    &-error {
      color: $ds2-color-alert !important;
    }
  }
}
</style>
