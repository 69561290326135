<template>
  <nuxt-link
    class="c-recommendCategory"
    :to="{ path: moreUrl, query: { ref: 'popular_categories' } }"
  >
    <img
      class="c-recommendCategory_icon"
      :src="imgSrc"
      :alt="name"
      loading="lazy"
      decoding="async"
    />
    <div class="c-recommendCategory_text">{{ getFormattedCategory() }}</div>
  </nuxt-link>
</template>

<script>
import { FORMATTED_CATEGORY } from '~/constants/category'

const CATEGORY_DIVINATION_ID = 3
const TEXT_CHAT_DIVINATION_CATEGORY_NAME = 'メッセージ・チャット占い'
const TEXT_CHAT_DIVINATION_CATEGORY_NAME_NEWLINE = 'メッセージ・\nチャット占い'

export default {
  name: 'RecommendCategory',
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    serviceKind: {
      type: Number,
      required: true
    },
    moreUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    getFormattedCategory() {
      if (
        this.id === CATEGORY_DIVINATION_ID &&
        this.serviceKind === 1 &&
        this.name === TEXT_CHAT_DIVINATION_CATEGORY_NAME
      ) {
        // category_id: 3、service_kind: 1のときは、
        // '占い'もしくは'メッセージ・チャット占い'がBEから返却されることがあるため個別に対応
        return TEXT_CHAT_DIVINATION_CATEGORY_NAME_NEWLINE
      }
      return FORMATTED_CATEGORY[this.id] ?? this.name
    }
  },
  computed: {
    imgSrc() {
      const imageFileName = this.serviceKind ? `${this.id}-${this.serviceKind}` : this.id
      try {
        return require(`~/assets/images/components/HomeMainContent/RecommendCategories/icon_category_${imageFileName}.svg`)
      } catch {
        return `${this.baseURL}/images/no_image_item.png`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$breakpoint-xs: 376px;

.c-recommendCategory {
  @include link-effect;

  display: flex;
  flex-direction: column;
  white-space: pre-wrap;

  align-items: center;
  &_icon {
    display: block;
    width: 60px;
    height: 60px;
  }
  &_text {
    margin-top: 8px;
    color: $color-gray-900;
    text-align: center;
    word-break: normal;
  }
}

@media (max-width: breakpoint(Home, M)) {
  .c-recommendCategory {
    &_text {
      margin-top: 10px;
      font-size: 12px;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .c-recommendCategory {
    &_text {
      font-size: calc(100vw * 11 / 375);
    }
  }
}
</style>
