<template>
  <div class="c-carouselRequest">
    <ContentHeadline
      class="c-carouselRequestTitle"
      :title-tag="titleTag"
      :title-icon="titleIcon"
      :title-text="title"
      :right-link="headlineLink"
    >
      <template v-slot:additionalTitle>
        <div class="c-contentHeadlineAdditionalTitle">
          <div class="c-contentHeadlineAdditionalTitle_isProvider">出品者向け</div>
          <client-only>
            <RequestTooltip
              v-if="isPC"
              class="c-tooltip"
            >
              <div
                class="c-tooltip_help"
                slot="body"
              >
                多数の公募されている依頼の中から、自分が興味を持ったものを選び提案することができます。
              </div>
            </RequestTooltip>
          </client-only>
        </div>
      </template>
    </ContentHeadline>
    <div class="c-carouselRequestContent">
      <CarouselRequestList
        :requests="requests"
        :current-date="currentDate"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import LinkRefProvider from '../mixins/LinkRefProvider'
import ContentHeadline from '~/components/molecules/ContentHeadline'
import CarouselRequestList from './CarouselRequestList'

export default {
  name: 'CarouselRequest',
  mixins: [LinkRefProvider],
  components: {
    ContentHeadline,
    CarouselRequestList,
    RequestTooltip: () => import('./RequestTooltip')
  },
  props: {
    requests: {
      type: Array,
      default: () => []
    },
    currentDate: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    titleTag: {
      type: String,
      default: 'div'
    },
    titleIcon: {
      type: String,
      default: ''
    },
    titleUrlMore: {
      type: String,
      default: ''
    },
    titleUrlMoreSp: {
      type: String,
      default: ''
    },
    urlMore: {
      type: String,
      default: ''
    },
    isNuxtLinkHeadline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headlineLink: {
        text: this.titleUrlMore,
        textSp: this.titleUrlMoreSp,
        link: this.urlMore,
        isNuxtLink: this.isNuxtLinkHeadline
      }
    }
  },
  computed: {
    ...mapGetters('ui', ['isPC'])
  }
}
</script>
<style lang="scss" scoped>
a {
  @include link-effect;
  color: $ds2-color-brand-secondary-700;
}

.c-carouselRequest {
  position: relative;
}

.c-contentHeadlineAdditionalTitle {
  &_isProvider {
    height: 24px;
    padding: 1px 8px;
    margin-left: 12px;
    font-size: 14px;
    background: $ds2-color-gray-25;
    display: inline-block;
    position: relative;
    top: -2px;
  }
}

.c-tooltip {
  display: inline-block;
  position: relative;
  top: 0;
  margin-left: 8px;

  &_help {
    font-size: 8px;
    font-weight: normal;
  }
}

.c-carouselRequestContent {
  height: 221px;
  margin-top: 12px;
  position: relative;
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-carouselRequest {
    margin-right: 0;

    &_swiper {
      padding: 16px 0 0 12px;
    }
  }

  .c-carouselRequestTitle {
    padding: 0 12px;
  }

  .c-carouselRequestContent {
    margin-top: 8px;
  }
}
</style>
