
import {
  defineComponent,
  ref,
  onMounted,
  nextTick,
  onBeforeUnmount,
  useContext
} from '@nuxtjs/composition-api'
import Swiper from 'swiper/dist/js/swiper.esm.bundle'

export default defineComponent({
  name: 'MzhcHeroAreaSwiper',
  setup() {
    const isReady = ref(false)
    const swiper = ref<{ destroy: () => void } | null>(null)
    const swiperElement = ref<HTMLElement>()

    const { $coconala } = useContext()

    const HERO_AREAS_LIST = [
      {
        title: 'IT・開発・プログラミング',
        image: require('~/assets/images/mzhc/categories/it_develop_banner.png'),
        url: '/categories/11?ref=hero_logo'
      },
      {
        title: 'デザイン',
        image: require('~/assets/images/mzhc/categories/design_banner.png'),
        url: '/categories/18?ref=hero_logo'
      },
      {
        title: 'ココナラ募集',
        image: require('~/assets/images/mzhc/hero_banner_job_matching.png'),
        url: $coconala.coconalaJobMatchingUrl('/client', { ref: 'hero_logo' })
      },
      {
        title: 'Webサイト・デザイン制作',
        image: require('~/assets/images/mzhc/categories/web-design_banner.png'),
        url: '/categories/22?ref=hero_logo'
      },
      {
        title: 'マーケティング・Web集客',
        image: require('~/assets/images/mzhc/categories/marketing_banner.png'),
        url: '/categories/16?ref=hero_logo'
      },
      {
        title: '動画・撮影・アニメーション',
        image: require('~/assets/images/mzhc/categories/movie_animation_banner.png'),
        url: '/categories/10?ref=hero_logo'
      },
      {
        title: 'ビジネス代行・アシスタント',
        image: require('~/assets/images/mzhc/categories/business_agency_banner.png'),
        url: '/categories/13?ref=hero_logo'
      },
      {
        title: 'コンサル・士業',
        image: require('~/assets/images/mzhc/categories/business_consulting_banner.png'),
        url: '/categories/27?ref=hero_logo'
      },
      {
        title: '音楽・ナレーション',
        image: require('~/assets/images/mzhc/categories/music_narration_banner.png'),
        url: '/categories/23?ref=hero_logo'
      },
      {
        title: 'ライティング・翻訳',
        image: require('~/assets/images/mzhc/categories/writing_translation_banner.png'),
        url: '/categories/19?ref=hero_logo'
      },
      {
        title: 'イラスト・モデリング・漫画',
        image: require('~/assets/images/mzhc/categories/illustration_comic_banner.png'),
        url: '/categories/9?ref=hero_logo'
      }
    ]

    const getSwiperOptions = () => {
      return {
        loop: true,
        slidesPerView: 'auto',
        loopedSlides: HERO_AREAS_LIST.length - 1,
        autoplay: true,
        pagination: {
          el: '.c-mzhcHeroAreaSwiper_swiperPagination'
        },
        navigation: {
          nextEl: '.c-mzhcHeroAreaSwiper_swiperButton-next',
          prevEl: '.c-mzhcHeroAreaSwiper_swiperButton-prev',
          disabledClass: 'c-swiper-button-disabled'
        },
        centeredSlides: false,
        on: {
          init: () => {
            isReady.value = true
          }
        },
        breakpoints: {
          959: {
            centeredSlides: true,
            loopedSlides: HERO_AREAS_LIST.length
          }
        }
      }
    }

    onMounted(() => {
      nextTick(() => {
        swiper.value = new Swiper(swiperElement.value, getSwiperOptions())
      })
    })

    onBeforeUnmount(() => {
      // swiper初期化前に遷移するとエラーになるのでOptional Chainingにする
      swiper.value?.destroy()
    })

    return {
      HERO_AREAS_LIST,
      isReady,
      swiper,
      swiperElement,
      getSwiperOptions
    }
  }
})
