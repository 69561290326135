<template>
  <div class="c-carouselService">
    <ContentHeadline
      class="c-carouselServiceTitle"
      :title-tag="titleTag"
      :title-icon="titleIcon"
      :title-text="title"
      :title-url="titleUrl"
      is-nuxt-link-title
      :right-link="headlineLink"
    />

    <div class="c-carouselServiceContent">
      <CarouselServiceList
        :services="services"
        :is-ranking="isRanking"
      />
    </div>
  </div>
</template>
<script>
import LinkRefProvider from '../mixins/LinkRefProvider'
import ContentHeadline from '~/components/molecules/ContentHeadline'
import CarouselServiceList from './CarouselServiceList'

export default {
  name: 'CarouselService',
  mixins: [LinkRefProvider],
  components: {
    ContentHeadline,
    CarouselServiceList
  },
  props: {
    services: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    titleTag: {
      type: String,
      default: 'div'
    },
    titleIcon: {
      type: String,
      default: ''
    },
    titleUrl: {
      type: String,
      default: ''
    },
    titleUrlMore: {
      type: String,
      default: ''
    },
    urlMore: {
      type: String,
      default: ''
    },
    isRanking: {
      type: Boolean,
      default: false
    },
    isNuxtLinkHeadline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      startSwiperInit: false,
      isSwiperMounted: false
    }
  },
  computed: {
    headlineLink() {
      let headlineLink = null
      if (this.titleUrlMore && this.urlMore) {
        headlineLink = {
          text: this.titleUrlMore,
          link: this.urlMore,
          isNuxtLink: this.isNuxtLinkHeadline
        }
      }
      return headlineLink
    }
  }
}
</script>
<style lang="scss" scoped>
a {
  @include link-effect;
  color: $ds2-color-brand-secondary-700;
}

.c-carouselService {
  position: relative;
}

.c-carouselServiceContent {
  position: relative;
  margin-top: 12px;
  min-height: 208px;
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-carouselService {
    margin-right: 0;

    &_swiper {
      padding: 16px 0 0 12px;
    }
  }

  .c-carouselServiceTitle {
    padding: 0 12px;
  }

  .c-carouselServiceContent {
    margin-top: 8px;
    min-height: 192px;
  }
}
</style>
