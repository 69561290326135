
import { computed, defineComponent, toRefs } from '@nuxtjs/composition-api'

// TODO:案件マッチング: request-budget-label.tsのhookを使ってロジックを共通化する

const BUDGET_TYPE = {
  MAX_LIMIT: 'max_limit',
  OLD_MAX_LIMIT: 'old_max_limit',
  RANGE: 'range',
  OLD_ABOVE: 'old_above',
  CONSULTATION: 'consultation',
  LIMIT: 'limit'
}

// 歴史的な理由（公開募集改修前のデータ）により、予算の「〇〇円以内」はprice_min=nullではなく500で登録されている
const OLD_MIN_PRICE = 500

export default defineComponent({
  props: {
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const { min, max } = toRefs(props)

    const budgetType = computed(() => {
      if (min.value === 0 && max.value === 0) return BUDGET_TYPE.CONSULTATION
      if (min.value === max.value) return BUDGET_TYPE.LIMIT
      // 旧データ判定用
      if (min.value === OLD_MIN_PRICE || max.value === OLD_MIN_PRICE)
        return BUDGET_TYPE.OLD_MAX_LIMIT
      // 旧データ判定用
      if (max.value === 0) return BUDGET_TYPE.OLD_ABOVE
      if (min.value === 0) return BUDGET_TYPE.MAX_LIMIT
      return BUDGET_TYPE.RANGE
    })

    return { budgetType, BUDGET_TYPE }
  }
})
