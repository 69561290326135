<template>
  <div
    class="c-coconalaNews"
    :class="{ 'c-coconalaNews-isBusiness': isBusiness }"
  >
    <ul>
      <li
        v-for="(newsItem, index) in news"
        :key="`news${index}`"
        class="c-coconalaNews_newsItem"
      >
        <a
          :href="`/news/${newsItem.id}`"
          class="c-coconalaNews_link"
        >
          <div class="c-coconalaNews_date">
            {{ $util.dayjs.unix(newsItem.date).format('YYYY.MM.DD') }}
          </div>
          <div class="c-coconalaNews_name">{{ newsItem.name }}</div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CoconalaNews',
  props: {
    news: {
      type: Array,
      default: () => []
    },
    isBusiness: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-coconalaNews {
  min-height: 219px;
  border-top: 1px solid $ds2-color-gray-100;
  border-bottom: 1px solid $ds2-color-gray-100;
  &_newsItem {
    &:not(:first-of-type) {
      border-top: 1px dotted $ds2-color-gray-50;
    }
  }
  &_link {
    @include link-effect;

    display: flex;
    padding: 12px 0;
    font-size: 12px;
  }
  &_date {
    flex: 0 0 84px;
    margin-right: 4px;
    color: $ds2-color-gray-600;
  }
  &_name {
    flex: 1 1 auto;
    color: $ds2-color-gray-900;
  }
  &-isBusiness {
    border: none;
    .c-coconalaNews {
      &_newsItem {
        border-top: 1px solid $ds2-color-gray-50;
        &:last-of-type {
          border-bottom: 1px solid $ds2-color-gray-50;
        }
      }
      &_link {
        display: inline-flex;
        padding: 16px 0;

        align-items: center;
        &:hover {
          opacity: 1;
        }
      }
      &_date {
        flex: auto;
        margin-right: 20px;
        color: $ds2-color-gray-400;
        font-weight: bold;
      }
      &_name {
        color: $ds2-color-gray-900;
        text-decoration: underline;
        font-size: 14px;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
</style>
