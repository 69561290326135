var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('OModal',_vm._b({staticClass:"d-modal",class:{
    'd-modal-full': _vm.isSpFullScreen,
    'd-modal-isRounded': _vm.isRounded,
    'd-modal-isOverflowVisible': _vm.isOverflowVisible,
    'd-modal-isBackgroundInvisible': _vm.isBackgroundInvisible,
    'd-modal-isOnGuide': _vm.isOnGuide
  },attrs:{"animation":_vm.animationName,"active":_vm.active,"content-class":"modal-content"},on:{"close":_vm.close,"after-leave":function($event){return _vm.$emit('after-leave')}}},'OModal',_vm.$attrs,false),[_c('div',{directives:[{name:"intersect-translate",rawName:"v-intersect-translate.once",modifiers:{"once":true}}],staticClass:"d-modal_title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.title, _vm.sanitizeOptions))}}),_vm._v(" "),_vm._t("after-title")],2),_vm._v(" "),(_vm.$slots.text)?_c('p',{staticClass:"d-modal_textBelowTitle"},[_vm._t("text")],2):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"intersect-translate",rawName:"v-intersect-translate.once",modifiers:{"once":true}}],staticClass:"d-modal_contentWrapper",attrs:{"id":"modal_contentWrapper"}},[_c('div',{staticClass:"d-modal_content"},[_vm._t("default")],2)]),_vm._v(" "),(_vm.hasBackButton)?_c('button',{staticClass:"d-modal_backButton",on:{"click":_vm.back}},[_c('CoconalaIcon',{staticClass:"d-modal_backButtonIcon",attrs:{"name":"arrow-back","size":"20px"}})],1):_vm._e(),_vm._v(" "),(this.$slots.stickyButtonsFooter)?_c('div',{directives:[{name:"intersect-translate",rawName:"v-intersect-translate.once",modifiers:{"once":true}}],staticClass:"d-modal_footerWrapper"},[_c('div',{staticClass:"d-modal_footer"},[_vm._t("stickyButtonsFooter")],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }