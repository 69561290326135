export const FORMATTED_CATEGORY: { [key: number]: string } = {
  2: '悩み相談・\n恋愛相談',
  5: '住まい・美容・\n生活・趣味',
  10: '動画・\nアニメーション',
  11: 'IT・開発\nプログラミング',
  12: '学習・就職・資格\nコーチング',
  13: 'ビジネス代行・\nアシスタント',
  16: 'マーケティング・\nWeb集客',
  17: 'マネー・副業・\nアフィリエイト',
  19: 'ライティング・\n翻訳',
  22: 'Webサイト制作',
  23: '音楽・\nナレーション',
  26: 'オンライン\nレッスン'
} as const
