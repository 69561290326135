import GrpcAccessorBase from '~/api/accessors/grpc-accessor-base'
import grpcClient from '~/grpc/grpc-client'
import {
  GetInvitationStatusRequest,
  VerifyInvitationRequest
} from '~/stub/apigateway/business/business_registration_pb'
import { BusinessRegistrationService } from '~/stub/apigateway/business/business_registration_pb_service'

export class BusinessRegistrationAccessor extends GrpcAccessorBase {
  getInvitationStatus(invitationKey: string) {
    const request = new GetInvitationStatusRequest()
    request.setInvitationKey(invitationKey)

    return grpcClient({
      method: BusinessRegistrationService.GetInvitationStatus,
      request,
      metadata: this.getMetadata()
    })
  }

  verifyInvitation(invitationKey: string) {
    const request = new VerifyInvitationRequest()
    request.setInvitationKey(invitationKey)

    return grpcClient({
      method: BusinessRegistrationService.VerifyInvitation,
      request,
      metadata: this.getMetadata()
    })
  }
}
