
import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'

export const SERVICE_TYPES = ['', 'unitTime'] as const
type SERVICE_TYPES_TYPE = (typeof SERVICE_TYPES)[number]

export default defineComponent({
  name: 'DSimpleServiceCard',
  props: {
    imgPath: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    rating: {
      type: Number,
      required: true
    },
    price: {
      type: Number,
      required: true
    },
    type: {
      type: String as PropType<SERVICE_TYPES_TYPE>,
      default: ''
    }
  },
  setup(props) {
    const unit = computed(() => (props.type === 'unitTime' ? '円/時' : '円'))
    return { unit }
  }
})
