<template>
  <NuxtLink
    :to="{ path: `/users/${user.userId}`, query: linkRefs }"
    class="c-userItem"
  >
    <div class="c-userItemInfo">
      <div class="c-userItemInfo_left">
        <div class="c-userItemInfo_icon">
          <c-user-icon-v2
            :file-path="user.resizableProfileIcon"
            :size="42"
            :v-if="!isSkeleton"
          />
        </div>
        <div class="c-userItemInfo_label">
          <div
            class="c-userItemInfo_certificationIcon"
            v-if="user.isPro"
          >
            <ProIcon width="20px" />
          </div>
          <div
            class="c-userItemInfo_certificationIcon"
            v-else-if="user.isCertificationDivination"
          >
            <DivinationIcon width="20px" />
          </div>
          <div
            class="c-userItemInfo_labelLevel"
            v-if="user.providerLevel.value > 0"
          >
            <img
              :src="$coconala.levelIconURL(user.providerLevel.value)"
              alt=""
              loading="lazy"
              decoding="async"
            />
          </div>
        </div>
      </div>
      <div class="c-userItemInfo_right">
        <div class="c-userItemInfo_name">
          {{ $util.truncate(user.userName, 10) }}
        </div>
        <div class="c-userItemInfo_occupation">
          {{ user.occupation }}
        </div>
        <div class="c-userItemInfo_rating">
          <div
            class="c-userItemInfoRating"
            v-if="user.averageRating"
          >
            <coconala-icon
              name="star"
              class="c-userItemInfoRating_star"
            />
            <span class="c-userItemInfoRating_score">
              {{ user.averageRating.toFixed(1) }}
            </span>
          </div>
          <div
            class="c-userItemInfoRating"
            v-else
          >
            <coconala-icon
              name="star"
              class="c-userItemInfoRating_star"
            />
            -
          </div>
          <div
            class="c-userItemInfoPaidOrderCount"
            v-if="user.paidOrderCount > 0"
          >
            ({{ user.paidOrderCount }})
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="user.userSpecialtiesList.length"
      class="c-userItemSpecialty"
      v-intersect-translate.once
    >
      <div class="c-userItemSpecialty_tag">{{ specialtyName }}</div>
    </div>
  </NuxtLink>
</template>
<script>
import ProIcon from '~/components/molecules/ProIcon'
import DivinationIcon from '~/components/molecules/Divination'

import { mapState } from 'vuex'
import LinkRefInjectee from '../mixins/LinkRefInjectee'

const SERVICE_CLASS_PHONE = 1

export default {
  name: 'CarouselUserItem',
  mixins: [LinkRefInjectee],
  components: {
    ProIcon,
    DivinationIcon
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    itemNumber: {
      type: Number,
      default: null
    },
    isSkeleton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      SERVICE_CLASS_PHONE
    }
  },
  computed: {
    ...mapState('master', ['masterCategories']),
    linkRefs() {
      const refs = {}
      if (this.linkRef) {
        refs['ref'] = this.linkRef
      }
      if (this.linkRefKind) {
        refs['ref_kind'] = this.linkRefKind
      }
      if (this.linkRefSort) {
        refs['ref_sort'] = this.linkRefSort
      }
      if (this.linkRefCategory) {
        refs['ref_category'] = this.linkRefCategory
      }
      if (this.itemNumber) {
        refs['ref_no'] = this.itemNumber
      }
      return refs
    },
    specialtyName() {
      if (!this.user.userSpecialtiesList.length) {
        return ''
      }
      const masterCategoryId = this.user.userSpecialtiesList[0].masterCategoryId
      return this.masterCategories.find(category => category.id === masterCategoryId)?.name
    }
  }
}
</script>
<style lang="scss" scoped>
.c-userItem {
  @include link-effect;

  position: relative;
  display: block;
  overflow: hidden;
  padding: 12px;
  width: 231px;
  height: 129px;
  border: 1px solid $ds2-color-gray-50;
}

.c-userItemInfo {
  display: flex;
  color: $ds2-color-gray-900;
  font-size: 12px;

  &_right {
    margin-left: 8px;
  }

  &_icon {
    overflow: hidden;
    width: 42px;
    height: 42px;

    flex-basis: 42px;

    img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }
  }

  &_label {
    display: flex;
    margin-top: 4px;

    justify-content: center;
  }

  &_certificationIcon {
    overflow: hidden;
    margin-right: 4px;
  }

  &_labelLevel {
    display: inline-flex;
    overflow: hidden;
    width: 20px;
    min-height: 20px;
    align-items: center;

    img {
      width: 20px;
    }
  }

  &_name {
    font-weight: bold;
  }

  &_occupation {
    overflow: hidden;
    margin-top: 4px;
    height: 26px;
    font-size: 11px;
    line-height: 120%;
  }

  &_rating {
    display: flex;

    align-items: center;
  }
}

.c-userItemInfoRating {
  display: flex;
  margin-right: 4px;

  align-items: center;

  &_star {
    margin-right: 4px;
    color: $ds2-color-star;
    font-size: 12px;
  }

  &_score {
    color: $ds2-color-star;
  }
}

.c-userItemInfoPaidOrderCount {
  color: $ds2-color-gray-900;
  font-size: 11px;
}

.c-userItemSpecialty {
  margin-top: 4px;
  color: $ds2-color-gray-900;
  font-size: 11px;

  &_tag {
    display: inline-block;
    overflow: hidden;
    padding: 4px 8px;
    max-width: 200px;
    border: 1px solid $ds2-color-gray-50;
    border-radius: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
}
</style>
