// package: coconala.protobuf.gw.user_attribute
// file: gw/user_attribute/user_attribute_service.proto

import * as gw_user_attribute_user_attribute_service_pb from "../../gw/user_attribute/user_attribute_service_pb";
import * as google_protobuf_wrappers_pb from "google-protobuf/google/protobuf/wrappers_pb";
import * as domain_enumeration_entry_form_purpose_type_pb from "../../domain/enumeration/entry_form_purpose_type_pb";
import * as domain_enumeration_entry_form_organization_type_pb from "../../domain/enumeration/entry_form_organization_type_pb";
import * as domain_enumeration_entry_form_order_format_pb from "../../domain/enumeration/entry_form_order_format_pb";
import * as domain_enumeration_entry_form_contract_style_pb from "../../domain/enumeration/entry_form_contract_style_pb";
export class UserAttributeService {
  static serviceName = "coconala.protobuf.gw.user_attribute.UserAttributeService";
}
export namespace UserAttributeService {
  export class GetCurrentUserAttribute {
    static readonly methodName = "GetCurrentUserAttribute";
    static readonly service = UserAttributeService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_user_attribute_user_attribute_service_pb.GetCurrentUserAttributeRequest;
    static readonly responseType = gw_user_attribute_user_attribute_service_pb.GetCurrentUserAttributeResponse;
  }
}
