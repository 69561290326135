<template>
  <NuxtLink
    :to="$coconala.rootPathUrl(`/requests/${request.requestId}`, linkRefs)"
    class="c-requestItem"
  >
    <div class="c-itemInfo">
      <div class="c-itemInfo_category">
        {{ getCategoryName(request.parentMasterCategoryId, request.childMasterCategoryId) }}
      </div>
      <div class="c-itemInfo_title">
        {{ request.title }}
      </div>
    </div>
    <div class="c-itemTile">
      <div class="c-itemTile_tile">
        <span class="c-itemTile_icon">
          <CoconalaIcon name="yen-circle" />
        </span>
        <span class="c-itemTile_caption">予算</span>
        <DRequestBudget
          class="c-itemTile_budget"
          :min="request.minBudget"
          :max="request.maxBudget"
        />
      </div>
      <div class="c-itemTile_tile">
        <span class="c-itemTile_icon">
          <CoconalaIcon name="users" />
        </span>
        <span class="c-itemTile_caption">応募者数</span>
        <span class="c-itemTileContent">
          {{ $util.numberWithDelimiter(request.proposalCount) }}
        </span>
      </div>
      <div class="c-itemTile_tile">
        <span class="c-itemTile_icon">
          <CoconalaIcon name="clock" />
        </span>
        <span class="c-itemTile_caption">募集期限</span>
        <span
          v-if="isFinished"
          class="c-itemTileContent"
        >
          <span>募集終了</span>
        </span>
        <span
          v-else
          class="c-itemTileContent"
        >
          <span v-if="calcRemainingDays(request.recruitmentDeadline) < 1">本日終了</span>
          <span v-else>あと {{ calcRemainingDays(request.recruitmentDeadline) }} 日</span>
          <span class="c-itemTileContent_remainingDate">
            （{{ $util.dayjs.unix(request.recruitmentDeadline).format('MMMDo') }}まで）
          </span>
        </span>
      </div>
    </div>
    <div class="c-itemBottom">
      <div class="c-itemInfoUser">
        <div class="c-itemInfoUser_icon">
          <CUserIconV2
            :file-path="request.resizableProfileIcon"
            :size="24"
          />
        </div>
        <div class="c-itemInfoUser_detail">
          <div class="c-itemInfoUser_name">
            {{ request.userName }}
          </div>
          <div class="c-itemInfoUser_info">
            <span>投稿日時：</span>
            <span :title="$util.dayjs.unix(request.created).format('LLLL')">
              {{ $util.dayjs.unix(request.created).from(currentDate) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>
<script>
import LinkRefInjectee from '../mixins/LinkRefInjectee'
import DRequestBudget from '~/components/organisms/DS2/DRequestBudget'

export default {
  name: 'CarouselRequestItem',
  mixins: [LinkRefInjectee],
  components: {
    DRequestBudget
  },
  props: {
    request: {
      type: Object,
      required: true
    },
    currentDate: {
      type: String,
      default: new Date().toGMTString()
    },
    itemNumber: {
      type: Number,
      default: null
    }
  },
  computed: {
    isFinished() {
      const currentUnixTime = this.$util.dayjs(this.currentDate).format('X')
      if (
        this.request.recruitmentDeadline &&
        this.request.recruitmentDeadline - currentUnixTime > 0
      ) {
        return false
      }
      return true
    }
  },
  methods: {
    getCategoryName(pid, cid) {
      if (!pid) {
        return ''
      }
      const parentCategory = this.$store.state.master.masterCategories.find(
        masterCategory => masterCategory.id == pid
      )
      if (parentCategory.subCategories.length) {
        return parentCategory.subCategories.find(c => c.id === cid).name
      } else {
        return parentCategory.name
      }
    },
    calcRemainingDays(date) {
      const diff = this.$util.dayjs.unix(date).diff(this.currentDate)
      return Math.floor(this.$util.dayjs.duration(diff).asDays())
    }
  }
}
</script>
<style lang="scss" scoped>
.c-requestItem {
  @include link-effect;

  position: relative;
  display: block;
  overflow: hidden;
  padding: 16px 16px 12px;
  width: 309px;
  height: 221px;
  border: 1px solid $ds2-color-gray-50;
  color: $ds2-color-gray-900;
}

.c-itemInfo {
  &_category {
    color: $ds2-color-gray-400;
    font-size: 11px;
  }

  &_title {
    overflow: hidden;
    margin-top: 4px;
    height: 36px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
  }
}

.c-itemTile {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  width: 100%;

  &_tile {
    display: flex;
    padding: 0;
    border-bottom: 1px solid $ds2-color-gray-25;
    background-color: $color-white;
    line-height: 28px;
  }

  &_icon {
    margin-right: 4px;
    color: $ds2-color-gray-600;

    flex-basis: 14px;
  }

  &_caption {
    overflow: hidden;
    margin-right: 28px;
    color: $ds2-color-gray-600;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;

    flex-basis: 50px;
  }

  &_budget {
    font-size: 11px;
    padding-top: 2px;
    line-height: 26px;

    ::v-deep .d-requestBudget {
      &_emphasis {
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
}

.c-itemTileContent {
  padding-top: 2px;
  font-weight: bold;
  font-size: 12px;
  line-height: 26px;

  &_remainingDate {
    font-weight: normal;
    font-size: 10px;
  }
}

.c-itemBottom {
  display: flex;
  margin-top: 8px;

  align-items: center;
  justify-content: space-between;
}

.c-itemInfoUser {
  display: flex;
  flex: 1 0 auto;

  &_icon {
    margin-right: 8px;

    flex-basis: 24px;

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  &_name {
    overflow: hidden;
    max-width: 200px;
    color: $ds2-color-gray-600;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 11px;
  }

  &_info {
    color: $ds2-color-gray-600;
    font-size: 10px;
  }
}

.c-itemInfoMore {
  font-size: 12px;

  flex-shrink: 0;
}
</style>
