import { useContext } from '@nuxtjs/composition-api'

export const useUserActionLog = () => {
  const { store, $sentry } = useContext()
  const addUserActionLog = (
    { action, detail = {} }: { action: string; detail?: object },
    sendViewId = true
  ) => {
    try {
      if (sendViewId) {
        detail['view_id'] = store.state.big_query.viewId
      }
      const params = {
        action,
        detailJson: JSON.stringify(detail)
      }
      return store.dispatch('big_query/log_action/addLogUserAction', params)
    } catch (e) {
      $sentry.captureException(e)
    }
  }

  return { addUserActionLog }
}
