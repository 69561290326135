<template>
  <div>
    <PrivateIndex />
  </div>
</template>
<script>
import PrivateIndex from '~/pages/privateIndex'

export default {
  name: 'Home',
  layout: 'default',
  components: {
    PrivateIndex
  }
}
</script>
