// package: home
// file: apigateway/home/home.proto

import * as apigateway_home_home_pb from "../../apigateway/home/home_pb";
import * as google_protobuf_wrappers_pb from "google-protobuf/google/protobuf/wrappers_pb";
import * as google_protobuf_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb";
import * as domain_enumeration_category_ranking_default_sort_pb from "../../domain/enumeration/category_ranking_default_sort_pb";
export class HomeService {
  static serviceName = "home.HomeService";
}
export namespace HomeService {
  export class GetHomeContent {
    static readonly methodName = "GetHomeContent";
    static readonly service = HomeService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_home_home_pb.GetHomeContentRequest;
    static readonly responseType = apigateway_home_home_pb.GetHomeContentReply;
  }
  export class GetMyFavoriteServices {
    static readonly methodName = "GetMyFavoriteServices";
    static readonly service = HomeService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_home_home_pb.GetMyFavoriteServicesRequest;
    static readonly responseType = apigateway_home_home_pb.GetMyFavoriteServicesReply;
  }
  export class GetMyServiceHistories {
    static readonly methodName = "GetMyServiceHistories";
    static readonly service = HomeService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_home_home_pb.GetMyServiceHistoriesRequest;
    static readonly responseType = apigateway_home_home_pb.GetMyServiceHistoriesReply;
  }
  export class GetMyRecommendRequests {
    static readonly methodName = "GetMyRecommendRequests";
    static readonly service = HomeService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_home_home_pb.GetMyRecommendRequestsRequest;
    static readonly responseType = apigateway_home_home_pb.GetMyRecommendRequestsResponse;
  }
}
