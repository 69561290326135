<template>
  <div
    v-if="!$translate.isTranslatableTarget()"
    class="c-sidebarBanners"
  >
    <a
      class="c-sidebarBanners_banner"
      href="/mypage/invite?ref=topleft_logo"
    >
      <img
        :src="bannerFileURL"
        alt="友達招待でポイントプレゼント"
        width="256"
        height="97"
        loading="lazy"
        decoding="async"
      />
    </a>
    <a
      class="c-sidebarBanners_banner"
      href="https://mag.coconala.com/?ref=pc_top_sidebar_mag"
      target="_blank"
      rel="noopener"
    >
      <img
        :src="require('~/assets/images/common/banner/mag.png')"
        alt="coconala mag"
        width="256"
        height="97"
        loading="lazy"
        decoding="async"
      />
    </a>
  </div>
</template>

<script>
import { isViralCampaign, isIncreasedAmountViralCampaign } from '~/components/logics/viral-campaign'

const SKELETON_SCREEN_URI =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

/** @type {import('vue').ComponentOptions} */
const sidebarBanners = {
  name: 'SidebarBanners',
  data() {
    return {
      SKELETON_SCREEN_URI,
      bannerFileURL: isViralCampaign()
        ? require('~/assets/images/common/banner/viral_campaign_banner_sidebar.png')
        : isIncreasedAmountViralCampaign()
        ? require('~/assets/images/common/banner/top_invite_bnr_side.png')
        : require('~/assets/images/common/banner/invite_banner.png')
    }
  }
}
export default sidebarBanners
</script>

<style lang="scss" scoped>
.c-sidebarBanners {
  &_banner {
    @include link-effect;

    display: block;
    background-color: $ds2-color-gray-25;
    & + & {
      margin-top: 12px;
    }
  }
}
</style>
