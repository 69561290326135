import { mapGetters } from 'vuex'

/** @type {import('vue').ComponentOptions} */
const LinkRefInjectee = {
  inject: {
    linkRef: { default: '' },
    linkRefKind: { default: '' },
    linkRefSort: { default: '' },
    linkRefCategory: { default: null }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    linkRefs() {
      const refs = {}
      if (this.linkRef) {
        refs['ref'] = this.linkRef
      }
      if (this.linkRefKind) {
        refs['ref_kind'] = this.linkRefKind
      }
      if (this.itemNumber) {
        refs['ref_no'] = this.itemNumber
      }
      if (!this.isLoggedIn) {
        return refs
      }

      // 以下はログイン中なら付与
      if (this.linkRefSort) {
        refs['ref_sort'] = this.linkRefSort
      }
      if (this.linkRefCategory) {
        refs['ref_category'] = this.linkRefCategory
      }

      return refs
    }
  }
}
export default LinkRefInjectee
