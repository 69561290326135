
import {
  defineComponent,
  reactive,
  ref,
  useContext,
  computed,
  useAsync,
  onMounted,
  onUnmounted,
  useRouter
} from '@nuxtjs/composition-api'
import useCustomFetch from '~/components/logics/use-custom-fetch'
import TheVueport from '~/components/organisms/TheVueport'
import DField from '~/components/molecules/DS2/DField'
import DInput from '~/components/atoms/DS2/DInput'
import DSelect from '~/components/atoms/DS2/DSelect'
import DRadio from '~/components/atoms/DS2/DRadio'
import DLoading from '~/components/atoms/DS2/DLoading'
import DButton from '~/components/atoms/DS2/DButton'
import DSuggest from '~/components/molecules/DS2/DSuggest'
import formMixin from '~/mixins/form-mixin'
import {
  requiredField,
  maxLength,
  phoneNumberRegister,
  noSymbolName,
  katakana,
  noSpace,
  isPast
} from '~/assets/javascripts/ds2/validators'
import RegisterAccessor, {
  ListIndustry,
  ListEmployeeNumber
} from '~/api/accessors/auth/register-accessor'
import { useUserActionLog } from '~/components/logics/user-action-log'
import { useImpressionLog } from '~/components/logics/impression-log'
import AttributeInfoAccessor, {
  FormInfoType
} from '~/api/accessors/attribute_info/attribute-info-accessor'
import preventSecession from '~/mixins/pages/prevent-secession'
import { PREF_BESIDES_JAPAN } from '~/components/organisms/RegisterBasicInfoForm/RegisterBasicInfoForm.vue'
import { AttributeInfoAcquisitionActionType } from '~/stub/domain/enumeration/attribute_info_acquisition_action_type_pb'
import { EntryFormPurposeType } from '~/stub/domain/enumeration/entry_form_purpose_type_pb'
import { EntryFormOrganizationType } from '~/stub/domain/enumeration/entry_form_organization_type_pb'
import { isSingleQuery } from '~/components/logics/utils'
import { EntryFormOrderFormat } from '~/stub/domain/enumeration/entry_form_order_format_pb'
import UserAttributeServiceAccessor from '~/api/accessors/user_attribute/user-attribute-accessor'
import { SelectItem } from '~/components/molecules/DS2/DRegisterSelectionCard/DRegisterSelectionCard.vue'
import DRegisterOrderBudget from '~/components/organisms/DS2/DRegisterOrderBudget'
import { useLocalStorage } from '~/components/logics/local-storage'
import { BusinessRegistrationAccessor } from '~/api/accessors/business/business-registration-accessor'
import {
  COOKIE_KEY_INVITATION_KEY,
  COOKIE_KEY_INVITATION_ALREADY_REGISTERED_ACCOUNT_KEY
} from '~/constants/business'
import type { GetInvitationStatusReply } from '~/stub/apigateway/business/business_registration_pb'
import type { GetCurrentUserAttributeResponseType } from '~/api/accessors/user_attribute/user-attribute-accessor'
import { APP_TRIGGER_REGISTRATION_BUYER_CORPORATE_COMPLETE } from '~/constants/urls'

import { useSearchCorporates } from '~/components/logics/search-corporates-hooks'

export const ATTRIBUTE_PRIVATE_TO_CORPORATE_MODAL_LOCAL_STORAGE_KEY =
  'attribute_private_to_corporate_modal'
export const ATTRIBUTE_PRIVATE_TO_CORPORATE_MODAL_LOCAL_STORAGE_VALUE = 'already_show'

const MAX_NAME_LENGTH = 45
const MAX_CORPORATE_LENGTH = 50
const ACTION_TYPE = {
  [AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_A]: 'a',
  [AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_B]: 'b',
  [AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_C]: 'c',
  [AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_D]: 'd'
}

const BUYER_TYPES: SelectItem[] = [
  {
    id: EntryFormOrderFormat.ENTRY_FORM_ORDER_FORMAT_SINGLE,
    title: '都度発注'
  },
  {
    id: EntryFormOrderFormat.ENTRY_FORM_ORDER_FORMAT_SUBCONTRACTING,
    title: '業務委託'
  },
  {
    id: EntryFormOrderFormat.ENTRY_FORM_ORDER_FORMAT_NOT_DETERMINED,
    title: 'まだ決まっていない'
  }
]

type closeDetailType = {
  purpose: EntryFormPurposeType.ENTRY_FORM_PURPOSE_TYPE_BUYER
  private_corporation: EntryFormOrganizationType.ENTRY_FORM_ORGANIZATION_TYPE_CORPORATION
  entered_last_name?: boolean
  entered_first_name?: boolean
  entered_last_kana_name?: boolean
  entered_first_kana_name?: boolean
  entered_dob_year?: boolean
  entered_dob_month?: boolean
  entered_dob_day?: boolean
  entered_prefecture?: boolean
  entered_sex?: boolean
  entered_phone_number?: boolean
  entered_corporate_name?: boolean
  entered_industry?: boolean
  entered_employee_number?: boolean
}

export default defineComponent({
  middleware: [
    'authenticated',
    ({ route, redirect, error, store }) => {
      const paramsActionType = Number(route.query.action_type)
      if (!Object.values(AttributeInfoAcquisitionActionType).includes(paramsActionType))
        redirect('/')
      if (store.state.my.header.privateAccountInfo.isMzhc) {
        return error({ statusCode: 404 })
      }
      if (
        store.state.auth.user.isBusiness &&
        [
          AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_C,
          AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_D
        ].includes(paramsActionType)
      ) {
        return error({ statusCode: 404 })
      }
    }
  ],
  name: 'UpdateBuyerCorporate',
  mixins: [formMixin, preventSecession],
  layout: 'register',
  head() {
    const head = {
      meta: [
        {
          name: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    }
    return {
      ...head
    }
  },
  components: {
    TheVueport,
    DField,
    DInput,
    DSelect,
    DRadio,
    DLoading,
    DButton,
    DRegisterOrderBudget,
    DSuggest,
    DAttentionMessageArea: () => import('~/components/molecules/DS2/DAttentionMessageArea'),
    DEnterpriseTransferConfirmModal: () =>
      import('~/components/organisms/DS2/DEnterpriseTransferConfirmModal')
  },
  computed: {
    birthdayErrorMessage() {
      const errorMessages = this.getErrorMessages('formInfo.year', this.isSubmitted).concat(
        this.getErrorMessages('formInfo.month', this.isSubmitted),
        this.getErrorMessages('formInfo.date', this.isSubmitted)
      )
      return this.$util.deleteDuplicateObj(errorMessages)
    },
    nameErrorMessage() {
      const errorMessages = this.getErrorMessages('formInfo.lastName', this.isSubmitted).concat(
        this.getErrorMessages('formInfo.firstName', this.isSubmitted)
      )
      return this.$util.deleteDuplicateObj(errorMessages)
    },
    nameKanaErrorMessage() {
      const errorMessages = this.getErrorMessages('formInfo.lastKanaName', this.isSubmitted).concat(
        this.getErrorMessages('formInfo.firstKanaName', this.isSubmitted)
      )
      return this.$util.deleteDuplicateObj(errorMessages)
    }
  },
  setup() {
    const { store, route, $util, $sentry, $toast, $cookies } = useContext()
    const router = useRouter()
    const isFromCoconalaApp = computed<boolean>(() => store.getters['auth/isFromCoconalaApp'])
    const { onInput, selectCorporateName, corporatesSuggestList } = useSearchCorporates()
    let actionType: AttributeInfoAcquisitionActionType =
      AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_UNSPECIFIED
    if (isSingleQuery(route.value.query.action_type)) {
      const paramsActionType = Number(route.value.query.action_type)
      if (Object.values(AttributeInfoAcquisitionActionType).includes(paramsActionType))
        actionType = paramsActionType
    }

    const isConfirmModalActive = ref<boolean>(false)

    const { addUserActionLog } = useUserActionLog()
    const detail = {
      purpose: EntryFormPurposeType.ENTRY_FORM_PURPOSE_TYPE_BUYER,
      private_corporation: EntryFormOrganizationType.ENTRY_FORM_ORGANIZATION_TYPE_CORPORATION
    }
    onMounted(() => {
      useAsync(() => {
        addUserActionLog({
          action: `view_current_corporation_information_input_${ACTION_TYPE[actionType]}`,
          detail
        })
      })
    })

    type BirthDayType = {
      year: number | null
      month: number | null
      date: number | null
    }
    const formInfo = reactive<FormInfoType & BirthDayType>({
      actionType,
      lastName: '',
      firstName: '',
      lastKanaName: '',
      firstKanaName: '',
      year: null,
      month: null,
      date: null,
      dob: null,
      prefecture: '',
      sex: '',
      corporateName: '',
      industryId: null,
      employeeNumberId: null,
      phoneNumber: '',
      orderFormat: null,
      budgetType: null,
      plannedTime: null
    })

    const isLoading = ref(false)
    const isSubmitted = ref(false)
    const isCompleted = ref(false)
    const hasInvitationStatus = ref(false)
    const industryList = ref<ListIndustry | []>([])
    const employeeNumberList = ref<ListEmployeeNumber | []>([])

    const isSelectedSingle = computed(
      () => formInfo.orderFormat === EntryFormOrderFormat.ENTRY_FORM_ORDER_FORMAT_SINGLE
    )
    const isSelectedSubcontracting = computed(
      () => formInfo.orderFormat === EntryFormOrderFormat.ENTRY_FORM_ORDER_FORMAT_SUBCONTRACTING
    )

    const selectedIndustryName = computed<string | null>(
      () => industryList.value.find(v => v.id === formInfo.industryId)?.name || null
    )
    const selectedEmployeeNumber = computed<string | null>(
      () => employeeNumberList.value.find(v => v.id === formInfo.employeeNumberId)?.name || null
    )

    const registerAccessor = new RegisterAccessor(store.state)
    const attributeInfoAccessor = new AttributeInfoAccessor(store.state)
    const userAttributeServiceAccessor = new UserAttributeServiceAccessor(store.state)
    const businessRegistrationAccessor = new BusinessRegistrationAccessor(store.state)
    const isAdmin = ref<boolean>(true)
    const enterpriseStaffRole = computed(() => store.getters['auth/user/ENTERPRISE_STAFF_ROLE'])

    useCustomFetch(async () => {
      await Promise.all([
        registerAccessor
          .fetchListIndustry()
          .then(result => {
            industryList.value = result
          })
          .catch(err => {
            $sentry.captureException(err)
          }),
        registerAccessor
          .fetchListEmployeeNumber()
          .then(result => {
            employeeNumberList.value = result
          })
          .catch(err => {
            $sentry.captureException(err)
          })
      ])
      if (
        [
          AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_A,
          AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_B
        ].includes(formInfo.actionType)
      ) {
        await attributeInfoAccessor
          .getInitialCorporationInformationBuyer()
          .then(result => {
            formInfo.lastName = result.lastName ?? ''
            formInfo.firstName = result.firstName ?? ''
            formInfo.lastKanaName = result.lastKanaName ?? ''
            formInfo.firstKanaName = result.firstKanaName ?? ''
            const timeObj = result.dob ? $util.dayjs.unix(result.dob) : null
            if (timeObj) {
              formInfo.year = timeObj.get('year') ?? null
              formInfo.month = Number.isInteger(timeObj.get('month'))
                ? timeObj.get('month') + 1
                : null
              formInfo.date = timeObj.get('date') ?? null
            }
            formInfo.corporateName = result.corporateName ?? ''
            formInfo.prefecture = result.prefecture ?? ''
            formInfo.sex = result.sex ?? ''
          })
          .catch(err => {
            $sentry.captureException(err)
          })
      } else {
        const getCurrentUserAttributeTask: Promise<GetCurrentUserAttributeResponseType | null> =
          userAttributeServiceAccessor.getCurrentUserAttribute().catch(err => {
            $sentry.captureException(err)
            return null
          })

        let getInvitationStatusTask: Promise<GetInvitationStatusReply.AsObject | null> =
          Promise.resolve(null)
        if (
          formInfo.actionType ===
          AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_D
        ) {
          const invitationKey = $cookies.get(COOKIE_KEY_INVITATION_KEY)
          if (invitationKey) {
            getInvitationStatusTask = businessRegistrationAccessor
              .getInvitationStatus(invitationKey)
              .then(response => {
                hasInvitationStatus.value = true
                return response
              })
              .catch(err => {
                $sentry.captureException(err)
                return null
              })
          }
        }

        const [currentUserAttribute, invitationStatus] = await Promise.all([
          getCurrentUserAttributeTask,
          getInvitationStatusTask
        ])
        if (currentUserAttribute) {
          formInfo.lastName = currentUserAttribute.lastName ?? ''
          formInfo.firstName = currentUserAttribute.firstName ?? ''
          formInfo.lastKanaName = currentUserAttribute.lastKanaName ?? ''
          formInfo.firstKanaName = currentUserAttribute.firstKanaName ?? ''
          const timeObj = currentUserAttribute.dob
            ? $util.dayjs.unix(currentUserAttribute.dob)
            : null
          if (timeObj) {
            formInfo.year = timeObj.get('year') ?? null
            formInfo.month = Number.isInteger(timeObj.get('month'))
              ? timeObj.get('month') + 1
              : null
            formInfo.date = timeObj.get('date') ?? null
          }
          formInfo.prefecture = currentUserAttribute.prefecture ?? ''
          formInfo.sex = currentUserAttribute.sex ?? ''
          formInfo.corporateName =
            invitationStatus?.enterpriseName ?? currentUserAttribute.corporateName ?? ''
          formInfo.industryId =
            invitationStatus?.industryId ?? currentUserAttribute.industryId ?? null
          formInfo.employeeNumberId =
            invitationStatus?.employeeNumberId ?? currentUserAttribute.employeeNumberId ?? null
          formInfo.phoneNumber = currentUserAttribute.phoneNumber ?? ''
          formInfo.orderFormat = currentUserAttribute.orderFormat || null
          formInfo.budgetType = currentUserAttribute.budgetType ?? null
          formInfo.plannedTime = currentUserAttribute.plannedTime ?? null
        }

        if (invitationStatus && invitationStatus.role === enterpriseStaffRole.value) {
          isAdmin.value = false
        }
      }
    })

    const currentYear = $util.dayjs().year()
    const birthYears = $util.range(currentYear - 100, currentYear).reverse()
    const birthMonths = $util.range(1, 12)
    const birthDays = computed(() => {
      return formInfo.year && formInfo.month
        ? new Date(formInfo.year!, formInfo.month!, 0).getDate()
        : 31
    })

    const prefList: string[] = [
      ...store.state.master.prefectures
        .filter(pref => pref.name !== 'その他')
        .map(pref => pref.name),
      PREF_BESIDES_JAPAN
    ]

    const saveCorporationInformationBuyer = async (): Promise<boolean> => {
      isLoading.value = true
      try {
        const enterpriseInvitationKey = hasInvitationStatus.value
          ? $cookies.get(COOKIE_KEY_INVITATION_KEY)
          : null
        formInfo.dob = $util
          .getDefaultTzDayjs(`${formInfo.year}/${formInfo.month}/${formInfo.date}`)
          .unix()
        await attributeInfoAccessor.saveCorporationInformationBuyer(
          formInfo,
          enterpriseInvitationKey
        )
        $cookies.remove(COOKIE_KEY_INVITATION_KEY)
        $cookies.remove(COOKIE_KEY_INVITATION_ALREADY_REGISTERED_ACCOUNT_KEY)
        return true
      } catch (error) {
        const message = $util.getNotifyErrorTitle(error.grpcCode, error.message)
        $toast.open({ type: 'error', message })
        $sentry.captureException(error)
        return false
      } finally {
        isLoading.value = false
        isConfirmModalActive.value = false
      }
    }

    const isLaterButtonClicked = ref(false)
    const skip = () => {
      isLaterButtonClicked.value = true
      window.close()
    }
    const sendActionLogForThanksPageOpen = () => {
      if (
        [
          AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_C,
          AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_D
        ].includes(formInfo.actionType)
      ) {
        addUserActionLog({
          action: `action_current_corporation_information_add_${ACTION_TYPE[actionType]}`,
          detail
        })
      }

      addUserActionLog({
        action: `view_current_corporation_information_thanks_${ACTION_TYPE[actionType]}`,
        detail
      })
    }

    const { addImpressionLog, addCloseImpressionLog } = useImpressionLog()
    const callAddCloseImpressionLog = () => {
      let action = ''
      let detail: closeDetailType = {
        purpose: EntryFormPurposeType.ENTRY_FORM_PURPOSE_TYPE_BUYER,
        private_corporation: EntryFormOrganizationType.ENTRY_FORM_ORGANIZATION_TYPE_CORPORATION
      }
      if (isCompleted.value) {
        action = `action_current_corporation_information_thanks_close_${ACTION_TYPE[actionType]}`
      } else {
        action = `action_current_corporation_information_${
          isLaterButtonClicked.value ? 'later' : 'close'
        }_${ACTION_TYPE[actionType]}`
        detail = {
          ...detail,
          entered_last_name: !!formInfo.lastName,
          entered_first_name: !!formInfo.firstName,
          entered_last_kana_name: !!formInfo.lastKanaName,
          entered_first_kana_name: !!formInfo.firstKanaName,
          entered_dob_year: !!formInfo.year,
          entered_dob_month: !!formInfo.month,
          entered_dob_day: !!formInfo.date,
          entered_prefecture: !!formInfo.prefecture,
          entered_sex: !!formInfo.sex,
          entered_phone_number: !!formInfo.phoneNumber,
          entered_corporate_name: !!formInfo.corporateName,
          entered_industry: !!formInfo.industryId,
          entered_employee_number: !!formInfo.employeeNumberId
        }
      }
      addCloseImpressionLog({ action, detail })
    }

    const moveToNextPage = () => {
      addImpressionLog({
        action: `action_current_corporation_information_thanks_interest_categories_click_${ACTION_TYPE[actionType]}`,
        detail: {
          purpose: EntryFormPurposeType.ENTRY_FORM_PURPOSE_TYPE_BUYER,
          private_corporation: EntryFormOrganizationType.ENTRY_FORM_ORGANIZATION_TYPE_CORPORATION
        }
      })
      router.push('/mypage/user_interest_categories')
    }

    const isIncludedActionType = (...actionTypes: AttributeInfoAcquisitionActionType[]) =>
      actionTypes.includes(formInfo.actionType)

    onMounted(() => {
      window.addEventListener('unload', callAddCloseImpressionLog)
    })

    onUnmounted(() => {
      window.removeEventListener('unload', callAddCloseImpressionLog)
    })

    return {
      MAX_CORPORATE_LENGTH,
      formInfo,
      isLoading,
      isSubmitted,
      isCompleted,
      industryList,
      employeeNumberList,
      selectedIndustryName,
      selectedEmployeeNumber,
      birthYears,
      birthMonths,
      birthDays,
      prefList,
      sexesRaw: store.state.master.sexesRaw,
      saveCorporationInformationBuyer,
      skip,
      sendActionLogForThanksPageOpen,
      moveToNextPage,
      BUYER_TYPES,
      AttributeInfoAcquisitionActionType,
      isSelectedSingle,
      isSelectedSubcontracting,
      isConfirmModalActive,
      hasInvitationStatus,
      isIncludedActionType,
      isFromCoconalaApp,
      isAdmin,
      selectCorporateName,
      corporatesSuggestList,
      onInput
    }
  },
  methods: {
    async submit() {
      this.isSubmitted = true

      if (!this.doValidate()) return

      if (
        this.formInfo.orderFormat === EntryFormOrderFormat.ENTRY_FORM_ORDER_FORMAT_NOT_DETERMINED
      ) {
        this.formInfo.budgetType = null
        this.formInfo.plannedTime = null
      }

      if (!(await this.saveCorporationInformationBuyer())) return
      if (
        this.formInfo.actionType ===
        AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_C
      ) {
        // Cakeのセッション更新
        await this.$axios({
          method: 'POST',
          url: '/transfer_account/to_business',
          // CakeにisAjax()判定してもらうため
          headers: {
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
      }
      this.isCompleted = true

      if (
        [
          AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_A,
          AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_B
        ].includes(this.formInfo.actionType)
      ) {
        const { localStorageUpdate } = useLocalStorage(
          ATTRIBUTE_PRIVATE_TO_CORPORATE_MODAL_LOCAL_STORAGE_KEY
        )
        localStorageUpdate(ATTRIBUTE_PRIVATE_TO_CORPORATE_MODAL_LOCAL_STORAGE_VALUE)
      }

      this.sendActionLogForThanksPageOpen()
      this.disablePreventSecession = true

      if (this.isFromCoconalaApp) {
        location.href = APP_TRIGGER_REGISTRATION_BUYER_CORPORATE_COMPLETE
      }
    },
    confirmForSubmit() {
      if (
        this.formInfo.actionType ===
        AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_D
      ) {
        this.isSubmitted = true
        // 結果として２度バリデーションしてしまうが確認モーダル表示前にバリデーションは通しておきたい
        if (!this.doValidate()) return
        this.isConfirmModalActive = true
      } else {
        this.submit()
      }
    }
  },
  validations() {
    const rules = {
      formInfo: {
        lastName: {
          required: requiredField(this.nameLabel),
          maxLength: maxLength(MAX_NAME_LENGTH),
          noSymbolName
        },
        firstName: {
          required: requiredField(this.nameLabel),
          maxLength: maxLength(MAX_NAME_LENGTH),
          noSymbolName
        },
        lastKanaName: {
          required: requiredField(this.nameLabelKana),
          katakana,
          noSpace,
          maxLength: maxLength(MAX_NAME_LENGTH)
        },
        firstKanaName: {
          required: requiredField(this.nameLabelKana),
          katakana,
          noSpace,
          maxLength: maxLength(MAX_NAME_LENGTH)
        },
        year: {
          requiredChoice: requiredField('生年月日'),
          isPast
        },
        month: {
          requiredChoice: requiredField('生年月日'),
          isPast
        },
        date: {
          requiredChoice: requiredField('生年月日'),
          isPast
        },
        prefecture: {
          requiredChoice: requiredField('都道府県')
        },
        sex: {
          requiredChoice: requiredField('性別')
        },
        corporateName: {
          required: requiredField('会社名/屋号'),
          maxLength: maxLength(MAX_CORPORATE_LENGTH)
        },
        industryId: {
          requiredChoice: requiredField('業界')
        },
        employeeNumberId: {
          requiredChoice: requiredField('従業員数')
        },
        phoneNumber: {
          required: requiredField('ご担当者電話番号'),
          phoneNumberRegister
        }
      }
    }

    if (
      this.isIncludedActionType(
        AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_C,
        AttributeInfoAcquisitionActionType.ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_D
      )
    ) {
      Object.assign(rules.formInfo, {
        orderFormat: {
          requiredChoice: requiredField('現在検討中の発注形式')
        },
        budgetType:
          this.formInfo.orderFormat !== EntryFormOrderFormat.ENTRY_FORM_ORDER_FORMAT_NOT_DETERMINED
            ? {
                requiredChoice: requiredField('発注予算')
              }
            : {},
        plannedTime:
          this.formInfo.orderFormat !== EntryFormOrderFormat.ENTRY_FORM_ORDER_FORMAT_NOT_DETERMINED
            ? {
                requiredChoice: requiredField('発注予定時期')
              }
            : {}
      })
    }

    return rules
  }
})
