// source: domain/enumeration/attribute_info_acquisition_action_type.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType', null, global);
/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.enumeration.AttributeInfoAcquisitionActionType = {
  ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_UNSPECIFIED: 0,
  ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_A: 1,
  ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_B: 2,
  ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_C: 3,
  ATTRIBUTE_INFO_ACQUISITION_ACTION_TYPE_D: 4
};

goog.object.extend(exports, proto.coconala.protobuf.domain.enumeration);
