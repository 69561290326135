// package: outsource
// file: gw/outsource/bookmark_service.proto

import * as gw_outsource_bookmark_service_pb from "../../gw/outsource/bookmark_service_pb";
import * as google_protobuf_wrappers_pb from "google-protobuf/google/protobuf/wrappers_pb";
export class BookmarkService {
  static serviceName = "outsource.BookmarkService";
}
export namespace BookmarkService {
  export class FetchBookmark {
    static readonly methodName = "FetchBookmark";
    static readonly service = BookmarkService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_outsource_bookmark_service_pb.FetchBookmarkDetailRequest;
    static readonly responseType = gw_outsource_bookmark_service_pb.FetchBookmarkDetailResponse;
  }
}
