// vuelidate.jsのカスタムバリデータ
import { withParams } from 'vuelidate'
import * as $v from 'vuelidate/lib/validators'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
dayjs.locale('ja')
dayjs.extend(advancedFormat)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)
dayjs.extend(duration)
import emojiRegex from 'emoji-regex'

export const required = $v.required
export const requiredIf = $v.requiredIf
export const requiredUnless = $v.requiredUnless
export const minValue = $v.minValue
export const maxValue = $v.maxValue
export const minYen = $v.minValue
export const maxYen = $v.maxValue
export const minNumLength = $v.minLength
export const maxNumLength = $v.maxLength
export const minArrayLength = $v.minLength
export const maxArrayLength = $v.maxLength
export const alpha = $v.alpha
export const numeric = $v.numeric
export const email = $v.email
export const sameAs = $v.sameAs
export const or = $v.or
export const and = $v.and
export const url = $v.url

export const requiredField = field =>
  withParams({ type: 'required', field }, value => {
    return required(value)
  })

/**
 * 改行コードの変換（デバイスによる改行コードの文字数カウントの差異をなくす為）
 *
 * 文字数カウントの表示があるUIを考えると改行を1文字扱いとする'LF'に今後は統一していきたい
 * 2024年3月現在、改行を2文字扱いとしている箇所もある為、改行1文字扱いとするかは実装時にBE含めて要確認
 * @param {'LF' | 'CRLF'} newLineCodeType 改行の文字数カウントの扱い（LF: 1文字扱い、CRLF: 2文字扱い）
 */
const replaceLineFeedCode = (value, newLineCodeType = 'CRLF') => {
  const replaceNewLineCode = newLineCodeType === 'LF' ? '\n' : '\r\n'
  return value.replace(/(\r\n|\r|\n)/g, replaceNewLineCode)
}

export const maxLength = (length, newLineCodeType = 'CRLF') =>
  withParams({ type: 'maxLength', max: length }, value => {
    if (!value) return false
    return $v.maxLength(length)(replaceLineFeedCode(value, newLineCodeType))
  })

export const minLength = (length, newLineCodeType = 'CRLF') =>
  withParams({ type: 'minLength', min: length }, value => {
    if (!value) return false
    return $v.minLength(length)(replaceLineFeedCode(value, newLineCodeType))
  })

export const between = (min, max) =>
  withParams({ type: 'between', min: min, max: max }, value => {
    if (!value) return false
    return $v.between(min, max)(value.length)
  })

export const regex = pattern =>
  withParams({ type: 'regex', pattern }, value => {
    if (!value) return false
    return new RegExp(pattern).test(value)
  })

export const phoneNumber = withParams({ type: 'phoneNumber' }, value => {
  if (!value) return true
  return new RegExp('^0\\d{1,4}-\\d{1,4}-\\d{4}$').test(value)
})

export const phoneNumberRegister = withParams({ type: 'phoneNumberRegister' }, value => {
  if (!value) return true
  return new RegExp('^\\d{10,11}$').test(value)
})

export const smsPhoneNumber = withParams({ type: 'smsPhoneNumber' }, value => {
  if (!value) return true
  return new RegExp('^(070|080|090)\\d{8}$').test(value)
})

export const date = withParams({ type: 'date' }, unixtime => {
  if (!unixtime) return true
  return unixtime <= dayjs().unix()
})

export const emoji = withParams({ type: 'emoji' }, text => {
  if (!text) return true
  return !emojiRegex().test(text)
})

export const emailDomain = domainName =>
  withParams({ type: 'emailDomain', domainName }, value => {
    if (!value || !value.includes('@')) {
      return false
    }
    const atIndex = value.indexOf('@')
    const domain = value.substring(atIndex + 1)
    if (domain !== domainName) {
      return false
    }
    return true
  })

// クレジットカード番号のチェックサム
export const luhnChecksum = withParams({ type: 'luhnChecksum' }, value => {
  // Luhnアルゴリズムによるチェック
  const digits = value.split('').reverse()
  let sum = 0
  let x = 0
  for (let i = 0; i < digits.length; i++) {
    x = digits[i] * (1 + (i % 2))
    sum += x > 9 ? x - 9 : x
  }
  if (sum % 10 !== 0) {
    return false
  }

  return true
})

// クレジットカードの過年度チェック
export const expire = withParams({ type: 'expire' }, value => {
  // 過年度チェック
  const now = new Date()
  const now_year = now.getFullYear()
  const now_month = ('00' + (now.getMonth() + 1)).slice(-2)
  return parseInt(value, 10) >= parseInt(now_year + '' + now_month, 10)
})

// 4バイト文字不可
export const char4Byte = withParams({ type: 'emoji' }, text => {
  if (!text) return true

  let includes4Byte = false
  for (var i = 0; i < text.length; i++) {
    try {
      // UTF-16コード1つ
      encodeURIComponent(text.charAt(i))
    } catch {
      try {
        // UTF-16コード2つ
        encodeURIComponent(text.substring(i, i + 2).charAt(1))
      } catch {
        // 4バイト文字はエラーになる
        includes4Byte = true
        break
      }
    }
  }
  return !includes4Byte
})

// 絵文字 + 4バイト文字不可
export const noEmojiAndChar4Byte = withParams({ type: 'emoji' }, text => {
  return char4Byte(text) || emoji(text)
})

export const alphaNum = withParams({ type: 'alphaNum' }, value => {
  if (!value) return false
  return $v.alphaNum(value)
})

export const katakana = withParams({ type: 'katakana' }, value => {
  if (!value) return true
  return new RegExp('^[ァ-ヶー　]*$').test(value)
})

// 0文字を許容する最大文字数バリデーション
export const anyMaxLength = length =>
  withParams({ type: 'anyMaxLength', max: length }, value => {
    if (!value) return true
    return $v.maxLength(length)(replaceLineFeedCode(value))
  })

// 0文字を許容する最小と最大文字数バリデーション
export const anyBetween = (min, max) =>
  withParams({ type: 'anyBetween', min: min, max: max }, value => {
    if (!value) return true
    return $v.between(min, max)(value.length)
  })

// 数値のみか（数値単位で表示してる場合）
export const numericYenWithNumericalUnit = (numericalUnitValue = 1) =>
  withParams({ type: 'numericYenWithNumericalUnit', numericalUnitValue }, value => {
    return numeric(Number(value) / numericalUnitValue)
  })

// 全角のみか
export const fullWidthCharacter = withParams({ type: 'fullWidthCharacter' }, value => {
  return new RegExp('^[^ -~｡-ﾟ]*$').test(value)
})

// 半角英数記号のみか
export const alphaNumSymbol = withParams({ type: 'alphaNumSymbol' }, value => {
  if (!value) return false
  return /^[a-zA-Z0-9!-/:-@[-^`_¥{-~]*$/.test(value)
})

// 英数混合しているか
export const alphaNumMix = withParams({ type: 'alphaNumMix' }, value => {
  if (!value) return false
  return /[a-zA-Z]/.test(value) && /[0-9]/.test(value)
})

// 記号不可（氏名用）
export const noSymbolName = withParams({ type: 'noSymbolName' }, value => {
  if (!value) return true
  return /^(?!.*[&"'<>/\\%_;])/.test(value) // XSSやSQLインジェクションの危険がある記号だけ弾く
})

// 記号不可（ユーザ名用）
export const noSymbolUserName = withParams({ type: 'noSymbolUserName' }, value => {
  if (!value) return false
  // ¥_は許可（本体のユーザ名の規則と合わせる）
  return /^(?!.*[!-/:-@[-^`{-~])/.test(value)
})

// サービス名不可（ユーザ名用）
export const noForbiddenWordUserName = withParams({ type: 'noForbiddenWordUserName' }, value => {
  if (!value) return false
  const FORBIDDEN_WORDS_IN_USER_NAME = ['coconala', 'ココナラ', 'ここなら']
  const userNameLowerCase = value.toLowerCase()
  return !FORBIDDEN_WORDS_IN_USER_NAME.some(word => userNameLowerCase.includes(word))
})

// 空白不可
export const noSpace = withParams({ type: 'noSpace' }, value => {
  if (!value) return true
  return new RegExp('^[^\\s　]+$').test(value)
})

// 空白のみ不可
export const noOnlyBlankSpaces = withParams({ type: 'noOnlyBlankSpaces' }, value => {
  if (!value) return false
  return !new RegExp('^(\\s|　)+$').test(value)
})

// 空白のみ不可(空文字は可)
export const noOnlyBlankSpacesAllowEmpty = withParams(
  { type: 'noOnlyBlankSpacesAllowEmpty' },
  value => {
    return !new RegExp('^(\\s|　)+$').test(value)
  }
)

// 親権者同意可否
export const isParentalConsent = withParams({ type: 'isParentalConsent' }, value => {
  if (!value) return false
  return true
})

// 過去日か否か
export const isPast = (value, vm) => {
  if (vm.year === null && vm.month === null && vm.day === null) return true

  const now = new Date()
  const nowYear = now.getFullYear()
  const nowMonth = now.getMonth() + 1
  const nowDay = now.getDate()

  if (vm.year != null && vm.year < nowYear) return true
  if (vm.year === nowYear && vm.month < nowMonth) return true
  if (vm.month === nowMonth && vm.day <= nowDay) return true

  return false
}

// 0文字を許容する特定文字数ちょうどかどうかバリデーション
export const anyJustLength = length =>
  withParams({ type: 'anyJustLength', length }, value => {
    if (!value) return true
    return value.length === length
  })

// 特定文字数（数字）ちょうどかどうかバリデーション
export const justNumericLength = (length, alertType) => {
  return withParams({ type: 'justNumericLength', length, alertType }, value => {
    if (!value) return true
    return value.length === length && /^\d*$/.test(value)
  })
}

// 入力されたクレジット番号が許可されているクレジットカードのものか
export const availableCardBrand = notAvailableCards =>
  withParams({ type: 'number', notAvailableCards }, value => {
    if (!notAvailableCards.length) return true

    let cardBrand = ''
    if (/^4[0-9]{15}$/.test(value)) {
      cardBrand = 'VISA'
    } else if (/^(2|5)[0-9]{15}$/.test(value)) {
      cardBrand = 'MASTER'
    } else if (/^35[0-9]{14}$/.test(value)) {
      cardBrand = 'JCB'
    } else if (/^(34|37)[0-9]{13}$/.test(value)) {
      cardBrand = 'AMEX'
    } else if (/^36[0-9]{12}$/.test(value)) {
      cardBrand = 'DINERS'
    }

    // 使用不可なクレジット番号が入力されていたらfalseを返す
    return !notAvailableCards.includes(cardBrand)
  })

export const betweenYen = (min, max) =>
  withParams({ type: 'betweenYen', min, max }, value => {
    if (!value) return false
    return Number(min) <= Number(value) && Number(value) <= Number(max)
  })

export const betweenYenWithNumericalUnit = (min, max, numericalUnit = '') =>
  withParams({ type: 'betweenYenWithNumericalUnit', min, max, numericalUnit }, value => {
    if (!value) return false
    return Number(min) <= Number(value) && Number(value) <= Number(max)
  })

export const betweenNum = (min, max, suffix) =>
  withParams({ type: 'betweenNum', min, max, suffix }, value => {
    return Number(min) <= Number(value) && Number(value) <= Number(max)
  })

// URLかどうか（BEと同じ正規表現を使うため、ライブラリで用意されている'url'と別に作る）
export const urlString = ({ required = true }) =>
  withParams({ type: 'urlString' }, value => {
    if (!required && !value) return true
    return new RegExp('^https?://.+$').test(value)
  })

// メールアドレスが含まれていないかどうかのチェック
export const notIncludeEmail = withParams({ type: 'notIncludeEmail' }, value => {
  return !/[a-zA-Z0-9._+-]+[@][a-zA-Z0-9.]+/.test(value)
})

// 電話番号が含まれていないかどうかのチェック
export const notIncludeTel = withParams({ type: 'notIncludeTel' }, value => {
  return !/[\D]*0\d{1,4}[\s-]\d{1,4}[\s-]\d{4}/.test(value)
})

export const youtubeUrl = withParams({ type: 'youtubeUrl' }, value => {
  return /^(https?:\/\/)?((www\.)?(m\.)?youtube\.com|youtu\.be)\/.+$/.test(value)
})
