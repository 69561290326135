const OUTSOURCE_WORKING_TYPE = {
  WEEK_ONE: {
    key: 'week_one',
    label: '1'
  },
  WEEK_TWO: {
    key: 'week_two',
    label: '2'
  },
  WEEK_THREE: {
    key: 'week_three',
    label: '3'
  },
  WEEK_FOUR: {
    key: 'week_four',
    label: '4'
  },
  WEEK_FIVE: {
    key: 'week_five',
    label: '5'
  },
  BIWEEKLY: {
    key: 'biweekly',
    label: '隔週'
  },
  MONTH_ONE: {
    key: 'month_one',
    label: '1'
  }
}

const PAYMENT_METHOD_TYPE = {
  HOURLY: {
    key: 'hourly',
    label: '時給'
  },
  MONTHLY: {
    key: 'monthly',
    label: '月'
  }
}

const WEEKLY_UNIT_ARRAY = [
  OUTSOURCE_WORKING_TYPE.WEEK_ONE,
  OUTSOURCE_WORKING_TYPE.WEEK_TWO,
  OUTSOURCE_WORKING_TYPE.WEEK_THREE,
  OUTSOURCE_WORKING_TYPE.WEEK_FOUR,
  OUTSOURCE_WORKING_TYPE.WEEK_FIVE
]

const MONTHLY_UNIT_ARRAY = [OUTSOURCE_WORKING_TYPE.MONTH_ONE]

export { OUTSOURCE_WORKING_TYPE, PAYMENT_METHOD_TYPE, WEEKLY_UNIT_ARRAY, MONTHLY_UNIT_ARRAY }
