<template>
  <div class="c-carouselUser">
    <ContentHeadline
      class="c-carouselUserTitle"
      :title-tag="titleTag"
      :title-icon="titleIcon"
      :title-text="title"
      :right-link="headlineLink"
    />

    <div class="c-carouselUserContent">
      <CarouselUserList :users="users" />
    </div>
  </div>
</template>
<script>
import LinkRefProvider from '../mixins/LinkRefProvider'
import ContentHeadline from '~/components/molecules/ContentHeadline'
import CarouselUserList from './CarouselUserList'

export default {
  name: 'CarouselUser',
  mixins: [LinkRefProvider],
  components: {
    ContentHeadline,
    CarouselUserList
  },
  props: {
    users: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    titleTag: {
      type: String,
      default: 'div'
    },
    titleIcon: {
      type: String,
      default: ''
    },
    titleUrlMore: {
      type: String,
      default: ''
    },
    urlMore: {
      type: String,
      default: ''
    },
    isNuxtLinkHeadline: {
      type: Boolean,
      default: false
    },
    position: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      headlineLink: {
        text: this.titleUrlMore,
        link: this.urlMore,
        isNuxtLink: this.isNuxtLinkHeadline
      }
    }
  }
}
</script>
<style lang="scss" scoped>
a {
  @include link-effect;
  color: $ds2-color-brand-secondary-700;
}

.c-carouselUser {
  position: relative;
}

.c-carouselUserContent {
  height: 129px;
  margin-top: 12px;
  position: relative;
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-carouselUser {
    margin-right: 0;

    &_swiper {
      padding: 16px 0 0 12px;
    }
  }

  .c-carouselUserTitle {
    padding: 0 12px;
  }

  .c-carouselUserContent {
    margin-top: 8px;
  }
}
</style>
