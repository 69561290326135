<template>
  <HomeCarousel
    class="c-carouselUserList"
    :slides-count="slidesCount"
  >
    <CarouselUserItem
      v-for="(user, index) in users"
      :key="`user${index}`"
      class="c-carouselUserList_item"
      :user="user"
      :item-number="index + 1"
    />
  </HomeCarousel>
</template>

<script>
import CarouselUserItem from './CarouselUserItem'
import HomeCarousel from './HomeCarousel'

const ITEMS_PER_SLIDE = 4

/** @type {import('vue').ComponentOptions} */
const carouselUserList = {
  name: 'CarouselUserList',
  components: {
    CarouselUserItem,
    HomeCarousel
  },
  props: {
    users: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    slidesCount() {
      return Math.ceil(this.users.length / ITEMS_PER_SLIDE)
    }
  }
}
export default carouselUserList
</script>

<style lang="scss" scoped>
.c-carouselUserList {
  width: 960px;
  height: 129px;

  &_item {
    width: 231px;
    margin-right: 9px;
    flex: 0 0 auto;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-carouselUserList {
    width: 100%;
  }
}
</style>
