<template>
  <section class="c-popularUseScenes">
    <content-headline
      class="c-popularUseScenes_headline"
      title-tag="h2"
      title-text="人気のご利用シーン"
    />
    <div
      class="c-popularUseScenes_pcContent"
      :class="{ 'c-popularUseScenes_pcContent-isMzhc': privateAccountInfo.isMzhc }"
    >
      <div class="c-popularUseScenes_list">
        <a
          class="c-popularUseScenes_item"
          v-for="(useScene, idx) in displayedUseScenesList"
          :key="idx"
          :href="useScene.url"
        >
          <img
            class="c-popularUseScenes_image"
            :src="getResizedImageUrl(useScene.image, 'pc')"
            :alt="useScene.title"
            loading="lazy"
            decoding="async"
          />
        </a>
      </div>
    </div>
    <div class="c-popularUseScenes_spContent">
      <PopularUseScenesSwiper
        :use-scenes-list="displayedUseScenesList"
        class="c-popularUseScenes_swiper"
        :key="`popularUseScenesSwiper-${displayedUseScenesList.length}`"
      />
    </div>
  </section>
</template>

<script>
import PopularUseScenesImageResizeMixin from './PopularUseScenesImageResizeMixin'
import PopularUseScenesSwiper from './PopularUseScenesSwiper'
import ContentHeadline from '~/components/molecules/ContentHeadline'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PopularUseScenes',
  mixins: [PopularUseScenesImageResizeMixin],
  components: {
    ContentHeadline,
    PopularUseScenesSwiper
  },
  data() {
    return {
      isSwiperReady: false
    }
  },
  computed: {
    ...mapGetters('ui', ['isSP', 'isTB']),
    ...mapGetters('master', ['displayedUseScenesList']),
    ...mapState('my/header', ['privateAccountInfo'])
  }
}
</script>

<style lang="scss" scoped>
.c-popularUseScenes {
  &_pcContent {
    display: block;
    overflow-x: hidden;
    width: 100%;
    height: 284px;
    &-isMzhc {
      height: 142px;
      .c-popularUseScenes_item {
        overflow: hidden;
        width: 183px;
        border: 1px solid $ds2-color-gray-100;
        border-radius: 8px;
      }
      .c-popularUseScenes_image {
        max-width: 230px;
        max-height: 130px;
        width: 230px;
        height: 130px;
      }
    }
  }
  &_spContent {
    position: relative;
    display: none;
    height: 184px;
  }
  &_list {
    display: flex;
    width: calc(100% + 20px);

    flex-wrap: wrap;
  }
  &_item {
    @include link-effect;

    display: flex;
    margin-top: 12px;
    margin-right: 12px;
    width: 232px;
    height: 130px;

    align-items: center;
    justify-content: center;
  }
  &_image {
    max-width: 100%;
    max-height: 100%;
  }
}
@media (max-width: breakpoint(Home, M)) {
  .c-popularUseScenes {
    &_headline {
      padding-left: 12px;
    }
    &_pcContent {
      display: none;
    }
    &_spContent {
      display: block;
      margin-top: 16px;
    }
    &_swiper {
      position: absolute;
      top: 0;
      left: 0;
    }
    &_placeholder {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .c-swiperPlaceholder {
    position: relative;
    overflow-x: hidden;
    height: 160px;
    &_wrapper {
      position: absolute;
      top: 0;
      left: 50%;
      display: flex;
      width: 1600px;
      transform: translateX(-50%);

      justify-content: center;
    }
    &_content {
      display: flex;
      margin: 0 4px;
      width: 284px;
      height: 159px;

      align-items: center;
      justify-content: center;
    }
    &_contentImage {
      max-width: 100%;
      max-height: 100%;
      background: $ds2-color-gray-25;
    }
  }
}
</style>
