<template>
  <div class="c-homeFooterContent">
    <section class="c-homeFooterContent_news">
      <ContentHeadline
        title-tag="h3"
        title-text="ニュース"
      />
      <CoconalaNews
        class="c-homeFooterContent_newsContent"
        :news="news"
      />
    </section>

    <div
      class="c-homeFooterContent_banner"
      v-if="isPC && !isLoggedIn && this.$cookies.get('ct_adw') === 0"
    >
      <a :href="$coconala.registrationURL(null, { ref: 'top_register_bottom' })">
        <img
          :src="imgPcBannerSrc"
          loading="lazy"
          decoding="async"
        />
      </a>
    </div>
  </div>
</template>

<script>
import ContentHeadline from '~/components/molecules/ContentHeadline'
import CoconalaNews from '~/components/organisms/CoconalaNews'

import { mapGetters } from 'vuex'

export default {
  name: 'HomeFooterContent',
  components: {
    ContentHeadline,
    CoconalaNews
  },
  props: {
    news: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('ui', ['isPC']),
    imgPcBannerSrc() {
      return require('~/assets/images/components/HomeFooterContent/pc_nologin_banner.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.c-homeFooterContent {
  margin-top: 40px;
  &_newsContent {
    margin-top: 4px;
  }
}

@media (min-width: breakpoint(Home, M) + 1) {
  .c-homeFooterContent {
    &_news {
      margin-left: 276px;
    }
    &_banner {
      margin-top: 40px;
      margin-left: 276px;
      a {
        @include link-effect;
      }
      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: breakpoint(Home, M)) {
  .c-homeFooterContent {
    &_news {
      margin: 0 12px;
      min-height: 334px;
      ::v-deep .c-coconalaNews {
        &_link {
          flex-direction: column;
        }
        &_date {
          flex: 0 0 auto;
        }
        &_name {
          margin-top: 4px;
        }
      }
    }
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-homeFooterContent {
    margin-top: 32px;
  }
}
</style>
