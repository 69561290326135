import { EntryFormOrderFormat } from '~/stub/domain/enumeration/entry_form_order_format_pb'

export type BudgetListType = {
  id: number
  name: string
}

export const BUDGET_LIST = {
  [EntryFormOrderFormat.ENTRY_FORM_ORDER_FORMAT_SINGLE]: [
    {
      id: 1,
      name: '2万円未満'
    },
    {
      id: 2,
      name: '2万円以上〜5万円未満'
    },
    {
      id: 3,
      name: '5万円以上〜10万円未満'
    },
    {
      id: 4,
      name: '10万円以上〜50万円未満'
    },
    {
      id: 5,
      name: '50万円以上'
    },
    {
      id: 99,
      name: '未定'
    }
  ],
  [EntryFormOrderFormat.ENTRY_FORM_ORDER_FORMAT_SUBCONTRACTING]: [
    {
      id: 6,
      name: '月額5万円未満'
    },
    {
      id: 7,
      name: '月額5万円以上〜10万円未満'
    },
    {
      id: 8,
      name: '月額10万円以上〜20万円未満'
    },
    {
      id: 9,
      name: '月額20万円以上〜30万円未満'
    },
    {
      id: 10,
      name: '月額30万円以上〜50万円未満'
    },
    {
      id: 11,
      name: '月額50万円以上〜80万円未満'
    },
    {
      id: 12,
      name: '月額80万円以上'
    },
    {
      id: 99,
      name: '未定'
    }
  ]
}

export const PLANNED_TIME_LIST: readonly BudgetListType[] = [
  {
    id: 1,
    name: '3日以内'
  },
  {
    id: 2,
    name: '1週間以内'
  },
  {
    id: 3,
    name: '1ヶ月以内'
  },
  {
    id: 4,
    name: '3ヶ月以内'
  },
  {
    id: 5,
    name: '3ヶ月以後'
  },
  {
    id: 99,
    name: '未定'
  }
]
