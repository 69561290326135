<template>
  <OSelect
    class="d-select"
    :class="`d-select-${size}`"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="innerValue"
    :style="styles"
    :disabled="selectDisabled"
  >
    <slot
      v-for="slotName in Object.keys($slots)"
      :name="slotName"
      :slot="slotName"
    />
  </OSelect>
</template>
<script>
export const SELECT_SIZES = Object.freeze(['small', 'medium', 'large'])
export default {
  name: 'DSelect',
  props: {
    value: {
      type: [String, Number, Boolean, Object, Array, Symbol, Function],
      default: null
    },
    selectDisabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100%'
    },
    size: {
      type: String,
      default: 'medium',
      validator(v) {
        return SELECT_SIZES.includes(v)
      }
    }
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    styles() {
      return {
        width: this.width
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$select-sizes: (
  'small': (
    'height': 32px,
    'select-padding': 6px 12px,
    'arrow-right': 12px,
    'font-size': 13px
  ),
  'medium': (
    'height': 40px,
    'select-padding': 8px 20px 8px 14px,
    'arrow-right': 22px,
    'font-size': 15px
  ),
  'large': (
    'height': 48px,
    'select-padding': 14px 16px,
    'arrow-right': 12px,
    'font-size': 13px
  )
);

.d-select {
  display: block;
  &.select {
    &::after {
      position: absolute;
      top: -4px;
      bottom: 0;
      margin: auto;
      width: 11px;
      height: 11px;
      border-color: $ds2-color-gray-600;
    }
    ::v-deep select {
      width: 100%;
      height: 100%;
      border: 1px solid $ds2-color-gray-200;
      color: $ds2-color-gray-900;
      line-height: 1;
      &:focus {
        box-shadow: none;
      }
      &:disabled {
        background-color: $ds2-color-gray-50;
        opacity: 0.5;
      }
      // placeholderのデザイン
      &.is-empty {
        color: $ds2-color-gray-600;
      }
    }
  }
  @each $key, $size in $select-sizes {
    &-#{$key} {
      height: map-get($size, 'height');
      &.select {
        &::after {
          right: map-get($size, 'arrow-right');
        }
      }
      ::v-deep select {
        padding: map-get($size, 'select-padding');
        width: inherit;
        font-size: map-get($size, 'font-size');
      }
    }
  }
}
</style>
