// source: apigateway/business/business_registration.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.business.BusinessCorporation', null, global);
goog.exportSymbol('proto.business.BusinessUser', null, global);
goog.exportSymbol('proto.business.GetAccountReply', null, global);
goog.exportSymbol('proto.business.GetAccountRequest', null, global);
goog.exportSymbol('proto.business.GetInvitationStatusReply', null, global);
goog.exportSymbol('proto.business.GetInvitationStatusRequest', null, global);
goog.exportSymbol('proto.business.PrivateUser', null, global);
goog.exportSymbol('proto.business.UpdateBusinessCorporationReply', null, global);
goog.exportSymbol('proto.business.UpdateBusinessCorporationRequest', null, global);
goog.exportSymbol('proto.business.UpdateBusinessUserReply', null, global);
goog.exportSymbol('proto.business.UpdateBusinessUserRequest', null, global);
goog.exportSymbol('proto.business.VerifyInvitationReply', null, global);
goog.exportSymbol('proto.business.VerifyInvitationRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business.GetAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business.GetAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business.GetAccountRequest.displayName = 'proto.business.GetAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business.GetAccountReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business.GetAccountReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business.GetAccountReply.displayName = 'proto.business.GetAccountReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business.UpdateBusinessCorporationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business.UpdateBusinessCorporationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business.UpdateBusinessCorporationRequest.displayName = 'proto.business.UpdateBusinessCorporationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business.UpdateBusinessCorporationReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business.UpdateBusinessCorporationReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business.UpdateBusinessCorporationReply.displayName = 'proto.business.UpdateBusinessCorporationReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business.UpdateBusinessUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business.UpdateBusinessUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business.UpdateBusinessUserRequest.displayName = 'proto.business.UpdateBusinessUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business.UpdateBusinessUserReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business.UpdateBusinessUserReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business.UpdateBusinessUserReply.displayName = 'proto.business.UpdateBusinessUserReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business.BusinessCorporation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business.BusinessCorporation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business.BusinessCorporation.displayName = 'proto.business.BusinessCorporation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business.BusinessUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business.BusinessUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business.BusinessUser.displayName = 'proto.business.BusinessUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business.PrivateUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business.PrivateUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business.PrivateUser.displayName = 'proto.business.PrivateUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business.GetInvitationStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business.GetInvitationStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business.GetInvitationStatusRequest.displayName = 'proto.business.GetInvitationStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business.GetInvitationStatusReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business.GetInvitationStatusReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business.GetInvitationStatusReply.displayName = 'proto.business.GetInvitationStatusReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business.VerifyInvitationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business.VerifyInvitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business.VerifyInvitationRequest.displayName = 'proto.business.VerifyInvitationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.business.VerifyInvitationReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.business.VerifyInvitationReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.business.VerifyInvitationReply.displayName = 'proto.business.VerifyInvitationReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business.GetAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business.GetAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business.GetAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.GetAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business.GetAccountRequest}
 */
proto.business.GetAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business.GetAccountRequest;
  return proto.business.GetAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business.GetAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business.GetAccountRequest}
 */
proto.business.GetAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business.GetAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business.GetAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business.GetAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.GetAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business.GetAccountReply.prototype.toObject = function(opt_includeInstance) {
  return proto.business.GetAccountReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business.GetAccountReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.GetAccountReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessCorporation: (f = msg.getBusinessCorporation()) && proto.business.BusinessCorporation.toObject(includeInstance, f),
    businessUser: (f = msg.getBusinessUser()) && proto.business.BusinessUser.toObject(includeInstance, f),
    privateUser: (f = msg.getPrivateUser()) && proto.business.PrivateUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business.GetAccountReply}
 */
proto.business.GetAccountReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business.GetAccountReply;
  return proto.business.GetAccountReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business.GetAccountReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business.GetAccountReply}
 */
proto.business.GetAccountReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business.BusinessCorporation;
      reader.readMessage(value,proto.business.BusinessCorporation.deserializeBinaryFromReader);
      msg.setBusinessCorporation(value);
      break;
    case 2:
      var value = new proto.business.BusinessUser;
      reader.readMessage(value,proto.business.BusinessUser.deserializeBinaryFromReader);
      msg.setBusinessUser(value);
      break;
    case 3:
      var value = new proto.business.PrivateUser;
      reader.readMessage(value,proto.business.PrivateUser.deserializeBinaryFromReader);
      msg.setPrivateUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business.GetAccountReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business.GetAccountReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business.GetAccountReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.GetAccountReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessCorporation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business.BusinessCorporation.serializeBinaryToWriter
    );
  }
  f = message.getBusinessUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.business.BusinessUser.serializeBinaryToWriter
    );
  }
  f = message.getPrivateUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.business.PrivateUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional BusinessCorporation business_corporation = 1;
 * @return {?proto.business.BusinessCorporation}
 */
proto.business.GetAccountReply.prototype.getBusinessCorporation = function() {
  return /** @type{?proto.business.BusinessCorporation} */ (
    jspb.Message.getWrapperField(this, proto.business.BusinessCorporation, 1));
};


/**
 * @param {?proto.business.BusinessCorporation|undefined} value
 * @return {!proto.business.GetAccountReply} returns this
*/
proto.business.GetAccountReply.prototype.setBusinessCorporation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business.GetAccountReply} returns this
 */
proto.business.GetAccountReply.prototype.clearBusinessCorporation = function() {
  return this.setBusinessCorporation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business.GetAccountReply.prototype.hasBusinessCorporation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BusinessUser business_user = 2;
 * @return {?proto.business.BusinessUser}
 */
proto.business.GetAccountReply.prototype.getBusinessUser = function() {
  return /** @type{?proto.business.BusinessUser} */ (
    jspb.Message.getWrapperField(this, proto.business.BusinessUser, 2));
};


/**
 * @param {?proto.business.BusinessUser|undefined} value
 * @return {!proto.business.GetAccountReply} returns this
*/
proto.business.GetAccountReply.prototype.setBusinessUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business.GetAccountReply} returns this
 */
proto.business.GetAccountReply.prototype.clearBusinessUser = function() {
  return this.setBusinessUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business.GetAccountReply.prototype.hasBusinessUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PrivateUser private_user = 3;
 * @return {?proto.business.PrivateUser}
 */
proto.business.GetAccountReply.prototype.getPrivateUser = function() {
  return /** @type{?proto.business.PrivateUser} */ (
    jspb.Message.getWrapperField(this, proto.business.PrivateUser, 3));
};


/**
 * @param {?proto.business.PrivateUser|undefined} value
 * @return {!proto.business.GetAccountReply} returns this
*/
proto.business.GetAccountReply.prototype.setPrivateUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business.GetAccountReply} returns this
 */
proto.business.GetAccountReply.prototype.clearPrivateUser = function() {
  return this.setPrivateUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business.GetAccountReply.prototype.hasPrivateUser = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business.UpdateBusinessCorporationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business.UpdateBusinessCorporationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business.UpdateBusinessCorporationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.UpdateBusinessCorporationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessCorporation: (f = msg.getBusinessCorporation()) && proto.business.BusinessCorporation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business.UpdateBusinessCorporationRequest}
 */
proto.business.UpdateBusinessCorporationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business.UpdateBusinessCorporationRequest;
  return proto.business.UpdateBusinessCorporationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business.UpdateBusinessCorporationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business.UpdateBusinessCorporationRequest}
 */
proto.business.UpdateBusinessCorporationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business.BusinessCorporation;
      reader.readMessage(value,proto.business.BusinessCorporation.deserializeBinaryFromReader);
      msg.setBusinessCorporation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business.UpdateBusinessCorporationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business.UpdateBusinessCorporationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business.UpdateBusinessCorporationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.UpdateBusinessCorporationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessCorporation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business.BusinessCorporation.serializeBinaryToWriter
    );
  }
};


/**
 * optional BusinessCorporation business_corporation = 1;
 * @return {?proto.business.BusinessCorporation}
 */
proto.business.UpdateBusinessCorporationRequest.prototype.getBusinessCorporation = function() {
  return /** @type{?proto.business.BusinessCorporation} */ (
    jspb.Message.getWrapperField(this, proto.business.BusinessCorporation, 1));
};


/**
 * @param {?proto.business.BusinessCorporation|undefined} value
 * @return {!proto.business.UpdateBusinessCorporationRequest} returns this
*/
proto.business.UpdateBusinessCorporationRequest.prototype.setBusinessCorporation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business.UpdateBusinessCorporationRequest} returns this
 */
proto.business.UpdateBusinessCorporationRequest.prototype.clearBusinessCorporation = function() {
  return this.setBusinessCorporation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business.UpdateBusinessCorporationRequest.prototype.hasBusinessCorporation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business.UpdateBusinessCorporationReply.prototype.toObject = function(opt_includeInstance) {
  return proto.business.UpdateBusinessCorporationReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business.UpdateBusinessCorporationReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.UpdateBusinessCorporationReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business.UpdateBusinessCorporationReply}
 */
proto.business.UpdateBusinessCorporationReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business.UpdateBusinessCorporationReply;
  return proto.business.UpdateBusinessCorporationReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business.UpdateBusinessCorporationReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business.UpdateBusinessCorporationReply}
 */
proto.business.UpdateBusinessCorporationReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business.UpdateBusinessCorporationReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business.UpdateBusinessCorporationReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business.UpdateBusinessCorporationReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.UpdateBusinessCorporationReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business.UpdateBusinessUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business.UpdateBusinessUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business.UpdateBusinessUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.UpdateBusinessUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessUser: (f = msg.getBusinessUser()) && proto.business.BusinessUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business.UpdateBusinessUserRequest}
 */
proto.business.UpdateBusinessUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business.UpdateBusinessUserRequest;
  return proto.business.UpdateBusinessUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business.UpdateBusinessUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business.UpdateBusinessUserRequest}
 */
proto.business.UpdateBusinessUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.business.BusinessUser;
      reader.readMessage(value,proto.business.BusinessUser.deserializeBinaryFromReader);
      msg.setBusinessUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business.UpdateBusinessUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business.UpdateBusinessUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business.UpdateBusinessUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.UpdateBusinessUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.business.BusinessUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional BusinessUser business_user = 1;
 * @return {?proto.business.BusinessUser}
 */
proto.business.UpdateBusinessUserRequest.prototype.getBusinessUser = function() {
  return /** @type{?proto.business.BusinessUser} */ (
    jspb.Message.getWrapperField(this, proto.business.BusinessUser, 1));
};


/**
 * @param {?proto.business.BusinessUser|undefined} value
 * @return {!proto.business.UpdateBusinessUserRequest} returns this
*/
proto.business.UpdateBusinessUserRequest.prototype.setBusinessUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.business.UpdateBusinessUserRequest} returns this
 */
proto.business.UpdateBusinessUserRequest.prototype.clearBusinessUser = function() {
  return this.setBusinessUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.business.UpdateBusinessUserRequest.prototype.hasBusinessUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business.UpdateBusinessUserReply.prototype.toObject = function(opt_includeInstance) {
  return proto.business.UpdateBusinessUserReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business.UpdateBusinessUserReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.UpdateBusinessUserReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business.UpdateBusinessUserReply}
 */
proto.business.UpdateBusinessUserReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business.UpdateBusinessUserReply;
  return proto.business.UpdateBusinessUserReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business.UpdateBusinessUserReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business.UpdateBusinessUserReply}
 */
proto.business.UpdateBusinessUserReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business.UpdateBusinessUserReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business.UpdateBusinessUserReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business.UpdateBusinessUserReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.UpdateBusinessUserReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business.BusinessCorporation.prototype.toObject = function(opt_includeInstance) {
  return proto.business.BusinessCorporation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business.BusinessCorporation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.BusinessCorporation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    enterpriseName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    enterpriseNameKana: jspb.Message.getFieldWithDefault(msg, 3, ""),
    representativeLastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    representativeFirstName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    representativeLastNameKana: jspb.Message.getFieldWithDefault(msg, 6, ""),
    representativeFirstNameKana: jspb.Message.getFieldWithDefault(msg, 7, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    prefecture: jspb.Message.getFieldWithDefault(msg, 9, ""),
    address1: jspb.Message.getFieldWithDefault(msg, 10, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 11, ""),
    address3: jspb.Message.getFieldWithDefault(msg, 12, ""),
    address4: jspb.Message.getFieldWithDefault(msg, 13, ""),
    typeOfIndustry: jspb.Message.getFieldWithDefault(msg, 17, 0),
    employeeNumber: jspb.Message.getFieldWithDefault(msg, 18, 0),
    url: jspb.Message.getFieldWithDefault(msg, 19, ""),
    annualSales: jspb.Message.getFieldWithDefault(msg, 20, 0),
    logoFileName: jspb.Message.getFieldWithDefault(msg, 21, ""),
    logoFileUrl: jspb.Message.getFieldWithDefault(msg, 22, ""),
    logoMime: jspb.Message.getFieldWithDefault(msg, 23, ""),
    logoSize: jspb.Message.getFieldWithDefault(msg, 24, 0),
    description: jspb.Message.getFieldWithDefault(msg, 25, ""),
    enterpriseTelNumber: jspb.Message.getFieldWithDefault(msg, 26, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business.BusinessCorporation}
 */
proto.business.BusinessCorporation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business.BusinessCorporation;
  return proto.business.BusinessCorporation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business.BusinessCorporation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business.BusinessCorporation}
 */
proto.business.BusinessCorporation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnterpriseName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnterpriseNameKana(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepresentativeLastName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepresentativeFirstName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepresentativeLastNameKana(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepresentativeFirstNameKana(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrefecture(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress3(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress4(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTypeOfIndustry(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmployeeNumber(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAnnualSales(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoFileName(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoFileUrl(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoMime(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLogoSize(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnterpriseTelNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business.BusinessCorporation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business.BusinessCorporation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business.BusinessCorporation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.BusinessCorporation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEnterpriseName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnterpriseNameKana();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRepresentativeLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRepresentativeFirstName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRepresentativeLastNameKana();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRepresentativeFirstNameKana();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPrefecture();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAddress3();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAddress4();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTypeOfIndustry();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getEmployeeNumber();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAnnualSales();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getLogoFileName();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getLogoFileUrl();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getLogoMime();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getLogoSize();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getEnterpriseTelNumber();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.business.BusinessCorporation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string enterprise_name = 2;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getEnterpriseName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setEnterpriseName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string enterprise_name_kana = 3;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getEnterpriseNameKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setEnterpriseNameKana = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string representative_last_name = 4;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getRepresentativeLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setRepresentativeLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string representative_first_name = 5;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getRepresentativeFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setRepresentativeFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string representative_last_name_kana = 6;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getRepresentativeLastNameKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setRepresentativeLastNameKana = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string representative_first_name_kana = 7;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getRepresentativeFirstNameKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setRepresentativeFirstNameKana = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string postal_code = 8;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string prefecture = 9;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getPrefecture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setPrefecture = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string address1 = 10;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string address2 = 11;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string address3 = 12;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getAddress3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setAddress3 = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string address4 = 13;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getAddress4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setAddress4 = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 type_of_industry = 17;
 * @return {number}
 */
proto.business.BusinessCorporation.prototype.getTypeOfIndustry = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setTypeOfIndustry = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 employee_number = 18;
 * @return {number}
 */
proto.business.BusinessCorporation.prototype.getEmployeeNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setEmployeeNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string url = 19;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 annual_sales = 20;
 * @return {number}
 */
proto.business.BusinessCorporation.prototype.getAnnualSales = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setAnnualSales = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string logo_file_name = 21;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getLogoFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setLogoFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string logo_file_url = 22;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getLogoFileUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setLogoFileUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string logo_mime = 23;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getLogoMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setLogoMime = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional int32 logo_size = 24;
 * @return {number}
 */
proto.business.BusinessCorporation.prototype.getLogoSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setLogoSize = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string description = 25;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string enterprise_tel_number = 26;
 * @return {string}
 */
proto.business.BusinessCorporation.prototype.getEnterpriseTelNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessCorporation} returns this
 */
proto.business.BusinessCorporation.prototype.setEnterpriseTelNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business.BusinessUser.prototype.toObject = function(opt_includeInstance) {
  return proto.business.BusinessUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business.BusinessUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.BusinessUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    applicantLastName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    applicantFirstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    applicantLastNameKana: jspb.Message.getFieldWithDefault(msg, 3, ""),
    applicantFirstNameKana: jspb.Message.getFieldWithDefault(msg, 4, ""),
    year: jspb.Message.getFieldWithDefault(msg, 5, 0),
    month: jspb.Message.getFieldWithDefault(msg, 6, 0),
    day: jspb.Message.getFieldWithDefault(msg, 7, 0),
    sex: jspb.Message.getFieldWithDefault(msg, 8, ""),
    userPrefecture: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isQuestionnaireFinished: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    role: jspb.Message.getFieldWithDefault(msg, 19, 0),
    department: jspb.Message.getFieldWithDefault(msg, 20, ""),
    mobileTelNumber: jspb.Message.getFieldWithDefault(msg, 21, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business.BusinessUser}
 */
proto.business.BusinessUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business.BusinessUser;
  return proto.business.BusinessUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business.BusinessUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business.BusinessUser}
 */
proto.business.BusinessUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplicantLastName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplicantFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplicantLastNameKana(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplicantFirstNameKana(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSex(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPrefecture(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsQuestionnaireFinished(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRole(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartment(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobileTelNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business.BusinessUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business.BusinessUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business.BusinessUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.BusinessUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApplicantLastName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApplicantFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApplicantLastNameKana();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getApplicantFirstNameKana();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSex();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUserPrefecture();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsQuestionnaireFinished();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getRole();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getDepartment();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getMobileTelNumber();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
};


/**
 * optional string applicant_last_name = 1;
 * @return {string}
 */
proto.business.BusinessUser.prototype.getApplicantLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessUser} returns this
 */
proto.business.BusinessUser.prototype.setApplicantLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string applicant_first_name = 2;
 * @return {string}
 */
proto.business.BusinessUser.prototype.getApplicantFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessUser} returns this
 */
proto.business.BusinessUser.prototype.setApplicantFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string applicant_last_name_kana = 3;
 * @return {string}
 */
proto.business.BusinessUser.prototype.getApplicantLastNameKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessUser} returns this
 */
proto.business.BusinessUser.prototype.setApplicantLastNameKana = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string applicant_first_name_kana = 4;
 * @return {string}
 */
proto.business.BusinessUser.prototype.getApplicantFirstNameKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessUser} returns this
 */
proto.business.BusinessUser.prototype.setApplicantFirstNameKana = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 year = 5;
 * @return {number}
 */
proto.business.BusinessUser.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.business.BusinessUser} returns this
 */
proto.business.BusinessUser.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 month = 6;
 * @return {number}
 */
proto.business.BusinessUser.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.business.BusinessUser} returns this
 */
proto.business.BusinessUser.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 day = 7;
 * @return {number}
 */
proto.business.BusinessUser.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.business.BusinessUser} returns this
 */
proto.business.BusinessUser.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string sex = 8;
 * @return {string}
 */
proto.business.BusinessUser.prototype.getSex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessUser} returns this
 */
proto.business.BusinessUser.prototype.setSex = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string user_prefecture = 9;
 * @return {string}
 */
proto.business.BusinessUser.prototype.getUserPrefecture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessUser} returns this
 */
proto.business.BusinessUser.prototype.setUserPrefecture = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool is_questionnaire_finished = 18;
 * @return {boolean}
 */
proto.business.BusinessUser.prototype.getIsQuestionnaireFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.business.BusinessUser} returns this
 */
proto.business.BusinessUser.prototype.setIsQuestionnaireFinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional int32 role = 19;
 * @return {number}
 */
proto.business.BusinessUser.prototype.getRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.business.BusinessUser} returns this
 */
proto.business.BusinessUser.prototype.setRole = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string department = 20;
 * @return {string}
 */
proto.business.BusinessUser.prototype.getDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessUser} returns this
 */
proto.business.BusinessUser.prototype.setDepartment = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string mobile_tel_number = 21;
 * @return {string}
 */
proto.business.BusinessUser.prototype.getMobileTelNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.BusinessUser} returns this
 */
proto.business.BusinessUser.prototype.setMobileTelNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business.PrivateUser.prototype.toObject = function(opt_includeInstance) {
  return proto.business.PrivateUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business.PrivateUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.PrivateUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastKanaName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstKanaName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business.PrivateUser}
 */
proto.business.PrivateUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business.PrivateUser;
  return proto.business.PrivateUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business.PrivateUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business.PrivateUser}
 */
proto.business.PrivateUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastKanaName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstKanaName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business.PrivateUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business.PrivateUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business.PrivateUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.PrivateUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastKanaName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstKanaName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string last_name = 1;
 * @return {string}
 */
proto.business.PrivateUser.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.PrivateUser} returns this
 */
proto.business.PrivateUser.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.business.PrivateUser.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.PrivateUser} returns this
 */
proto.business.PrivateUser.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_kana_name = 3;
 * @return {string}
 */
proto.business.PrivateUser.prototype.getLastKanaName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.PrivateUser} returns this
 */
proto.business.PrivateUser.prototype.setLastKanaName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string first_kana_name = 4;
 * @return {string}
 */
proto.business.PrivateUser.prototype.getFirstKanaName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.PrivateUser} returns this
 */
proto.business.PrivateUser.prototype.setFirstKanaName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business.GetInvitationStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business.GetInvitationStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business.GetInvitationStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.GetInvitationStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invitationKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business.GetInvitationStatusRequest}
 */
proto.business.GetInvitationStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business.GetInvitationStatusRequest;
  return proto.business.GetInvitationStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business.GetInvitationStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business.GetInvitationStatusRequest}
 */
proto.business.GetInvitationStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitationKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business.GetInvitationStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business.GetInvitationStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business.GetInvitationStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.GetInvitationStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvitationKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string invitation_key = 1;
 * @return {string}
 */
proto.business.GetInvitationStatusRequest.prototype.getInvitationKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.GetInvitationStatusRequest} returns this
 */
proto.business.GetInvitationStatusRequest.prototype.setInvitationKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business.GetInvitationStatusReply.prototype.toObject = function(opt_includeInstance) {
  return proto.business.GetInvitationStatusReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business.GetInvitationStatusReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.GetInvitationStatusReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    isRegistered: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    invitationEmail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    role: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isInvitedFromUrl: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isEmailDomainMatch: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isExistingAccount: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isBusiness: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    enterpriseName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    industryId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    employeeNumberId: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business.GetInvitationStatusReply}
 */
proto.business.GetInvitationStatusReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business.GetInvitationStatusReply;
  return proto.business.GetInvitationStatusReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business.GetInvitationStatusReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business.GetInvitationStatusReply}
 */
proto.business.GetInvitationStatusReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRegistered(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitationEmail(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRole(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInvitedFromUrl(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEmailDomainMatch(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExistingAccount(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBusiness(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnterpriseName(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndustryId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmployeeNumberId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business.GetInvitationStatusReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business.GetInvitationStatusReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business.GetInvitationStatusReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.GetInvitationStatusReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsRegistered();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getInvitationEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRole();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIsInvitedFromUrl();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsEmailDomainMatch();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsExistingAccount();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsBusiness();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getEnterpriseName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIndustryId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getEmployeeNumberId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional bool is_registered = 1;
 * @return {boolean}
 */
proto.business.GetInvitationStatusReply.prototype.getIsRegistered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.business.GetInvitationStatusReply} returns this
 */
proto.business.GetInvitationStatusReply.prototype.setIsRegistered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string invitation_email = 2;
 * @return {string}
 */
proto.business.GetInvitationStatusReply.prototype.getInvitationEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.GetInvitationStatusReply} returns this
 */
proto.business.GetInvitationStatusReply.prototype.setInvitationEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 status = 3;
 * @return {number}
 */
proto.business.GetInvitationStatusReply.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.business.GetInvitationStatusReply} returns this
 */
proto.business.GetInvitationStatusReply.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 role = 4;
 * @return {number}
 */
proto.business.GetInvitationStatusReply.prototype.getRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.business.GetInvitationStatusReply} returns this
 */
proto.business.GetInvitationStatusReply.prototype.setRole = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_invited_from_url = 5;
 * @return {boolean}
 */
proto.business.GetInvitationStatusReply.prototype.getIsInvitedFromUrl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.business.GetInvitationStatusReply} returns this
 */
proto.business.GetInvitationStatusReply.prototype.setIsInvitedFromUrl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_email_domain_match = 6;
 * @return {boolean}
 */
proto.business.GetInvitationStatusReply.prototype.getIsEmailDomainMatch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.business.GetInvitationStatusReply} returns this
 */
proto.business.GetInvitationStatusReply.prototype.setIsEmailDomainMatch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_existing_account = 7;
 * @return {boolean}
 */
proto.business.GetInvitationStatusReply.prototype.getIsExistingAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.business.GetInvitationStatusReply} returns this
 */
proto.business.GetInvitationStatusReply.prototype.setIsExistingAccount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_business = 8;
 * @return {boolean}
 */
proto.business.GetInvitationStatusReply.prototype.getIsBusiness = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.business.GetInvitationStatusReply} returns this
 */
proto.business.GetInvitationStatusReply.prototype.setIsBusiness = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string enterprise_name = 9;
 * @return {string}
 */
proto.business.GetInvitationStatusReply.prototype.getEnterpriseName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.GetInvitationStatusReply} returns this
 */
proto.business.GetInvitationStatusReply.prototype.setEnterpriseName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 industry_id = 10;
 * @return {number}
 */
proto.business.GetInvitationStatusReply.prototype.getIndustryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.business.GetInvitationStatusReply} returns this
 */
proto.business.GetInvitationStatusReply.prototype.setIndustryId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 employee_number_id = 11;
 * @return {number}
 */
proto.business.GetInvitationStatusReply.prototype.getEmployeeNumberId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.business.GetInvitationStatusReply} returns this
 */
proto.business.GetInvitationStatusReply.prototype.setEmployeeNumberId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business.VerifyInvitationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.business.VerifyInvitationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business.VerifyInvitationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.VerifyInvitationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invitationKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business.VerifyInvitationRequest}
 */
proto.business.VerifyInvitationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business.VerifyInvitationRequest;
  return proto.business.VerifyInvitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business.VerifyInvitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business.VerifyInvitationRequest}
 */
proto.business.VerifyInvitationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitationKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business.VerifyInvitationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business.VerifyInvitationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business.VerifyInvitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.VerifyInvitationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvitationKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string invitation_key = 1;
 * @return {string}
 */
proto.business.VerifyInvitationRequest.prototype.getInvitationKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.VerifyInvitationRequest} returns this
 */
proto.business.VerifyInvitationRequest.prototype.setInvitationKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.business.VerifyInvitationReply.prototype.toObject = function(opt_includeInstance) {
  return proto.business.VerifyInvitationReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.business.VerifyInvitationReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.VerifyInvitationReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    preAuthHash: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.business.VerifyInvitationReply}
 */
proto.business.VerifyInvitationReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.business.VerifyInvitationReply;
  return proto.business.VerifyInvitationReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.business.VerifyInvitationReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.business.VerifyInvitationReply}
 */
proto.business.VerifyInvitationReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreAuthHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.business.VerifyInvitationReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.business.VerifyInvitationReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.business.VerifyInvitationReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.business.VerifyInvitationReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreAuthHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pre_auth_hash = 1;
 * @return {string}
 */
proto.business.VerifyInvitationReply.prototype.getPreAuthHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.business.VerifyInvitationReply} returns this
 */
proto.business.VerifyInvitationReply.prototype.setPreAuthHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.business);
