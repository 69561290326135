// source: gw/outsource/bookmark_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.outsource.BookmarkDetail', null, global);
goog.exportSymbol('proto.outsource.FetchBookmarkDetailRequest', null, global);
goog.exportSymbol('proto.outsource.FetchBookmarkDetailResponse', null, global);
goog.exportSymbol('proto.outsource.OutsourceBookmarkDetail', null, global);
goog.exportSymbol('proto.outsource.RequestBookmarkDetail', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.FetchBookmarkDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.FetchBookmarkDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.FetchBookmarkDetailRequest.displayName = 'proto.outsource.FetchBookmarkDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.FetchBookmarkDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.outsource.FetchBookmarkDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.outsource.FetchBookmarkDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.FetchBookmarkDetailResponse.displayName = 'proto.outsource.FetchBookmarkDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.BookmarkDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.BookmarkDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.BookmarkDetail.displayName = 'proto.outsource.BookmarkDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.RequestBookmarkDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.RequestBookmarkDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.RequestBookmarkDetail.displayName = 'proto.outsource.RequestBookmarkDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.OutsourceBookmarkDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.OutsourceBookmarkDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.OutsourceBookmarkDetail.displayName = 'proto.outsource.OutsourceBookmarkDetail';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.FetchBookmarkDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.FetchBookmarkDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.FetchBookmarkDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.FetchBookmarkDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: (f = msg.getPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perPage: (f = msg.getPerPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isExpiredIncluded: (f = msg.getIsExpiredIncluded()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.FetchBookmarkDetailRequest}
 */
proto.outsource.FetchBookmarkDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.FetchBookmarkDetailRequest;
  return proto.outsource.FetchBookmarkDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.FetchBookmarkDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.FetchBookmarkDetailRequest}
 */
proto.outsource.FetchBookmarkDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPerPage(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsExpiredIncluded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.FetchBookmarkDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.FetchBookmarkDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.FetchBookmarkDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.FetchBookmarkDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsExpiredIncluded();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value page = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.FetchBookmarkDetailRequest.prototype.getPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.FetchBookmarkDetailRequest} returns this
*/
proto.outsource.FetchBookmarkDetailRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.FetchBookmarkDetailRequest} returns this
 */
proto.outsource.FetchBookmarkDetailRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.FetchBookmarkDetailRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value per_page = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.FetchBookmarkDetailRequest.prototype.getPerPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.FetchBookmarkDetailRequest} returns this
*/
proto.outsource.FetchBookmarkDetailRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.FetchBookmarkDetailRequest} returns this
 */
proto.outsource.FetchBookmarkDetailRequest.prototype.clearPerPage = function() {
  return this.setPerPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.FetchBookmarkDetailRequest.prototype.hasPerPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue is_expired_included = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.outsource.FetchBookmarkDetailRequest.prototype.getIsExpiredIncluded = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.outsource.FetchBookmarkDetailRequest} returns this
*/
proto.outsource.FetchBookmarkDetailRequest.prototype.setIsExpiredIncluded = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.FetchBookmarkDetailRequest} returns this
 */
proto.outsource.FetchBookmarkDetailRequest.prototype.clearIsExpiredIncluded = function() {
  return this.setIsExpiredIncluded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.FetchBookmarkDetailRequest.prototype.hasIsExpiredIncluded = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.outsource.FetchBookmarkDetailResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.FetchBookmarkDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.FetchBookmarkDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.FetchBookmarkDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.FetchBookmarkDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookmarksList: jspb.Message.toObjectList(msg.getBookmarksList(),
    proto.outsource.BookmarkDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.FetchBookmarkDetailResponse}
 */
proto.outsource.FetchBookmarkDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.FetchBookmarkDetailResponse;
  return proto.outsource.FetchBookmarkDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.FetchBookmarkDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.FetchBookmarkDetailResponse}
 */
proto.outsource.FetchBookmarkDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.outsource.BookmarkDetail;
      reader.readMessage(value,proto.outsource.BookmarkDetail.deserializeBinaryFromReader);
      msg.addBookmarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.FetchBookmarkDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.FetchBookmarkDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.FetchBookmarkDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.FetchBookmarkDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookmarksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.outsource.BookmarkDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BookmarkDetail bookmarks = 1;
 * @return {!Array<!proto.outsource.BookmarkDetail>}
 */
proto.outsource.FetchBookmarkDetailResponse.prototype.getBookmarksList = function() {
  return /** @type{!Array<!proto.outsource.BookmarkDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.outsource.BookmarkDetail, 1));
};


/**
 * @param {!Array<!proto.outsource.BookmarkDetail>} value
 * @return {!proto.outsource.FetchBookmarkDetailResponse} returns this
*/
proto.outsource.FetchBookmarkDetailResponse.prototype.setBookmarksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.outsource.BookmarkDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.outsource.BookmarkDetail}
 */
proto.outsource.FetchBookmarkDetailResponse.prototype.addBookmarks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.outsource.BookmarkDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.outsource.FetchBookmarkDetailResponse} returns this
 */
proto.outsource.FetchBookmarkDetailResponse.prototype.clearBookmarksList = function() {
  return this.setBookmarksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.BookmarkDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.BookmarkDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.BookmarkDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.BookmarkDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookmarkId: (f = msg.getBookmarkId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    type: (f = msg.getType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    request: (f = msg.getRequest()) && proto.outsource.RequestBookmarkDetail.toObject(includeInstance, f),
    outsource: (f = msg.getOutsource()) && proto.outsource.OutsourceBookmarkDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.BookmarkDetail}
 */
proto.outsource.BookmarkDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.BookmarkDetail;
  return proto.outsource.BookmarkDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.BookmarkDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.BookmarkDetail}
 */
proto.outsource.BookmarkDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setBookmarkId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new proto.outsource.RequestBookmarkDetail;
      reader.readMessage(value,proto.outsource.RequestBookmarkDetail.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 5:
      var value = new proto.outsource.OutsourceBookmarkDetail;
      reader.readMessage(value,proto.outsource.OutsourceBookmarkDetail.deserializeBinaryFromReader);
      msg.setOutsource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.BookmarkDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.BookmarkDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.BookmarkDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.BookmarkDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookmarkId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.outsource.RequestBookmarkDetail.serializeBinaryToWriter
    );
  }
  f = message.getOutsource();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.outsource.OutsourceBookmarkDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value bookmark_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.BookmarkDetail.prototype.getBookmarkId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.BookmarkDetail} returns this
*/
proto.outsource.BookmarkDetail.prototype.setBookmarkId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.BookmarkDetail} returns this
 */
proto.outsource.BookmarkDetail.prototype.clearBookmarkId = function() {
  return this.setBookmarkId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.BookmarkDetail.prototype.hasBookmarkId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue type = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.BookmarkDetail.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.BookmarkDetail} returns this
*/
proto.outsource.BookmarkDetail.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.BookmarkDetail} returns this
 */
proto.outsource.BookmarkDetail.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.BookmarkDetail.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue created_at = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.BookmarkDetail.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.BookmarkDetail} returns this
*/
proto.outsource.BookmarkDetail.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.BookmarkDetail} returns this
 */
proto.outsource.BookmarkDetail.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.BookmarkDetail.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional RequestBookmarkDetail request = 4;
 * @return {?proto.outsource.RequestBookmarkDetail}
 */
proto.outsource.BookmarkDetail.prototype.getRequest = function() {
  return /** @type{?proto.outsource.RequestBookmarkDetail} */ (
    jspb.Message.getWrapperField(this, proto.outsource.RequestBookmarkDetail, 4));
};


/**
 * @param {?proto.outsource.RequestBookmarkDetail|undefined} value
 * @return {!proto.outsource.BookmarkDetail} returns this
*/
proto.outsource.BookmarkDetail.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.BookmarkDetail} returns this
 */
proto.outsource.BookmarkDetail.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.BookmarkDetail.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OutsourceBookmarkDetail outsource = 5;
 * @return {?proto.outsource.OutsourceBookmarkDetail}
 */
proto.outsource.BookmarkDetail.prototype.getOutsource = function() {
  return /** @type{?proto.outsource.OutsourceBookmarkDetail} */ (
    jspb.Message.getWrapperField(this, proto.outsource.OutsourceBookmarkDetail, 5));
};


/**
 * @param {?proto.outsource.OutsourceBookmarkDetail|undefined} value
 * @return {!proto.outsource.BookmarkDetail} returns this
*/
proto.outsource.BookmarkDetail.prototype.setOutsource = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.BookmarkDetail} returns this
 */
proto.outsource.BookmarkDetail.prototype.clearOutsource = function() {
  return this.setOutsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.BookmarkDetail.prototype.hasOutsource = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.RequestBookmarkDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.RequestBookmarkDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.RequestBookmarkDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.RequestBookmarkDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    title: (f = msg.getTitle()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    content: (f = msg.getContent()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    userId: (f = msg.getUserId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    priceMin: (f = msg.getPriceMin()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    priceMax: (f = msg.getPriceMax()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    expireDate: (f = msg.getExpireDate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    categoryName: (f = msg.getCategoryName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    applicationCount: (f = msg.getApplicationCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.RequestBookmarkDetail}
 */
proto.outsource.RequestBookmarkDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.RequestBookmarkDetail;
  return proto.outsource.RequestBookmarkDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.RequestBookmarkDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.RequestBookmarkDetail}
 */
proto.outsource.RequestBookmarkDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPriceMin(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPriceMax(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setExpireDate(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCategoryName(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setApplicationCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.RequestBookmarkDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.RequestBookmarkDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.RequestBookmarkDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.RequestBookmarkDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPriceMin();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPriceMax();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getExpireDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCategoryName();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getApplicationCount();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.RequestBookmarkDetail.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
*/
proto.outsource.RequestBookmarkDetail.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
 */
proto.outsource.RequestBookmarkDetail.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.RequestBookmarkDetail.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue title = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.RequestBookmarkDetail.prototype.getTitle = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
*/
proto.outsource.RequestBookmarkDetail.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
 */
proto.outsource.RequestBookmarkDetail.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.RequestBookmarkDetail.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue content = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.RequestBookmarkDetail.prototype.getContent = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
*/
proto.outsource.RequestBookmarkDetail.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
 */
proto.outsource.RequestBookmarkDetail.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.RequestBookmarkDetail.prototype.hasContent = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value user_id = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.RequestBookmarkDetail.prototype.getUserId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
*/
proto.outsource.RequestBookmarkDetail.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
 */
proto.outsource.RequestBookmarkDetail.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.RequestBookmarkDetail.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value price_min = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.RequestBookmarkDetail.prototype.getPriceMin = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
*/
proto.outsource.RequestBookmarkDetail.prototype.setPriceMin = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
 */
proto.outsource.RequestBookmarkDetail.prototype.clearPriceMin = function() {
  return this.setPriceMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.RequestBookmarkDetail.prototype.hasPriceMin = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int32Value price_max = 9;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.RequestBookmarkDetail.prototype.getPriceMax = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
*/
proto.outsource.RequestBookmarkDetail.prototype.setPriceMax = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
 */
proto.outsource.RequestBookmarkDetail.prototype.clearPriceMax = function() {
  return this.setPriceMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.RequestBookmarkDetail.prototype.hasPriceMax = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue expire_date = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.RequestBookmarkDetail.prototype.getExpireDate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
*/
proto.outsource.RequestBookmarkDetail.prototype.setExpireDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
 */
proto.outsource.RequestBookmarkDetail.prototype.clearExpireDate = function() {
  return this.setExpireDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.RequestBookmarkDetail.prototype.hasExpireDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue category_name = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.RequestBookmarkDetail.prototype.getCategoryName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
*/
proto.outsource.RequestBookmarkDetail.prototype.setCategoryName = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
 */
proto.outsource.RequestBookmarkDetail.prototype.clearCategoryName = function() {
  return this.setCategoryName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.RequestBookmarkDetail.prototype.hasCategoryName = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Int32Value application_count = 12;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.RequestBookmarkDetail.prototype.getApplicationCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 12));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
*/
proto.outsource.RequestBookmarkDetail.prototype.setApplicationCount = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.RequestBookmarkDetail} returns this
 */
proto.outsource.RequestBookmarkDetail.prototype.clearApplicationCount = function() {
  return this.setApplicationCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.RequestBookmarkDetail.prototype.hasApplicationCount = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.OutsourceBookmarkDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.OutsourceBookmarkDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.OutsourceBookmarkDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    outsourceUlid: (f = msg.getOutsourceUlid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    userId: (f = msg.getUserId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    title: (f = msg.getTitle()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    paymentMethodType: (f = msg.getPaymentMethodType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    weeklyMinWorkingHour: (f = msg.getWeeklyMinWorkingHour()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    weeklyMaxWorkingHour: (f = msg.getWeeklyMaxWorkingHour()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMinWorkingHour: (f = msg.getMonthlyMinWorkingHour()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMaxWorkingHour: (f = msg.getMonthlyMaxWorkingHour()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    hourlyMinUnitPrice: (f = msg.getHourlyMinUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    hourlyMaxUnitPrice: (f = msg.getHourlyMaxUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMinUnitPrice: (f = msg.getMonthlyMinUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMaxUnitPrice: (f = msg.getMonthlyMaxUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    workingDayType: (f = msg.getWorkingDayType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    categoryName: (f = msg.getCategoryName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    applicationCount: (f = msg.getApplicationCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.OutsourceBookmarkDetail}
 */
proto.outsource.OutsourceBookmarkDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.OutsourceBookmarkDetail;
  return proto.outsource.OutsourceBookmarkDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.OutsourceBookmarkDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.OutsourceBookmarkDetail}
 */
proto.outsource.OutsourceBookmarkDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOutsourceUlid(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPaymentMethodType(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setWeeklyMinWorkingHour(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setWeeklyMaxWorkingHour(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMinWorkingHour(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMaxWorkingHour(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setHourlyMinUnitPrice(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setHourlyMaxUnitPrice(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMinUnitPrice(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMaxUnitPrice(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setWorkingDayType(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCategoryName(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setApplicationCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.OutsourceBookmarkDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.OutsourceBookmarkDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.OutsourceBookmarkDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getOutsourceUlid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethodType();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getWeeklyMinWorkingHour();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getWeeklyMaxWorkingHour();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMinWorkingHour();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMaxWorkingHour();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getHourlyMinUnitPrice();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getHourlyMaxUnitPrice();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMinUnitPrice();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMaxUnitPrice();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getWorkingDayType();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCategoryName();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getApplicationCount();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue outsource_ulid = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getOutsourceUlid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setOutsourceUlid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearOutsourceUlid = function() {
  return this.setOutsourceUlid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasOutsourceUlid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value user_id = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getUserId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue status = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue title = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getTitle = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue description = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue payment_method_type = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getPaymentMethodType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setPaymentMethodType = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearPaymentMethodType = function() {
  return this.setPaymentMethodType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasPaymentMethodType = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value weekly_min_working_hour = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getWeeklyMinWorkingHour = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setWeeklyMinWorkingHour = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearWeeklyMinWorkingHour = function() {
  return this.setWeeklyMinWorkingHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasWeeklyMinWorkingHour = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int32Value weekly_max_working_hour = 9;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getWeeklyMaxWorkingHour = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setWeeklyMaxWorkingHour = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearWeeklyMaxWorkingHour = function() {
  return this.setWeeklyMaxWorkingHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasWeeklyMaxWorkingHour = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_min_working_hour = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getMonthlyMinWorkingHour = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setMonthlyMinWorkingHour = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearMonthlyMinWorkingHour = function() {
  return this.setMonthlyMinWorkingHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasMonthlyMinWorkingHour = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_max_working_hour = 11;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getMonthlyMaxWorkingHour = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 11));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setMonthlyMaxWorkingHour = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearMonthlyMaxWorkingHour = function() {
  return this.setMonthlyMaxWorkingHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasMonthlyMaxWorkingHour = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Int32Value hourly_min_unit_price = 12;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getHourlyMinUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 12));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setHourlyMinUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearHourlyMinUnitPrice = function() {
  return this.setHourlyMinUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasHourlyMinUnitPrice = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Int32Value hourly_max_unit_price = 13;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getHourlyMaxUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 13));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setHourlyMaxUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearHourlyMaxUnitPrice = function() {
  return this.setHourlyMaxUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasHourlyMaxUnitPrice = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_min_unit_price = 14;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getMonthlyMinUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 14));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setMonthlyMinUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearMonthlyMinUnitPrice = function() {
  return this.setMonthlyMinUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasMonthlyMinUnitPrice = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_max_unit_price = 15;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getMonthlyMaxUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 15));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setMonthlyMaxUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearMonthlyMaxUnitPrice = function() {
  return this.setMonthlyMaxUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasMonthlyMaxUnitPrice = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.StringValue working_day_type = 16;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getWorkingDayType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setWorkingDayType = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearWorkingDayType = function() {
  return this.setWorkingDayType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasWorkingDayType = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.StringValue category_name = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getCategoryName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setCategoryName = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearCategoryName = function() {
  return this.setCategoryName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasCategoryName = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.Int32Value application_count = 18;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.getApplicationCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 18));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
*/
proto.outsource.OutsourceBookmarkDetail.prototype.setApplicationCount = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceBookmarkDetail} returns this
 */
proto.outsource.OutsourceBookmarkDetail.prototype.clearApplicationCount = function() {
  return this.setApplicationCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceBookmarkDetail.prototype.hasApplicationCount = function() {
  return jspb.Message.getField(this, 18) != null;
};


goog.object.extend(exports, proto.outsource);
