var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-input",class:{
    'd-input-counterPositionRight': _vm.isCounterPositionRight,
    'd-input-counterShow': _vm.isShowTextCounter
  }},[_c('OInput',_vm._g(_vm._b({staticClass:"d-input_text",class:{ 'd-input_text-error': _vm.isError },attrs:{"maxlength":_vm.maxInputLength,"type":_vm.inputType,"pattern":_vm.inputType === 'number' ? '\\d*' : null,"autocomplete":_vm.autocomplete,"is-error":_vm.isError,"id":_vm.innerId,"disabled":_vm.isDisabled},on:{"blur":_vm.onBlur},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.emitEnterEvent.apply(null, arguments)},"compositionstart":function($event){_vm.composing = true},"compositionend":function($event){_vm.composing = false}},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'OInput',_vm.$attrs,false),_vm.$listeners)),_vm._v(" "),(_vm.maxLength)?_c('small',{staticClass:"help counter d-input_counter",class:{
      'd-input_counter-right': _vm.isCounterPositionRight,
      'd-input_counter-error': _vm.isError
    }},[_vm._v("\n    "+_vm._s(_vm.valueLength)+" / "+_vm._s(_vm.maxLength)+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }