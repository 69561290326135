// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/components/DS2/DSuggest/search.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tags-input[data-v-3867df3d]{display:flex;flex-wrap:wrap;align-items:center}.tags-input input[data-v-3867df3d]{flex:1;background:rgba(0,0,0,0);border:none}.tags-input input[data-v-3867df3d]:focus{outline:none}.tags-input input[type=text][data-v-3867df3d]{color:#0a2733}.tags-input[data-v-3867df3d]::placeholder{color:#b6bfc2}.tags-input-wrapper-default[data-v-3867df3d]{padding:9px 14px;border:1px solid #b6bfc2;border-radius:4px}.tags-input-wrapper-default.active[data-v-3867df3d]{border:1px solid #1bb299}.tags-input-remove[data-v-3867df3d]{cursor:pointer;display:inline-block;padding:.5px;overflow:hidden}.tags-input-badge[data-v-3867df3d]{position:relative;display:flex;background-color:#f3f4f5;align-items:center;margin:3px 4px 3px 0;padding:4px 6px;line-height:1;text-align:center;white-space:nowrap;vertical-align:baseline;border-radius:20px;overflow:hidden;text-overflow:ellipsis}.tags-input-icon[data-v-3867df3d]{width:21px;height:auto;border-radius:50%;margin-right:6px}.tags-input-name[data-v-3867df3d]{font-size:12px;margin-right:9px}.d-suggest[data-v-3867df3d]{position:relative}.d-suggest-float[data-v-3867df3d]{height:48px}.d-suggestFloat[data-v-3867df3d]{position:absolute;z-index:10;width:100%}.d-suggestInput[data-v-3867df3d] .d-input_text .icon{margin-right:12px;width:initial;height:100%;color:#546871 !important;font-size:20px}.d-suggestInput[data-v-3867df3d] input{padding-left:42px !important;background:no-repeat 12px url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.d-suggestList[data-v-3867df3d]{position:relative;overflow-y:auto;box-sizing:border-box;max-height:408px;border-radius:4px;background:#fff;box-shadow:-1px 1px 5px rgba(0,0,0,.25)}.d-suggestList_item[data-v-3867df3d]{padding:12px 24px;font-size:14px;line-height:1.2}.d-suggestList_item-focused[data-v-3867df3d]{background-color:#f3f4f5;cursor:pointer}.d-suggestList_description[data-v-3867df3d]{margin-top:4px;color:#546871}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
