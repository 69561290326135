<template>
  <div class="c-homeHeroArea">
    <div
      class="c-homeHeroArea_header"
      :class="{ 'c-homeHeroArea_header-loggedIn': isLoggedIn }"
    >
      <img
        class="c-homeHeroArea_headerImage"
        :src="require('~/assets/images/components/HomeHeroArea/mainvisual.jpg')"
        alt="最大級のスキルマーケット"
        fetchpriority="high"
      />
      <div class="c-homeHeroArea_headerRegistrationArea c-homeHeroArea_headerRegistrationArea-sp">
        <EzTopButton
          class="c-homeHeroArea_headerRegistrationButton"
          :href="
            $coconala.registrationURL(this.$route.fullPath, {
              ref: $route.path === '/' ? 'sp_top_hero_area_register' : 'sp_hero_area_register'
            })
          "
          type="primary"
          width="100%"
          height="64px"
          sp-height="52px"
          border-radius="8px"
          data-translate
        >
          まずは無料で会員登録
        </EzTopButton>
      </div>
      <div class="c-homeHeroArea_headerAppBanners">
        <a
          class="c-homeHeroArea_headerAppBannerLeft"
          :href="$util.genAppUrl(appStoreUrl, $ua.isFromPc(), true)"
        >
          <img
            src="~/assets/images/components/HomeHeroArea/ios.png"
            class="js-ga-event-tracker"
            alt="App Storeからダウンロード"
            data-tracking-category="app"
            data-tracking-action="tap"
            data-tracking-label="sp_top_app_ios_1"
            loading="lazy"
            decoding="async"
          />
        </a>
        <a
          class="c-homeHeroArea_headerAppBannerRight"
          :href="$util.genAppUrl(appStoreUrl, $ua.isFromPc(), false)"
        >
          <img
            src="~/assets/images/components/HomeHeroArea/google-play.png"
            class="js-ga-event-tracker"
            alt="Google playからダウンロード"
            data-tracking-category="app"
            data-tracking-action="tap"
            data-tracking-label="sp_top_app_android_1"
            loading="lazy"
            decoding="async"
          />
        </a>
      </div>
    </div>
    <HomeHeroAreaSwiper
      :class="{ 'c-homeHeroAreaSwiper-loggedIn': isLoggedIn }"
      :hero-areas-list="heroAreasList"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import HomeHeroAreaSwiper from './components/HomeHeroAreaSwiper'
import EzTopButton from '~/components/atoms/ez-top/EzTopButton'

/** @type {import('vue').ComponentOptions} */
const homeHeroArea = {
  name: 'HomeHeroArea',
  components: {
    HomeHeroAreaSwiper,
    EzTopButton
  },
  props: {
    heroAreasList: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('auth', ['appStoreUrl']),
    // NOTE:未ログイントップバナーのABテストに使用してたが一旦キメでmainvisual.jpgを表示する
    mainVisualName() {
      // 有効なCookie情報(ct_adw=0 or 1)が保存されているか
      const existCookie = this.$cookies.get('ct_adw') == 0 || this.$cookies.get('ct_adw') == 1
      // Cookie情報(ct_adw)なしの場合、パラメータにct_adwを含まないか、トップページでないとき、クーポンあり
      const displayBanner =
        (Array.isArray(this.$route.query.ref)
          ? !this.$route.query.ref?.includes('ct_adw')
          : this.$route.query.ref !== 'ct_adw') || this.$route.path != '/'
      // クーポンあり画像の表示判定
      const displayCouponImage = existCookie ? this.$cookies.get('ct_adw') == 0 : displayBanner

      return displayCouponImage ? 'mainvisual.jpg' : 'mainvisual_ct_adw.jpg'
    }
  }
}
export default homeHeroArea
</script>

<style lang="scss" scoped>
.c-homeHeroArea {
  &_header {
    display: none;
  }
  ::v-deep .c-homeHeroAreaSwiper {
    box-sizing: content-box;
    margin: 0 auto;
    padding: 0 20px;
    width: 1240px;
  }
}
@media (max-width: breakpoint(Home, M)) {
  .c-homeHeroArea {
    &_header {
      display: block;
      &-loggedIn {
        display: none;
      }
    }
    &_headerImage {
      display: block;
      width: 100vw;
      // 画像のアスペクト比から高さを計算
      height: calc(100vw * 1008 / 1280);
    }
    &_headerAppBanners {
      display: flex;
      margin: 0 auto;
      padding-top: 8px;
      width: 93%;

      justify-content: space-around;
    }
    &_headerAppBannerLeft {
      margin-right: 16px;
      width: 100%;
      text-align: right;
      img {
        width: 70%;
      }
    }
    &_headerAppBannerRight {
      margin-top: -1.4%;
      width: 100%;
      img {
        width: 70%;
      }
    }
    ::v-deep .c-homeHeroAreaSwiper {
      display: none !important;
      padding: 0;
      width: 100%;
      &-loggedIn {
        display: block !important;
      }
    }
    &_headerRegistrationArea {
      &-sp {
        display: flex;
        justify-content: center;
        padding: 4px 0 8px;
      }
    }
    &_headerRegistrationButton {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 600px;
      margin: 0 20px;
      letter-spacing: 0.05em;
      font-size: 16px;
      white-space: nowrap;
    }
  }
}
</style>
