<template>
  <div
    class="c-contentHeadline"
    data-translate
  >
    <component
      :is="titleTag"
      class="c-contentHeadline_title"
      :class="`c-contentHeadline_title-${titleTag}`"
    >
      <CoconalaIcon
        v-if="titleIcon"
        :name="titleIcon"
      />
      <SwitchLink
        v-if="titleUrl"
        :link="titleUrl"
        :is-nuxt-link="isNuxtLinkTitle"
        :is-external-link="isExternalLinkTitle"
        class="c-contentHeadline_titlelink"
      >
        {{ titleText }}
      </SwitchLink>
      <span v-else>{{ titleText }}</span>
      <span
        v-if="subTitleText"
        class="c-contentHeadline_subTitle"
      >
        {{ subTitleText }}
      </span>
      <slot name="additionalTitle"></slot>
    </component>
    <SwitchLink
      v-if="rightLink"
      :link="rightLink.link"
      :is-nuxt-link="rightLink.isNuxtLink"
      :is-external-link="rightLink.isExternalLink"
      class="c-contentHeadline_rightLink"
    >
      <span v-if="isSP && rightLink.textSp">{{ rightLink.textSp }}</span>
      <span v-else>{{ rightLink.text }}</span>
      <CoconalaIcon
        v-if="rightLink.isExternalLink"
        name="external-link"
        size="13px"
        color="gray600"
      />
    </SwitchLink>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import SwitchLink from '~/components/atoms/SwitchLink'

/**
 * @typedef RightLink 右側リンク
 * @property {string} text リンクテキスト
 * @property {string} textSp SPリンクテキスト
 * @property {string} link リンクパス
 * @property {boolean} [isNuxtLink] nuxt-linkフラグ(省略可)
 * @property {boolean} [isExternalLink] 外部リンクフラグ(省略可)
 */

export default {
  name: 'ContentHeadline',
  components: {
    SwitchLink
  },
  props: {
    titleTag: {
      type: String,
      default: 'div'
    },
    titleText: {
      type: String,
      default: ''
    },
    titleIcon: {
      type: String,
      default: ''
    },
    titleUrl: {
      type: String,
      default: ''
    },
    isNuxtLinkTitle: {
      type: Boolean,
      default: false
    },
    isExternalLinkTitle: {
      type: Boolean,
      default: false
    },
    subTitleText: {
      type: String,
      default: ''
    },
    rightLink: {
      type: Object,
      default: () => null,
      /**
       * @param { RightLink|null } val
       */
      validator(val) {
        if (!val) {
          return true
        }
        return val.text && val.link
      }
    }
  },
  computed: {
    ...mapGetters('ui', ['isSP'])
  }
}
</script>

<style lang="scss" scoped>
.c-contentHeadline {
  display: flex;
  justify-content: space-around;
  align-items: center;

  &_title {
    font-size: 16px;
    font-weight: bold;
    flex-basis: 100%;
    color: $ds2-color-gray-900;

    &-h3 {
      font-size: 14px;
      font-weight: bold;
    }
  }

  &_titlelink {
    color: $ds2-color-gray-900;
  }

  &_subTitle {
    font-size: 12px;
    font-weight: normal;
    margin-left: 12px;
  }

  &_rightLink {
    color: $ds2-color-brand-secondary;
    flex-shrink: 0;
  }
}

.c-contentHeadlineAdditionalTitle {
  display: inline-block;
}

@media (min-width: breakpoint(Home, M) + 1) {
  .c-contentHeadline {
    &_title {
      font-size: 20px;
      color: $ds2-color-gray-900;
      flex-basis: 100%;

      &-h3 {
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
}
</style>
