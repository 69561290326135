import GrpcAccessorBase from '~/api/accessors/grpc-accessor-base'
import grpcClient from '~/grpc/grpc-client'
import { Stripped } from '~/grpc/grpc-util'

import {
  GetCurrentUserAttributeRequest,
  GetCurrentUserAttributeResponse
} from '~/stub/gw/user_attribute/user_attribute_service_pb'
import { UserAttributeService } from '~/stub/gw/user_attribute/user_attribute_service_pb_service'

export type CurrentUserAttributeType = Stripped<GetCurrentUserAttributeRequest.AsObject>

export type GetCurrentUserAttributeResponseType = Stripped<GetCurrentUserAttributeResponse.AsObject>

class UserAttributeServiceAccessor extends GrpcAccessorBase {
  /**
   * ユーザー属性情報取得
   */
  getCurrentUserAttribute(): Promise<GetCurrentUserAttributeResponseType> {
    const metadata = this.getMetadata()
    const request = new GetCurrentUserAttributeRequest()
    return grpcClient({
      method: UserAttributeService.GetCurrentUserAttribute,
      request,
      metadata,
      strip: true
    })
  }
}

export default UserAttributeServiceAccessor
