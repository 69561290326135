// source: gw/user_attribute/user_attribute_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var domain_enumeration_entry_form_purpose_type_pb = require('../../domain/enumeration/entry_form_purpose_type_pb.js');
goog.object.extend(proto, domain_enumeration_entry_form_purpose_type_pb);
var domain_enumeration_entry_form_organization_type_pb = require('../../domain/enumeration/entry_form_organization_type_pb.js');
goog.object.extend(proto, domain_enumeration_entry_form_organization_type_pb);
var domain_enumeration_entry_form_order_format_pb = require('../../domain/enumeration/entry_form_order_format_pb.js');
goog.object.extend(proto, domain_enumeration_entry_form_order_format_pb);
var domain_enumeration_entry_form_contract_style_pb = require('../../domain/enumeration/entry_form_contract_style_pb.js');
goog.object.extend(proto, domain_enumeration_entry_form_contract_style_pb);
goog.exportSymbol('proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest', null, global);
goog.exportSymbol('proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest.displayName = 'proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.displayName = 'proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest;
  return proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    purpose: jspb.Message.getFieldWithDefault(msg, 1, 0),
    privateCorporation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    orderFormat: jspb.Message.getFieldWithDefault(msg, 3, 0),
    contractStyle: jspb.Message.getFieldWithDefault(msg, 4, 0),
    lastName: (f = msg.getLastName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firstName: (f = msg.getFirstName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastKanaName: (f = msg.getLastKanaName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firstKanaName: (f = msg.getFirstKanaName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    dob: (f = msg.getDob()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    sex: (f = msg.getSex()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    corporateName: (f = msg.getCorporateName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    prefecture: (f = msg.getPrefecture()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    industryId: (f = msg.getIndustryId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    employeeNumberId: (f = msg.getEmployeeNumberId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    phoneNumber: (f = msg.getPhoneNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    budgetType: (f = msg.getBudgetType()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    plannedTime: (f = msg.getPlannedTime()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse;
  return proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} */ (reader.readEnum());
      msg.setPurpose(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} */ (reader.readEnum());
      msg.setPrivateCorporation(value);
      break;
    case 3:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} */ (reader.readEnum());
      msg.setOrderFormat(value);
      break;
    case 4:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormContractStyle} */ (reader.readEnum());
      msg.setContractStyle(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastName(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstName(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastKanaName(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstKanaName(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setDob(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSex(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCorporateName(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrefecture(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setIndustryId(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setEmployeeNumberId(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setBudgetType(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPlannedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurpose();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPrivateCorporation();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOrderFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getContractStyle();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getLastName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirstName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastKanaName();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirstKanaName();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDob();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getSex();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCorporateName();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrefecture();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIndustryId();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getEmployeeNumberId();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBudgetType();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPlannedTime();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormPurposeType purpose = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getPurpose = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormPurposeType} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormOrganizationType private_corporation = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getPrivateCorporation = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormOrganizationType} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setPrivateCorporation = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormOrderFormat order_format = 3;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getOrderFormat = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormOrderFormat} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setOrderFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional coconala.protobuf.domain.enumeration.EntryFormContractStyle contract_style = 4;
 * @return {!proto.coconala.protobuf.domain.enumeration.EntryFormContractStyle}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getContractStyle = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.EntryFormContractStyle} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.EntryFormContractStyle} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setContractStyle = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue last_name = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getLastName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
*/
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setLastName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.clearLastName = function() {
  return this.setLastName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.hasLastName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue first_name = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getFirstName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
*/
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setFirstName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.clearFirstName = function() {
  return this.setFirstName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.hasFirstName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue last_kana_name = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getLastKanaName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
*/
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setLastKanaName = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.clearLastKanaName = function() {
  return this.setLastKanaName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.hasLastKanaName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue first_kana_name = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getFirstKanaName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
*/
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setFirstKanaName = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.clearFirstKanaName = function() {
  return this.setFirstKanaName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.hasFirstKanaName = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int64Value dob = 9;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getDob = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
*/
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setDob = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.clearDob = function() {
  return this.setDob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.hasDob = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue sex = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getSex = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
*/
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setSex = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.clearSex = function() {
  return this.setSex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.hasSex = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue corporate_name = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getCorporateName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
*/
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setCorporateName = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.clearCorporateName = function() {
  return this.setCorporateName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.hasCorporateName = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue prefecture = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getPrefecture = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
*/
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setPrefecture = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.clearPrefecture = function() {
  return this.setPrefecture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.hasPrefecture = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Int32Value industry_id = 13;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getIndustryId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 13));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
*/
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setIndustryId = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.clearIndustryId = function() {
  return this.setIndustryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.hasIndustryId = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Int32Value employee_number_id = 14;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getEmployeeNumberId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 14));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
*/
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setEmployeeNumberId = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.clearEmployeeNumberId = function() {
  return this.setEmployeeNumberId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.hasEmployeeNumberId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.StringValue phone_number = 15;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getPhoneNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 15));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
*/
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Int32Value budget_type = 16;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getBudgetType = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 16));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
*/
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setBudgetType = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.clearBudgetType = function() {
  return this.setBudgetType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.hasBudgetType = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Int32Value planned_time = 17;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.getPlannedTime = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 17));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
*/
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.setPlannedTime = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse} returns this
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.clearPlannedTime = function() {
  return this.setPlannedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.gw.user_attribute.GetCurrentUserAttributeResponse.prototype.hasPlannedTime = function() {
  return jspb.Message.getField(this, 17) != null;
};


goog.object.extend(exports, proto.coconala.protobuf.gw.user_attribute);
