// source: apigateway/home/home.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var domain_enumeration_category_ranking_default_sort_pb = require('../../domain/enumeration/category_ranking_default_sort_pb.js');
goog.object.extend(proto, domain_enumeration_category_ranking_default_sort_pb);
goog.exportSymbol('proto.home.CategoryRanking', null, global);
goog.exportSymbol('proto.home.CategoryRankingDetail', null, global);
goog.exportSymbol('proto.home.GetHomeContentReply', null, global);
goog.exportSymbol('proto.home.GetHomeContentRequest', null, global);
goog.exportSymbol('proto.home.GetMyFavoriteServicesReply', null, global);
goog.exportSymbol('proto.home.GetMyFavoriteServicesRequest', null, global);
goog.exportSymbol('proto.home.GetMyRecommendRequestsRequest', null, global);
goog.exportSymbol('proto.home.GetMyRecommendRequestsResponse', null, global);
goog.exportSymbol('proto.home.GetMyServiceHistoriesReply', null, global);
goog.exportSymbol('proto.home.GetMyServiceHistoriesRequest', null, global);
goog.exportSymbol('proto.home.HeroArea', null, global);
goog.exportSymbol('proto.home.LegalQa', null, global);
goog.exportSymbol('proto.home.News', null, global);
goog.exportSymbol('proto.home.RatingData', null, global);
goog.exportSymbol('proto.home.RecommendBlog', null, global);
goog.exportSymbol('proto.home.RecommendBlogUser', null, global);
goog.exportSymbol('proto.home.RecommendCategory', null, global);
goog.exportSymbol('proto.home.RecommendRequest', null, global);
goog.exportSymbol('proto.home.Request', null, global);
goog.exportSymbol('proto.home.SearchProviderLevel', null, global);
goog.exportSymbol('proto.home.Service', null, global);
goog.exportSymbol('proto.home.ServiceProvider', null, global);
goog.exportSymbol('proto.home.UseScene', null, global);
goog.exportSymbol('proto.home.User', null, global);
goog.exportSymbol('proto.home.UserSearchSpecialty', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.GetHomeContentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.GetHomeContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.GetHomeContentRequest.displayName = 'proto.home.GetHomeContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.GetHomeContentReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.home.GetHomeContentReply.repeatedFields_, null);
};
goog.inherits(proto.home.GetHomeContentReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.GetHomeContentReply.displayName = 'proto.home.GetHomeContentReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.GetMyFavoriteServicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.GetMyFavoriteServicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.GetMyFavoriteServicesRequest.displayName = 'proto.home.GetMyFavoriteServicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.GetMyFavoriteServicesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.home.GetMyFavoriteServicesReply.repeatedFields_, null);
};
goog.inherits(proto.home.GetMyFavoriteServicesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.GetMyFavoriteServicesReply.displayName = 'proto.home.GetMyFavoriteServicesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.GetMyServiceHistoriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.GetMyServiceHistoriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.GetMyServiceHistoriesRequest.displayName = 'proto.home.GetMyServiceHistoriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.GetMyServiceHistoriesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.home.GetMyServiceHistoriesReply.repeatedFields_, null);
};
goog.inherits(proto.home.GetMyServiceHistoriesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.GetMyServiceHistoriesReply.displayName = 'proto.home.GetMyServiceHistoriesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.GetMyRecommendRequestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.GetMyRecommendRequestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.GetMyRecommendRequestsRequest.displayName = 'proto.home.GetMyRecommendRequestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.GetMyRecommendRequestsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.home.GetMyRecommendRequestsResponse.repeatedFields_, null);
};
goog.inherits(proto.home.GetMyRecommendRequestsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.GetMyRecommendRequestsResponse.displayName = 'proto.home.GetMyRecommendRequestsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.HeroArea = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.HeroArea, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.HeroArea.displayName = 'proto.home.HeroArea';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.UseScene = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.UseScene, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.UseScene.displayName = 'proto.home.UseScene';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.Service = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.Service, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.Service.displayName = 'proto.home.Service';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.ServiceProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.ServiceProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.ServiceProvider.displayName = 'proto.home.ServiceProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.RatingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.RatingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.RatingData.displayName = 'proto.home.RatingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.home.User.repeatedFields_, null);
};
goog.inherits(proto.home.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.User.displayName = 'proto.home.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.UserSearchSpecialty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.UserSearchSpecialty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.UserSearchSpecialty.displayName = 'proto.home.UserSearchSpecialty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.SearchProviderLevel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.SearchProviderLevel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.SearchProviderLevel.displayName = 'proto.home.SearchProviderLevel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.Request.displayName = 'proto.home.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.CategoryRanking = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.home.CategoryRanking.repeatedFields_, null);
};
goog.inherits(proto.home.CategoryRanking, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.CategoryRanking.displayName = 'proto.home.CategoryRanking';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.CategoryRankingDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.home.CategoryRankingDetail.repeatedFields_, null);
};
goog.inherits(proto.home.CategoryRankingDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.CategoryRankingDetail.displayName = 'proto.home.CategoryRankingDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.News = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.News, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.News.displayName = 'proto.home.News';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.LegalQa = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.LegalQa, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.LegalQa.displayName = 'proto.home.LegalQa';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.RecommendBlog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.RecommendBlog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.RecommendBlog.displayName = 'proto.home.RecommendBlog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.RecommendBlogUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.RecommendBlogUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.RecommendBlogUser.displayName = 'proto.home.RecommendBlogUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.RecommendCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.RecommendCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.RecommendCategory.displayName = 'proto.home.RecommendCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.home.RecommendRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.home.RecommendRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.home.RecommendRequest.displayName = 'proto.home.RecommendRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.GetHomeContentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.home.GetHomeContentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.GetHomeContentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetHomeContentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    viewPattern: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.GetHomeContentRequest}
 */
proto.home.GetHomeContentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.GetHomeContentRequest;
  return proto.home.GetHomeContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.GetHomeContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.GetHomeContentRequest}
 */
proto.home.GetHomeContentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setViewPattern(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.GetHomeContentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.GetHomeContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.GetHomeContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetHomeContentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViewPattern();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 view_pattern = 1;
 * @return {number}
 */
proto.home.GetHomeContentRequest.prototype.getViewPattern = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.GetHomeContentRequest} returns this
 */
proto.home.GetHomeContentRequest.prototype.setViewPattern = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.home.GetHomeContentReply.repeatedFields_ = [2,3,4,5,7,8,9,10,11,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.GetHomeContentReply.prototype.toObject = function(opt_includeInstance) {
  return proto.home.GetHomeContentReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.GetHomeContentReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetHomeContentReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    useScenesList: jspb.Message.toObjectList(msg.getUseScenesList(),
    proto.home.UseScene.toObject, includeInstance),
    recommendServicesList: jspb.Message.toObjectList(msg.getRecommendServicesList(),
    proto.home.Service.toObject, includeInstance),
    recommendUsersList: jspb.Message.toObjectList(msg.getRecommendUsersList(),
    proto.home.User.toObject, includeInstance),
    recommendRequestsList: jspb.Message.toObjectList(msg.getRecommendRequestsList(),
    proto.home.Request.toObject, includeInstance),
    categoryRanking: (f = msg.getCategoryRanking()) && proto.home.CategoryRanking.toObject(includeInstance, f),
    newsList: jspb.Message.toObjectList(msg.getNewsList(),
    proto.home.News.toObject, includeInstance),
    legalQasList: jspb.Message.toObjectList(msg.getLegalQasList(),
    proto.home.LegalQa.toObject, includeInstance),
    recommendBlogsList: jspb.Message.toObjectList(msg.getRecommendBlogsList(),
    proto.home.RecommendBlog.toObject, includeInstance),
    heroAreasNoLoggedInList: jspb.Message.toObjectList(msg.getHeroAreasNoLoggedInList(),
    proto.home.HeroArea.toObject, includeInstance),
    recommendCategoriesList: jspb.Message.toObjectList(msg.getRecommendCategoriesList(),
    proto.home.RecommendCategory.toObject, includeInstance),
    heroAreasBuyerList: jspb.Message.toObjectList(msg.getHeroAreasBuyerList(),
    proto.home.HeroArea.toObject, includeInstance),
    heroAreasProviderList: jspb.Message.toObjectList(msg.getHeroAreasProviderList(),
    proto.home.HeroArea.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.GetHomeContentReply}
 */
proto.home.GetHomeContentReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.GetHomeContentReply;
  return proto.home.GetHomeContentReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.GetHomeContentReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.GetHomeContentReply}
 */
proto.home.GetHomeContentReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.home.UseScene;
      reader.readMessage(value,proto.home.UseScene.deserializeBinaryFromReader);
      msg.addUseScenes(value);
      break;
    case 3:
      var value = new proto.home.Service;
      reader.readMessage(value,proto.home.Service.deserializeBinaryFromReader);
      msg.addRecommendServices(value);
      break;
    case 4:
      var value = new proto.home.User;
      reader.readMessage(value,proto.home.User.deserializeBinaryFromReader);
      msg.addRecommendUsers(value);
      break;
    case 5:
      var value = new proto.home.Request;
      reader.readMessage(value,proto.home.Request.deserializeBinaryFromReader);
      msg.addRecommendRequests(value);
      break;
    case 6:
      var value = new proto.home.CategoryRanking;
      reader.readMessage(value,proto.home.CategoryRanking.deserializeBinaryFromReader);
      msg.setCategoryRanking(value);
      break;
    case 7:
      var value = new proto.home.News;
      reader.readMessage(value,proto.home.News.deserializeBinaryFromReader);
      msg.addNews(value);
      break;
    case 8:
      var value = new proto.home.LegalQa;
      reader.readMessage(value,proto.home.LegalQa.deserializeBinaryFromReader);
      msg.addLegalQas(value);
      break;
    case 9:
      var value = new proto.home.RecommendBlog;
      reader.readMessage(value,proto.home.RecommendBlog.deserializeBinaryFromReader);
      msg.addRecommendBlogs(value);
      break;
    case 10:
      var value = new proto.home.HeroArea;
      reader.readMessage(value,proto.home.HeroArea.deserializeBinaryFromReader);
      msg.addHeroAreasNoLoggedIn(value);
      break;
    case 11:
      var value = new proto.home.RecommendCategory;
      reader.readMessage(value,proto.home.RecommendCategory.deserializeBinaryFromReader);
      msg.addRecommendCategories(value);
      break;
    case 12:
      var value = new proto.home.HeroArea;
      reader.readMessage(value,proto.home.HeroArea.deserializeBinaryFromReader);
      msg.addHeroAreasBuyer(value);
      break;
    case 13:
      var value = new proto.home.HeroArea;
      reader.readMessage(value,proto.home.HeroArea.deserializeBinaryFromReader);
      msg.addHeroAreasProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.GetHomeContentReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.GetHomeContentReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.GetHomeContentReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetHomeContentReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUseScenesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.home.UseScene.serializeBinaryToWriter
    );
  }
  f = message.getRecommendServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.home.Service.serializeBinaryToWriter
    );
  }
  f = message.getRecommendUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.home.User.serializeBinaryToWriter
    );
  }
  f = message.getRecommendRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.home.Request.serializeBinaryToWriter
    );
  }
  f = message.getCategoryRanking();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.home.CategoryRanking.serializeBinaryToWriter
    );
  }
  f = message.getNewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.home.News.serializeBinaryToWriter
    );
  }
  f = message.getLegalQasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.home.LegalQa.serializeBinaryToWriter
    );
  }
  f = message.getRecommendBlogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.home.RecommendBlog.serializeBinaryToWriter
    );
  }
  f = message.getHeroAreasNoLoggedInList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.home.HeroArea.serializeBinaryToWriter
    );
  }
  f = message.getRecommendCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.home.RecommendCategory.serializeBinaryToWriter
    );
  }
  f = message.getHeroAreasBuyerList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.home.HeroArea.serializeBinaryToWriter
    );
  }
  f = message.getHeroAreasProviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.home.HeroArea.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UseScene use_scenes = 2;
 * @return {!Array<!proto.home.UseScene>}
 */
proto.home.GetHomeContentReply.prototype.getUseScenesList = function() {
  return /** @type{!Array<!proto.home.UseScene>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.UseScene, 2));
};


/**
 * @param {!Array<!proto.home.UseScene>} value
 * @return {!proto.home.GetHomeContentReply} returns this
*/
proto.home.GetHomeContentReply.prototype.setUseScenesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.home.UseScene=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.UseScene}
 */
proto.home.GetHomeContentReply.prototype.addUseScenes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.home.UseScene, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.GetHomeContentReply} returns this
 */
proto.home.GetHomeContentReply.prototype.clearUseScenesList = function() {
  return this.setUseScenesList([]);
};


/**
 * repeated Service recommend_services = 3;
 * @return {!Array<!proto.home.Service>}
 */
proto.home.GetHomeContentReply.prototype.getRecommendServicesList = function() {
  return /** @type{!Array<!proto.home.Service>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.Service, 3));
};


/**
 * @param {!Array<!proto.home.Service>} value
 * @return {!proto.home.GetHomeContentReply} returns this
*/
proto.home.GetHomeContentReply.prototype.setRecommendServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.home.Service=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.Service}
 */
proto.home.GetHomeContentReply.prototype.addRecommendServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.home.Service, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.GetHomeContentReply} returns this
 */
proto.home.GetHomeContentReply.prototype.clearRecommendServicesList = function() {
  return this.setRecommendServicesList([]);
};


/**
 * repeated User recommend_users = 4;
 * @return {!Array<!proto.home.User>}
 */
proto.home.GetHomeContentReply.prototype.getRecommendUsersList = function() {
  return /** @type{!Array<!proto.home.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.User, 4));
};


/**
 * @param {!Array<!proto.home.User>} value
 * @return {!proto.home.GetHomeContentReply} returns this
*/
proto.home.GetHomeContentReply.prototype.setRecommendUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.home.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.User}
 */
proto.home.GetHomeContentReply.prototype.addRecommendUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.home.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.GetHomeContentReply} returns this
 */
proto.home.GetHomeContentReply.prototype.clearRecommendUsersList = function() {
  return this.setRecommendUsersList([]);
};


/**
 * repeated Request recommend_requests = 5;
 * @return {!Array<!proto.home.Request>}
 */
proto.home.GetHomeContentReply.prototype.getRecommendRequestsList = function() {
  return /** @type{!Array<!proto.home.Request>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.Request, 5));
};


/**
 * @param {!Array<!proto.home.Request>} value
 * @return {!proto.home.GetHomeContentReply} returns this
*/
proto.home.GetHomeContentReply.prototype.setRecommendRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.home.Request=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.Request}
 */
proto.home.GetHomeContentReply.prototype.addRecommendRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.home.Request, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.GetHomeContentReply} returns this
 */
proto.home.GetHomeContentReply.prototype.clearRecommendRequestsList = function() {
  return this.setRecommendRequestsList([]);
};


/**
 * optional CategoryRanking category_ranking = 6;
 * @return {?proto.home.CategoryRanking}
 */
proto.home.GetHomeContentReply.prototype.getCategoryRanking = function() {
  return /** @type{?proto.home.CategoryRanking} */ (
    jspb.Message.getWrapperField(this, proto.home.CategoryRanking, 6));
};


/**
 * @param {?proto.home.CategoryRanking|undefined} value
 * @return {!proto.home.GetHomeContentReply} returns this
*/
proto.home.GetHomeContentReply.prototype.setCategoryRanking = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.GetHomeContentReply} returns this
 */
proto.home.GetHomeContentReply.prototype.clearCategoryRanking = function() {
  return this.setCategoryRanking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.GetHomeContentReply.prototype.hasCategoryRanking = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated News news = 7;
 * @return {!Array<!proto.home.News>}
 */
proto.home.GetHomeContentReply.prototype.getNewsList = function() {
  return /** @type{!Array<!proto.home.News>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.News, 7));
};


/**
 * @param {!Array<!proto.home.News>} value
 * @return {!proto.home.GetHomeContentReply} returns this
*/
proto.home.GetHomeContentReply.prototype.setNewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.home.News=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.News}
 */
proto.home.GetHomeContentReply.prototype.addNews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.home.News, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.GetHomeContentReply} returns this
 */
proto.home.GetHomeContentReply.prototype.clearNewsList = function() {
  return this.setNewsList([]);
};


/**
 * repeated LegalQa legal_qas = 8;
 * @return {!Array<!proto.home.LegalQa>}
 */
proto.home.GetHomeContentReply.prototype.getLegalQasList = function() {
  return /** @type{!Array<!proto.home.LegalQa>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.LegalQa, 8));
};


/**
 * @param {!Array<!proto.home.LegalQa>} value
 * @return {!proto.home.GetHomeContentReply} returns this
*/
proto.home.GetHomeContentReply.prototype.setLegalQasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.home.LegalQa=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.LegalQa}
 */
proto.home.GetHomeContentReply.prototype.addLegalQas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.home.LegalQa, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.GetHomeContentReply} returns this
 */
proto.home.GetHomeContentReply.prototype.clearLegalQasList = function() {
  return this.setLegalQasList([]);
};


/**
 * repeated RecommendBlog recommend_blogs = 9;
 * @return {!Array<!proto.home.RecommendBlog>}
 */
proto.home.GetHomeContentReply.prototype.getRecommendBlogsList = function() {
  return /** @type{!Array<!proto.home.RecommendBlog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.RecommendBlog, 9));
};


/**
 * @param {!Array<!proto.home.RecommendBlog>} value
 * @return {!proto.home.GetHomeContentReply} returns this
*/
proto.home.GetHomeContentReply.prototype.setRecommendBlogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.home.RecommendBlog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.RecommendBlog}
 */
proto.home.GetHomeContentReply.prototype.addRecommendBlogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.home.RecommendBlog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.GetHomeContentReply} returns this
 */
proto.home.GetHomeContentReply.prototype.clearRecommendBlogsList = function() {
  return this.setRecommendBlogsList([]);
};


/**
 * repeated HeroArea hero_areas_no_logged_in = 10;
 * @return {!Array<!proto.home.HeroArea>}
 */
proto.home.GetHomeContentReply.prototype.getHeroAreasNoLoggedInList = function() {
  return /** @type{!Array<!proto.home.HeroArea>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.HeroArea, 10));
};


/**
 * @param {!Array<!proto.home.HeroArea>} value
 * @return {!proto.home.GetHomeContentReply} returns this
*/
proto.home.GetHomeContentReply.prototype.setHeroAreasNoLoggedInList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.home.HeroArea=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.HeroArea}
 */
proto.home.GetHomeContentReply.prototype.addHeroAreasNoLoggedIn = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.home.HeroArea, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.GetHomeContentReply} returns this
 */
proto.home.GetHomeContentReply.prototype.clearHeroAreasNoLoggedInList = function() {
  return this.setHeroAreasNoLoggedInList([]);
};


/**
 * repeated RecommendCategory recommend_categories = 11;
 * @return {!Array<!proto.home.RecommendCategory>}
 */
proto.home.GetHomeContentReply.prototype.getRecommendCategoriesList = function() {
  return /** @type{!Array<!proto.home.RecommendCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.RecommendCategory, 11));
};


/**
 * @param {!Array<!proto.home.RecommendCategory>} value
 * @return {!proto.home.GetHomeContentReply} returns this
*/
proto.home.GetHomeContentReply.prototype.setRecommendCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.home.RecommendCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.RecommendCategory}
 */
proto.home.GetHomeContentReply.prototype.addRecommendCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.home.RecommendCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.GetHomeContentReply} returns this
 */
proto.home.GetHomeContentReply.prototype.clearRecommendCategoriesList = function() {
  return this.setRecommendCategoriesList([]);
};


/**
 * repeated HeroArea hero_areas_buyer = 12;
 * @return {!Array<!proto.home.HeroArea>}
 */
proto.home.GetHomeContentReply.prototype.getHeroAreasBuyerList = function() {
  return /** @type{!Array<!proto.home.HeroArea>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.HeroArea, 12));
};


/**
 * @param {!Array<!proto.home.HeroArea>} value
 * @return {!proto.home.GetHomeContentReply} returns this
*/
proto.home.GetHomeContentReply.prototype.setHeroAreasBuyerList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.home.HeroArea=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.HeroArea}
 */
proto.home.GetHomeContentReply.prototype.addHeroAreasBuyer = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.home.HeroArea, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.GetHomeContentReply} returns this
 */
proto.home.GetHomeContentReply.prototype.clearHeroAreasBuyerList = function() {
  return this.setHeroAreasBuyerList([]);
};


/**
 * repeated HeroArea hero_areas_provider = 13;
 * @return {!Array<!proto.home.HeroArea>}
 */
proto.home.GetHomeContentReply.prototype.getHeroAreasProviderList = function() {
  return /** @type{!Array<!proto.home.HeroArea>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.HeroArea, 13));
};


/**
 * @param {!Array<!proto.home.HeroArea>} value
 * @return {!proto.home.GetHomeContentReply} returns this
*/
proto.home.GetHomeContentReply.prototype.setHeroAreasProviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.home.HeroArea=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.HeroArea}
 */
proto.home.GetHomeContentReply.prototype.addHeroAreasProvider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.home.HeroArea, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.GetHomeContentReply} returns this
 */
proto.home.GetHomeContentReply.prototype.clearHeroAreasProviderList = function() {
  return this.setHeroAreasProviderList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.GetMyFavoriteServicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.home.GetMyFavoriteServicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.GetMyFavoriteServicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetMyFavoriteServicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.GetMyFavoriteServicesRequest}
 */
proto.home.GetMyFavoriteServicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.GetMyFavoriteServicesRequest;
  return proto.home.GetMyFavoriteServicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.GetMyFavoriteServicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.GetMyFavoriteServicesRequest}
 */
proto.home.GetMyFavoriteServicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.GetMyFavoriteServicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.GetMyFavoriteServicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.GetMyFavoriteServicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetMyFavoriteServicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.home.GetMyFavoriteServicesReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.GetMyFavoriteServicesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.home.GetMyFavoriteServicesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.GetMyFavoriteServicesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetMyFavoriteServicesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.home.Service.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.GetMyFavoriteServicesReply}
 */
proto.home.GetMyFavoriteServicesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.GetMyFavoriteServicesReply;
  return proto.home.GetMyFavoriteServicesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.GetMyFavoriteServicesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.GetMyFavoriteServicesReply}
 */
proto.home.GetMyFavoriteServicesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.home.Service;
      reader.readMessage(value,proto.home.Service.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.GetMyFavoriteServicesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.GetMyFavoriteServicesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.GetMyFavoriteServicesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetMyFavoriteServicesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.home.Service.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Service services = 1;
 * @return {!Array<!proto.home.Service>}
 */
proto.home.GetMyFavoriteServicesReply.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.home.Service>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.Service, 1));
};


/**
 * @param {!Array<!proto.home.Service>} value
 * @return {!proto.home.GetMyFavoriteServicesReply} returns this
*/
proto.home.GetMyFavoriteServicesReply.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.home.Service=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.Service}
 */
proto.home.GetMyFavoriteServicesReply.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.home.Service, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.GetMyFavoriteServicesReply} returns this
 */
proto.home.GetMyFavoriteServicesReply.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.GetMyServiceHistoriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.home.GetMyServiceHistoriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.GetMyServiceHistoriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetMyServiceHistoriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    history: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.GetMyServiceHistoriesRequest}
 */
proto.home.GetMyServiceHistoriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.GetMyServiceHistoriesRequest;
  return proto.home.GetMyServiceHistoriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.GetMyServiceHistoriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.GetMyServiceHistoriesRequest}
 */
proto.home.GetMyServiceHistoriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHistory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.GetMyServiceHistoriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.GetMyServiceHistoriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.GetMyServiceHistoriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetMyServiceHistoriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHistory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string history = 1;
 * @return {string}
 */
proto.home.GetMyServiceHistoriesRequest.prototype.getHistory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.GetMyServiceHistoriesRequest} returns this
 */
proto.home.GetMyServiceHistoriesRequest.prototype.setHistory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.home.GetMyServiceHistoriesReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.GetMyServiceHistoriesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.home.GetMyServiceHistoriesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.GetMyServiceHistoriesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetMyServiceHistoriesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.home.Service.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.GetMyServiceHistoriesReply}
 */
proto.home.GetMyServiceHistoriesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.GetMyServiceHistoriesReply;
  return proto.home.GetMyServiceHistoriesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.GetMyServiceHistoriesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.GetMyServiceHistoriesReply}
 */
proto.home.GetMyServiceHistoriesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.home.Service;
      reader.readMessage(value,proto.home.Service.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.GetMyServiceHistoriesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.GetMyServiceHistoriesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.GetMyServiceHistoriesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetMyServiceHistoriesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.home.Service.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Service services = 1;
 * @return {!Array<!proto.home.Service>}
 */
proto.home.GetMyServiceHistoriesReply.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.home.Service>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.Service, 1));
};


/**
 * @param {!Array<!proto.home.Service>} value
 * @return {!proto.home.GetMyServiceHistoriesReply} returns this
*/
proto.home.GetMyServiceHistoriesReply.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.home.Service=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.Service}
 */
proto.home.GetMyServiceHistoriesReply.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.home.Service, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.GetMyServiceHistoriesReply} returns this
 */
proto.home.GetMyServiceHistoriesReply.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.GetMyRecommendRequestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.home.GetMyRecommendRequestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.GetMyRecommendRequestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetMyRecommendRequestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    algorithm: (f = msg.getAlgorithm()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.GetMyRecommendRequestsRequest}
 */
proto.home.GetMyRecommendRequestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.GetMyRecommendRequestsRequest;
  return proto.home.GetMyRecommendRequestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.GetMyRecommendRequestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.GetMyRecommendRequestsRequest}
 */
proto.home.GetMyRecommendRequestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAlgorithm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.GetMyRecommendRequestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.GetMyRecommendRequestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.GetMyRecommendRequestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetMyRecommendRequestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlgorithm();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue algorithm = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.home.GetMyRecommendRequestsRequest.prototype.getAlgorithm = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.home.GetMyRecommendRequestsRequest} returns this
*/
proto.home.GetMyRecommendRequestsRequest.prototype.setAlgorithm = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.GetMyRecommendRequestsRequest} returns this
 */
proto.home.GetMyRecommendRequestsRequest.prototype.clearAlgorithm = function() {
  return this.setAlgorithm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.GetMyRecommendRequestsRequest.prototype.hasAlgorithm = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.home.GetMyRecommendRequestsResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.GetMyRecommendRequestsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.home.GetMyRecommendRequestsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.GetMyRecommendRequestsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetMyRecommendRequestsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    algorithm: (f = msg.getAlgorithm()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    impressionParamsJson: (f = msg.getImpressionParamsJson()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    recommendRequestsList: jspb.Message.toObjectList(msg.getRecommendRequestsList(),
    proto.home.RecommendRequest.toObject, includeInstance),
    currentDate: (f = msg.getCurrentDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.GetMyRecommendRequestsResponse}
 */
proto.home.GetMyRecommendRequestsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.GetMyRecommendRequestsResponse;
  return proto.home.GetMyRecommendRequestsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.GetMyRecommendRequestsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.GetMyRecommendRequestsResponse}
 */
proto.home.GetMyRecommendRequestsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAlgorithm(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setImpressionParamsJson(value);
      break;
    case 3:
      var value = new proto.home.RecommendRequest;
      reader.readMessage(value,proto.home.RecommendRequest.deserializeBinaryFromReader);
      msg.addRecommendRequests(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrentDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.GetMyRecommendRequestsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.GetMyRecommendRequestsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.GetMyRecommendRequestsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.GetMyRecommendRequestsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlgorithm();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getImpressionParamsJson();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRecommendRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.home.RecommendRequest.serializeBinaryToWriter
    );
  }
  f = message.getCurrentDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue algorithm = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.home.GetMyRecommendRequestsResponse.prototype.getAlgorithm = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.home.GetMyRecommendRequestsResponse} returns this
*/
proto.home.GetMyRecommendRequestsResponse.prototype.setAlgorithm = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.GetMyRecommendRequestsResponse} returns this
 */
proto.home.GetMyRecommendRequestsResponse.prototype.clearAlgorithm = function() {
  return this.setAlgorithm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.GetMyRecommendRequestsResponse.prototype.hasAlgorithm = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue impression_params_json = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.home.GetMyRecommendRequestsResponse.prototype.getImpressionParamsJson = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.home.GetMyRecommendRequestsResponse} returns this
*/
proto.home.GetMyRecommendRequestsResponse.prototype.setImpressionParamsJson = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.GetMyRecommendRequestsResponse} returns this
 */
proto.home.GetMyRecommendRequestsResponse.prototype.clearImpressionParamsJson = function() {
  return this.setImpressionParamsJson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.GetMyRecommendRequestsResponse.prototype.hasImpressionParamsJson = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated RecommendRequest recommend_requests = 3;
 * @return {!Array<!proto.home.RecommendRequest>}
 */
proto.home.GetMyRecommendRequestsResponse.prototype.getRecommendRequestsList = function() {
  return /** @type{!Array<!proto.home.RecommendRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.RecommendRequest, 3));
};


/**
 * @param {!Array<!proto.home.RecommendRequest>} value
 * @return {!proto.home.GetMyRecommendRequestsResponse} returns this
*/
proto.home.GetMyRecommendRequestsResponse.prototype.setRecommendRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.home.RecommendRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.RecommendRequest}
 */
proto.home.GetMyRecommendRequestsResponse.prototype.addRecommendRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.home.RecommendRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.GetMyRecommendRequestsResponse} returns this
 */
proto.home.GetMyRecommendRequestsResponse.prototype.clearRecommendRequestsList = function() {
  return this.setRecommendRequestsList([]);
};


/**
 * optional google.protobuf.Timestamp current_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.home.GetMyRecommendRequestsResponse.prototype.getCurrentDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.home.GetMyRecommendRequestsResponse} returns this
*/
proto.home.GetMyRecommendRequestsResponse.prototype.setCurrentDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.GetMyRecommendRequestsResponse} returns this
 */
proto.home.GetMyRecommendRequestsResponse.prototype.clearCurrentDate = function() {
  return this.setCurrentDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.GetMyRecommendRequestsResponse.prototype.hasCurrentDate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.HeroArea.prototype.toObject = function(opt_includeInstance) {
  return proto.home.HeroArea.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.HeroArea} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.HeroArea.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    image: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.HeroArea}
 */
proto.home.HeroArea.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.HeroArea;
  return proto.home.HeroArea.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.HeroArea} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.HeroArea}
 */
proto.home.HeroArea.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.HeroArea.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.HeroArea.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.HeroArea} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.HeroArea.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.home.HeroArea.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.HeroArea} returns this
 */
proto.home.HeroArea.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image = 2;
 * @return {string}
 */
proto.home.HeroArea.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.HeroArea} returns this
 */
proto.home.HeroArea.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.home.HeroArea.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.HeroArea} returns this
 */
proto.home.HeroArea.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.UseScene.prototype.toObject = function(opt_includeInstance) {
  return proto.home.UseScene.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.UseScene} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.UseScene.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    image: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    linkText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isBusiness: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.UseScene}
 */
proto.home.UseScene.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.UseScene;
  return proto.home.UseScene.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.UseScene} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.UseScene}
 */
proto.home.UseScene.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkText(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBusiness(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.UseScene.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.UseScene.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.UseScene} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.UseScene.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLinkText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsBusiness();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.home.UseScene.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.UseScene} returns this
 */
proto.home.UseScene.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image = 2;
 * @return {string}
 */
proto.home.UseScene.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.UseScene} returns this
 */
proto.home.UseScene.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.home.UseScene.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.UseScene} returns this
 */
proto.home.UseScene.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string link_text = 4;
 * @return {string}
 */
proto.home.UseScene.prototype.getLinkText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.UseScene} returns this
 */
proto.home.UseScene.prototype.setLinkText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_business = 5;
 * @return {boolean}
 */
proto.home.UseScene.prototype.getIsBusiness = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.home.UseScene} returns this
 */
proto.home.UseScene.prototype.setIsBusiness = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.Service.prototype.toObject = function(opt_includeInstance) {
  return proto.home.Service.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.Service} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.Service.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    priceWot: jspb.Message.getFieldWithDefault(msg, 3, 0),
    serviceClass: jspb.Message.getFieldWithDefault(msg, 4, 0),
    videoType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    proServiceFlag: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    moviePriorityFlag: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    videoServiceFlag: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    unitTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    serviceImage: jspb.Message.getFieldWithDefault(msg, 10, ""),
    serviceMovie: jspb.Message.getFieldWithDefault(msg, 11, ""),
    provider: (f = msg.getProvider()) && proto.home.ServiceProvider.toObject(includeInstance, f),
    ratings: (f = msg.getRatings()) && proto.home.RatingData.toObject(includeInstance, f),
    performanceCount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    certificationDivinationServiceFlag: jspb.Message.getBooleanFieldWithDefault(msg, 15, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.Service}
 */
proto.home.Service.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.Service;
  return proto.home.Service.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.Service} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.Service}
 */
proto.home.Service.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceWot(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setServiceClass(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVideoType(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProServiceFlag(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMoviePriorityFlag(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVideoServiceFlag(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnitTime(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceImage(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceMovie(value);
      break;
    case 12:
      var value = new proto.home.ServiceProvider;
      reader.readMessage(value,proto.home.ServiceProvider.deserializeBinaryFromReader);
      msg.setProvider(value);
      break;
    case 13:
      var value = new proto.home.RatingData;
      reader.readMessage(value,proto.home.RatingData.deserializeBinaryFromReader);
      msg.setRatings(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerformanceCount(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCertificationDivinationServiceFlag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.Service.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.Service.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.Service} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.Service.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPriceWot();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getServiceClass();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getVideoType();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getProServiceFlag();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getMoviePriorityFlag();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getVideoServiceFlag();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getUnitTime();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getServiceImage();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getServiceMovie();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getProvider();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.home.ServiceProvider.serializeBinaryToWriter
    );
  }
  f = message.getRatings();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.home.RatingData.serializeBinaryToWriter
    );
  }
  f = message.getPerformanceCount();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getCertificationDivinationServiceFlag();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.home.Service.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.home.Service.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 price_wot = 3;
 * @return {number}
 */
proto.home.Service.prototype.getPriceWot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.setPriceWot = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 service_class = 4;
 * @return {number}
 */
proto.home.Service.prototype.getServiceClass = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.setServiceClass = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 video_type = 5;
 * @return {number}
 */
proto.home.Service.prototype.getVideoType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.setVideoType = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool pro_service_flag = 6;
 * @return {boolean}
 */
proto.home.Service.prototype.getProServiceFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.setProServiceFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool movie_priority_flag = 7;
 * @return {boolean}
 */
proto.home.Service.prototype.getMoviePriorityFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.setMoviePriorityFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool video_service_flag = 8;
 * @return {boolean}
 */
proto.home.Service.prototype.getVideoServiceFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.setVideoServiceFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 unit_time = 9;
 * @return {number}
 */
proto.home.Service.prototype.getUnitTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.setUnitTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string service_image = 10;
 * @return {string}
 */
proto.home.Service.prototype.getServiceImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.setServiceImage = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string service_movie = 11;
 * @return {string}
 */
proto.home.Service.prototype.getServiceMovie = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.setServiceMovie = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional ServiceProvider provider = 12;
 * @return {?proto.home.ServiceProvider}
 */
proto.home.Service.prototype.getProvider = function() {
  return /** @type{?proto.home.ServiceProvider} */ (
    jspb.Message.getWrapperField(this, proto.home.ServiceProvider, 12));
};


/**
 * @param {?proto.home.ServiceProvider|undefined} value
 * @return {!proto.home.Service} returns this
*/
proto.home.Service.prototype.setProvider = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.clearProvider = function() {
  return this.setProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.Service.prototype.hasProvider = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional RatingData ratings = 13;
 * @return {?proto.home.RatingData}
 */
proto.home.Service.prototype.getRatings = function() {
  return /** @type{?proto.home.RatingData} */ (
    jspb.Message.getWrapperField(this, proto.home.RatingData, 13));
};


/**
 * @param {?proto.home.RatingData|undefined} value
 * @return {!proto.home.Service} returns this
*/
proto.home.Service.prototype.setRatings = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.clearRatings = function() {
  return this.setRatings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.Service.prototype.hasRatings = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int32 performance_count = 14;
 * @return {number}
 */
proto.home.Service.prototype.getPerformanceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.setPerformanceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional bool certification_divination_service_flag = 15;
 * @return {boolean}
 */
proto.home.Service.prototype.getCertificationDivinationServiceFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.home.Service} returns this
 */
proto.home.Service.prototype.setCertificationDivinationServiceFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.ServiceProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.home.ServiceProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.ServiceProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.ServiceProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profileIconPath: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.ServiceProvider}
 */
proto.home.ServiceProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.ServiceProvider;
  return proto.home.ServiceProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.ServiceProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.ServiceProvider}
 */
proto.home.ServiceProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileIconPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.ServiceProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.ServiceProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.ServiceProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.ServiceProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfileIconPath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.home.ServiceProvider.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.ServiceProvider} returns this
 */
proto.home.ServiceProvider.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.home.ServiceProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.ServiceProvider} returns this
 */
proto.home.ServiceProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_icon_path = 3;
 * @return {string}
 */
proto.home.ServiceProvider.prototype.getProfileIconPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.ServiceProvider} returns this
 */
proto.home.ServiceProvider.prototype.setProfileIconPath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.RatingData.prototype.toObject = function(opt_includeInstance) {
  return proto.home.RatingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.RatingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.RatingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    indicator: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    ratingCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.RatingData}
 */
proto.home.RatingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.RatingData;
  return proto.home.RatingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.RatingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.RatingData}
 */
proto.home.RatingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIndicator(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRatingCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.RatingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.RatingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.RatingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.RatingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndicator();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getRatingCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional double indicator = 1;
 * @return {number}
 */
proto.home.RatingData.prototype.getIndicator = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.home.RatingData} returns this
 */
proto.home.RatingData.prototype.setIndicator = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int32 rating_count = 2;
 * @return {number}
 */
proto.home.RatingData.prototype.getRatingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.RatingData} returns this
 */
proto.home.RatingData.prototype.setRatingCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.home.User.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.User.prototype.toObject = function(opt_includeInstance) {
  return proto.home.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resizableProfileIcon: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    occupation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    averageRating: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    paidOrderCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    userSpecialtiesList: jspb.Message.toObjectList(msg.getUserSpecialtiesList(),
    proto.home.UserSearchSpecialty.toObject, includeInstance),
    providerLevel: (f = msg.getProviderLevel()) && proto.home.SearchProviderLevel.toObject(includeInstance, f),
    isPro: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isCertificationDivination: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.User}
 */
proto.home.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.User;
  return proto.home.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.User}
 */
proto.home.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResizableProfileIcon(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAverageRating(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaidOrderCount(value);
      break;
    case 7:
      var value = new proto.home.UserSearchSpecialty;
      reader.readMessage(value,proto.home.UserSearchSpecialty.deserializeBinaryFromReader);
      msg.addUserSpecialties(value);
      break;
    case 8:
      var value = new proto.home.SearchProviderLevel;
      reader.readMessage(value,proto.home.SearchProviderLevel.deserializeBinaryFromReader);
      msg.setProviderLevel(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPro(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCertificationDivination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getResizableProfileIcon();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAverageRating();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getPaidOrderCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getUserSpecialtiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.home.UserSearchSpecialty.serializeBinaryToWriter
    );
  }
  f = message.getProviderLevel();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.home.SearchProviderLevel.serializeBinaryToWriter
    );
  }
  f = message.getIsPro();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsCertificationDivination();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.home.User.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.User} returns this
 */
proto.home.User.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string resizable_profile_icon = 2;
 * @return {string}
 */
proto.home.User.prototype.getResizableProfileIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.User} returns this
 */
proto.home.User.prototype.setResizableProfileIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_name = 3;
 * @return {string}
 */
proto.home.User.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.User} returns this
 */
proto.home.User.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string occupation = 4;
 * @return {string}
 */
proto.home.User.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.User} returns this
 */
proto.home.User.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float average_rating = 5;
 * @return {number}
 */
proto.home.User.prototype.getAverageRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.home.User} returns this
 */
proto.home.User.prototype.setAverageRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 paid_order_count = 6;
 * @return {number}
 */
proto.home.User.prototype.getPaidOrderCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.User} returns this
 */
proto.home.User.prototype.setPaidOrderCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated UserSearchSpecialty user_specialties = 7;
 * @return {!Array<!proto.home.UserSearchSpecialty>}
 */
proto.home.User.prototype.getUserSpecialtiesList = function() {
  return /** @type{!Array<!proto.home.UserSearchSpecialty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.UserSearchSpecialty, 7));
};


/**
 * @param {!Array<!proto.home.UserSearchSpecialty>} value
 * @return {!proto.home.User} returns this
*/
proto.home.User.prototype.setUserSpecialtiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.home.UserSearchSpecialty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.UserSearchSpecialty}
 */
proto.home.User.prototype.addUserSpecialties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.home.UserSearchSpecialty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.User} returns this
 */
proto.home.User.prototype.clearUserSpecialtiesList = function() {
  return this.setUserSpecialtiesList([]);
};


/**
 * optional SearchProviderLevel provider_level = 8;
 * @return {?proto.home.SearchProviderLevel}
 */
proto.home.User.prototype.getProviderLevel = function() {
  return /** @type{?proto.home.SearchProviderLevel} */ (
    jspb.Message.getWrapperField(this, proto.home.SearchProviderLevel, 8));
};


/**
 * @param {?proto.home.SearchProviderLevel|undefined} value
 * @return {!proto.home.User} returns this
*/
proto.home.User.prototype.setProviderLevel = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.User} returns this
 */
proto.home.User.prototype.clearProviderLevel = function() {
  return this.setProviderLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.User.prototype.hasProviderLevel = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_pro = 9;
 * @return {boolean}
 */
proto.home.User.prototype.getIsPro = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.home.User} returns this
 */
proto.home.User.prototype.setIsPro = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_certification_divination = 10;
 * @return {boolean}
 */
proto.home.User.prototype.getIsCertificationDivination = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.home.User} returns this
 */
proto.home.User.prototype.setIsCertificationDivination = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.UserSearchSpecialty.prototype.toObject = function(opt_includeInstance) {
  return proto.home.UserSearchSpecialty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.UserSearchSpecialty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.UserSearchSpecialty.toObject = function(includeInstance, msg) {
  var f, obj = {
    masterCategoryId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.UserSearchSpecialty}
 */
proto.home.UserSearchSpecialty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.UserSearchSpecialty;
  return proto.home.UserSearchSpecialty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.UserSearchSpecialty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.UserSearchSpecialty}
 */
proto.home.UserSearchSpecialty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMasterCategoryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.UserSearchSpecialty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.UserSearchSpecialty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.UserSearchSpecialty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.UserSearchSpecialty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 master_category_id = 2;
 * @return {number}
 */
proto.home.UserSearchSpecialty.prototype.getMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.UserSearchSpecialty} returns this
 */
proto.home.UserSearchSpecialty.prototype.setMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.SearchProviderLevel.prototype.toObject = function(opt_includeInstance) {
  return proto.home.SearchProviderLevel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.SearchProviderLevel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.SearchProviderLevel.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    textJp: jspb.Message.getFieldWithDefault(msg, 2, ""),
    textEn: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.SearchProviderLevel}
 */
proto.home.SearchProviderLevel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.SearchProviderLevel;
  return proto.home.SearchProviderLevel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.SearchProviderLevel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.SearchProviderLevel}
 */
proto.home.SearchProviderLevel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextJp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextEn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.SearchProviderLevel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.SearchProviderLevel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.SearchProviderLevel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.SearchProviderLevel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTextJp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTextEn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 value = 1;
 * @return {number}
 */
proto.home.SearchProviderLevel.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.SearchProviderLevel} returns this
 */
proto.home.SearchProviderLevel.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text_jp = 2;
 * @return {string}
 */
proto.home.SearchProviderLevel.prototype.getTextJp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.SearchProviderLevel} returns this
 */
proto.home.SearchProviderLevel.prototype.setTextJp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text_en = 3;
 * @return {string}
 */
proto.home.SearchProviderLevel.prototype.getTextEn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.SearchProviderLevel} returns this
 */
proto.home.SearchProviderLevel.prototype.setTextEn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.home.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    recruitmentDeadline: jspb.Message.getFieldWithDefault(msg, 5, 0),
    minBudget: jspb.Message.getFieldWithDefault(msg, 6, 0),
    maxBudget: jspb.Message.getFieldWithDefault(msg, 7, 0),
    budgetConsultation: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    proposalCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    userName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    resizableProfileIcon: jspb.Message.getFieldWithDefault(msg, 11, ""),
    userGender: jspb.Message.getFieldWithDefault(msg, 12, ""),
    userLastLogin: jspb.Message.getFieldWithDefault(msg, 13, 0),
    created: jspb.Message.getFieldWithDefault(msg, 14, 0),
    parentMasterCategoryId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    childMasterCategoryId: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.Request}
 */
proto.home.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.Request;
  return proto.home.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.Request}
 */
proto.home.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecruitmentDeadline(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinBudget(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxBudget(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBudgetConsultation(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProposalCount(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setResizableProfileIcon(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGender(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserLastLogin(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParentMasterCategoryId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChildMasterCategoryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRecruitmentDeadline();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMinBudget();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMaxBudget();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getBudgetConsultation();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getProposalCount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getResizableProfileIcon();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUserGender();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUserLastLogin();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getParentMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getChildMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
};


/**
 * optional int32 request_id = 1;
 * @return {number}
 */
proto.home.Request.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Request} returns this
 */
proto.home.Request.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.home.Request.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.Request} returns this
 */
proto.home.Request.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 recruitment_deadline = 5;
 * @return {number}
 */
proto.home.Request.prototype.getRecruitmentDeadline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Request} returns this
 */
proto.home.Request.prototype.setRecruitmentDeadline = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 min_budget = 6;
 * @return {number}
 */
proto.home.Request.prototype.getMinBudget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Request} returns this
 */
proto.home.Request.prototype.setMinBudget = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 max_budget = 7;
 * @return {number}
 */
proto.home.Request.prototype.getMaxBudget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Request} returns this
 */
proto.home.Request.prototype.setMaxBudget = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool budget_consultation = 8;
 * @return {boolean}
 */
proto.home.Request.prototype.getBudgetConsultation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.home.Request} returns this
 */
proto.home.Request.prototype.setBudgetConsultation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 proposal_count = 9;
 * @return {number}
 */
proto.home.Request.prototype.getProposalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Request} returns this
 */
proto.home.Request.prototype.setProposalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string user_name = 10;
 * @return {string}
 */
proto.home.Request.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.Request} returns this
 */
proto.home.Request.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string resizable_profile_icon = 11;
 * @return {string}
 */
proto.home.Request.prototype.getResizableProfileIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.Request} returns this
 */
proto.home.Request.prototype.setResizableProfileIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string user_gender = 12;
 * @return {string}
 */
proto.home.Request.prototype.getUserGender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.Request} returns this
 */
proto.home.Request.prototype.setUserGender = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 user_last_login = 13;
 * @return {number}
 */
proto.home.Request.prototype.getUserLastLogin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Request} returns this
 */
proto.home.Request.prototype.setUserLastLogin = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 created = 14;
 * @return {number}
 */
proto.home.Request.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Request} returns this
 */
proto.home.Request.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 parent_master_category_id = 15;
 * @return {number}
 */
proto.home.Request.prototype.getParentMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Request} returns this
 */
proto.home.Request.prototype.setParentMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 child_master_category_id = 16;
 * @return {number}
 */
proto.home.Request.prototype.getChildMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.Request} returns this
 */
proto.home.Request.prototype.setChildMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.home.CategoryRanking.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.CategoryRanking.prototype.toObject = function(opt_includeInstance) {
  return proto.home.CategoryRanking.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.CategoryRanking} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.CategoryRanking.toObject = function(includeInstance, msg) {
  var f, obj = {
    rankingTermStart: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rankingTermEnd: jspb.Message.getFieldWithDefault(msg, 2, 0),
    detailsList: jspb.Message.toObjectList(msg.getDetailsList(),
    proto.home.CategoryRankingDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.CategoryRanking}
 */
proto.home.CategoryRanking.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.CategoryRanking;
  return proto.home.CategoryRanking.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.CategoryRanking} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.CategoryRanking}
 */
proto.home.CategoryRanking.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRankingTermStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRankingTermEnd(value);
      break;
    case 3:
      var value = new proto.home.CategoryRankingDetail;
      reader.readMessage(value,proto.home.CategoryRankingDetail.deserializeBinaryFromReader);
      msg.addDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.CategoryRanking.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.CategoryRanking.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.CategoryRanking} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.CategoryRanking.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRankingTermStart();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRankingTermEnd();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.home.CategoryRankingDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 ranking_term_start = 1;
 * @return {number}
 */
proto.home.CategoryRanking.prototype.getRankingTermStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.CategoryRanking} returns this
 */
proto.home.CategoryRanking.prototype.setRankingTermStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 ranking_term_end = 2;
 * @return {number}
 */
proto.home.CategoryRanking.prototype.getRankingTermEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.CategoryRanking} returns this
 */
proto.home.CategoryRanking.prototype.setRankingTermEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated CategoryRankingDetail details = 3;
 * @return {!Array<!proto.home.CategoryRankingDetail>}
 */
proto.home.CategoryRanking.prototype.getDetailsList = function() {
  return /** @type{!Array<!proto.home.CategoryRankingDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.CategoryRankingDetail, 3));
};


/**
 * @param {!Array<!proto.home.CategoryRankingDetail>} value
 * @return {!proto.home.CategoryRanking} returns this
*/
proto.home.CategoryRanking.prototype.setDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.home.CategoryRankingDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.CategoryRankingDetail}
 */
proto.home.CategoryRanking.prototype.addDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.home.CategoryRankingDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.CategoryRanking} returns this
 */
proto.home.CategoryRanking.prototype.clearDetailsList = function() {
  return this.setDetailsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.home.CategoryRankingDetail.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.CategoryRankingDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.home.CategoryRankingDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.CategoryRankingDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.CategoryRankingDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceClass: jspb.Message.getFieldWithDefault(msg, 2, 0),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.home.Service.toObject, includeInstance),
    masterCategoryId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    masterCategoryTitle: jspb.Message.getFieldWithDefault(msg, 7, ""),
    masterCategoryRomaji: jspb.Message.getFieldWithDefault(msg, 8, ""),
    defaultSort: jspb.Message.getFieldWithDefault(msg, 9, 0),
    isBusiness: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.CategoryRankingDetail}
 */
proto.home.CategoryRankingDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.CategoryRankingDetail;
  return proto.home.CategoryRankingDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.CategoryRankingDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.CategoryRankingDetail}
 */
proto.home.CategoryRankingDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setServiceClass(value);
      break;
    case 5:
      var value = new proto.home.Service;
      reader.readMessage(value,proto.home.Service.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMasterCategoryId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterCategoryTitle(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterCategoryRomaji(value);
      break;
    case 9:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.CategoryRankingDefaultSort} */ (reader.readEnum());
      msg.setDefaultSort(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBusiness(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.CategoryRankingDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.CategoryRankingDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.CategoryRankingDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.CategoryRankingDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceClass();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.home.Service.serializeBinaryToWriter
    );
  }
  f = message.getMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMasterCategoryTitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMasterCategoryRomaji();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDefaultSort();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getIsBusiness();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional int32 service_class = 2;
 * @return {number}
 */
proto.home.CategoryRankingDetail.prototype.getServiceClass = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.CategoryRankingDetail} returns this
 */
proto.home.CategoryRankingDetail.prototype.setServiceClass = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Service services = 5;
 * @return {!Array<!proto.home.Service>}
 */
proto.home.CategoryRankingDetail.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.home.Service>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.home.Service, 5));
};


/**
 * @param {!Array<!proto.home.Service>} value
 * @return {!proto.home.CategoryRankingDetail} returns this
*/
proto.home.CategoryRankingDetail.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.home.Service=} opt_value
 * @param {number=} opt_index
 * @return {!proto.home.Service}
 */
proto.home.CategoryRankingDetail.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.home.Service, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.home.CategoryRankingDetail} returns this
 */
proto.home.CategoryRankingDetail.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};


/**
 * optional int32 master_category_id = 6;
 * @return {number}
 */
proto.home.CategoryRankingDetail.prototype.getMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.CategoryRankingDetail} returns this
 */
proto.home.CategoryRankingDetail.prototype.setMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string master_category_title = 7;
 * @return {string}
 */
proto.home.CategoryRankingDetail.prototype.getMasterCategoryTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.CategoryRankingDetail} returns this
 */
proto.home.CategoryRankingDetail.prototype.setMasterCategoryTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string master_category_romaji = 8;
 * @return {string}
 */
proto.home.CategoryRankingDetail.prototype.getMasterCategoryRomaji = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.CategoryRankingDetail} returns this
 */
proto.home.CategoryRankingDetail.prototype.setMasterCategoryRomaji = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional coconala.protobuf.domain.enumeration.CategoryRankingDefaultSort default_sort = 9;
 * @return {!proto.coconala.protobuf.domain.enumeration.CategoryRankingDefaultSort}
 */
proto.home.CategoryRankingDetail.prototype.getDefaultSort = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.CategoryRankingDefaultSort} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.CategoryRankingDefaultSort} value
 * @return {!proto.home.CategoryRankingDetail} returns this
 */
proto.home.CategoryRankingDetail.prototype.setDefaultSort = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional bool is_business = 10;
 * @return {boolean}
 */
proto.home.CategoryRankingDetail.prototype.getIsBusiness = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.home.CategoryRankingDetail} returns this
 */
proto.home.CategoryRankingDetail.prototype.setIsBusiness = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.News.prototype.toObject = function(opt_includeInstance) {
  return proto.home.News.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.News} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.News.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    date: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.News}
 */
proto.home.News.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.News;
  return proto.home.News.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.News} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.News}
 */
proto.home.News.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.News.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.News.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.News} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.News.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.home.News.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.News} returns this
 */
proto.home.News.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.home.News.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.News} returns this
 */
proto.home.News.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 date = 3;
 * @return {number}
 */
proto.home.News.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.News} returns this
 */
proto.home.News.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.LegalQa.prototype.toObject = function(opt_includeInstance) {
  return proto.home.LegalQa.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.LegalQa} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.LegalQa.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    categoryName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    categoryThumbnailPath: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.LegalQa}
 */
proto.home.LegalQa.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.LegalQa;
  return proto.home.LegalQa.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.LegalQa} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.LegalQa}
 */
proto.home.LegalQa.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryThumbnailPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.LegalQa.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.LegalQa.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.LegalQa} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.LegalQa.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCategoryName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCategoryThumbnailPath();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.home.LegalQa.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.LegalQa} returns this
 */
proto.home.LegalQa.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.home.LegalQa.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.LegalQa} returns this
 */
proto.home.LegalQa.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 category_id = 3;
 * @return {number}
 */
proto.home.LegalQa.prototype.getCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.LegalQa} returns this
 */
proto.home.LegalQa.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string category_name = 4;
 * @return {string}
 */
proto.home.LegalQa.prototype.getCategoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.LegalQa} returns this
 */
proto.home.LegalQa.prototype.setCategoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string category_thumbnail_path = 5;
 * @return {string}
 */
proto.home.LegalQa.prototype.getCategoryThumbnailPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.LegalQa} returns this
 */
proto.home.LegalQa.prototype.setCategoryThumbnailPath = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.RecommendBlog.prototype.toObject = function(opt_includeInstance) {
  return proto.home.RecommendBlog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.RecommendBlog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.RecommendBlog.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    blogCategoryId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    blogCategoryName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    resizableCoverImagePath: jspb.Message.getFieldWithDefault(msg, 5, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 6, 0),
    user: (f = msg.getUser()) && proto.home.RecommendBlogUser.toObject(includeInstance, f),
    price: jspb.Message.getFieldWithDefault(msg, 8, 0),
    paidFg: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    openedAt: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.RecommendBlog}
 */
proto.home.RecommendBlog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.RecommendBlog;
  return proto.home.RecommendBlog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.RecommendBlog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.RecommendBlog}
 */
proto.home.RecommendBlog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBlogCategoryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlogCategoryName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setResizableCoverImagePath(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKind(value);
      break;
    case 7:
      var value = new proto.home.RecommendBlogUser;
      reader.readMessage(value,proto.home.RecommendBlogUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaidFg(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpenedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.RecommendBlog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.RecommendBlog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.RecommendBlog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.RecommendBlog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBlogCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBlogCategoryName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getResizableCoverImagePath();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getKind();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.home.RecommendBlogUser.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPaidFg();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getOpenedAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.home.RecommendBlog.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.RecommendBlog} returns this
 */
proto.home.RecommendBlog.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 blog_category_id = 2;
 * @return {number}
 */
proto.home.RecommendBlog.prototype.getBlogCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.RecommendBlog} returns this
 */
proto.home.RecommendBlog.prototype.setBlogCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string blog_category_name = 3;
 * @return {string}
 */
proto.home.RecommendBlog.prototype.getBlogCategoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.RecommendBlog} returns this
 */
proto.home.RecommendBlog.prototype.setBlogCategoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.home.RecommendBlog.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.RecommendBlog} returns this
 */
proto.home.RecommendBlog.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string resizable_cover_image_path = 5;
 * @return {string}
 */
proto.home.RecommendBlog.prototype.getResizableCoverImagePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.RecommendBlog} returns this
 */
proto.home.RecommendBlog.prototype.setResizableCoverImagePath = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 kind = 6;
 * @return {number}
 */
proto.home.RecommendBlog.prototype.getKind = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.RecommendBlog} returns this
 */
proto.home.RecommendBlog.prototype.setKind = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional RecommendBlogUser user = 7;
 * @return {?proto.home.RecommendBlogUser}
 */
proto.home.RecommendBlog.prototype.getUser = function() {
  return /** @type{?proto.home.RecommendBlogUser} */ (
    jspb.Message.getWrapperField(this, proto.home.RecommendBlogUser, 7));
};


/**
 * @param {?proto.home.RecommendBlogUser|undefined} value
 * @return {!proto.home.RecommendBlog} returns this
*/
proto.home.RecommendBlog.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.RecommendBlog} returns this
 */
proto.home.RecommendBlog.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.RecommendBlog.prototype.hasUser = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 price = 8;
 * @return {number}
 */
proto.home.RecommendBlog.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.RecommendBlog} returns this
 */
proto.home.RecommendBlog.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool paid_fg = 9;
 * @return {boolean}
 */
proto.home.RecommendBlog.prototype.getPaidFg = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.home.RecommendBlog} returns this
 */
proto.home.RecommendBlog.prototype.setPaidFg = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int64 opened_at = 10;
 * @return {number}
 */
proto.home.RecommendBlog.prototype.getOpenedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.RecommendBlog} returns this
 */
proto.home.RecommendBlog.prototype.setOpenedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.RecommendBlogUser.prototype.toObject = function(opt_includeInstance) {
  return proto.home.RecommendBlogUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.RecommendBlogUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.RecommendBlogUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profileIconPath: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.RecommendBlogUser}
 */
proto.home.RecommendBlogUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.RecommendBlogUser;
  return proto.home.RecommendBlogUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.RecommendBlogUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.RecommendBlogUser}
 */
proto.home.RecommendBlogUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileIconPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.RecommendBlogUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.RecommendBlogUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.RecommendBlogUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.RecommendBlogUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfileIconPath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.home.RecommendBlogUser.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.RecommendBlogUser} returns this
 */
proto.home.RecommendBlogUser.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.home.RecommendBlogUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.RecommendBlogUser} returns this
 */
proto.home.RecommendBlogUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_icon_path = 3;
 * @return {string}
 */
proto.home.RecommendBlogUser.prototype.getProfileIconPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.RecommendBlogUser} returns this
 */
proto.home.RecommendBlogUser.prototype.setProfileIconPath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.RecommendCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.home.RecommendCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.RecommendCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.RecommendCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serviceKind: jspb.Message.getFieldWithDefault(msg, 3, 0),
    moreUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isBusiness: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.RecommendCategory}
 */
proto.home.RecommendCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.RecommendCategory;
  return proto.home.RecommendCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.RecommendCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.RecommendCategory}
 */
proto.home.RecommendCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setServiceKind(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMoreUrl(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBusiness(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.RecommendCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.RecommendCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.RecommendCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.RecommendCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getServiceKind();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMoreUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsBusiness();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.home.RecommendCategory.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.RecommendCategory} returns this
 */
proto.home.RecommendCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.home.RecommendCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.RecommendCategory} returns this
 */
proto.home.RecommendCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 service_kind = 3;
 * @return {number}
 */
proto.home.RecommendCategory.prototype.getServiceKind = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.home.RecommendCategory} returns this
 */
proto.home.RecommendCategory.prototype.setServiceKind = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string more_url = 4;
 * @return {string}
 */
proto.home.RecommendCategory.prototype.getMoreUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.home.RecommendCategory} returns this
 */
proto.home.RecommendCategory.prototype.setMoreUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_business = 5;
 * @return {boolean}
 */
proto.home.RecommendCategory.prototype.getIsBusiness = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.home.RecommendCategory} returns this
 */
proto.home.RecommendCategory.prototype.setIsBusiness = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.home.RecommendRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.home.RecommendRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.home.RecommendRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.RecommendRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    title: (f = msg.getTitle()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    priceMin: (f = msg.getPriceMin()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    priceMax: (f = msg.getPriceMax()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    budgetConsultation: (f = msg.getBudgetConsultation()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    proposalCount: (f = msg.getProposalCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    userName: (f = msg.getUserName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    resizableProfileIcon: (f = msg.getResizableProfileIcon()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    parentMasterCategoryId: (f = msg.getParentMasterCategoryId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    childMasterCategoryId: (f = msg.getChildMasterCategoryId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expireDate: (f = msg.getExpireDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.home.RecommendRequest}
 */
proto.home.RecommendRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.home.RecommendRequest;
  return proto.home.RecommendRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.home.RecommendRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.home.RecommendRequest}
 */
proto.home.RecommendRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPriceMin(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPriceMax(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setBudgetConsultation(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setProposalCount(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUserName(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setResizableProfileIcon(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setParentMasterCategoryId(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setChildMasterCategoryId(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpireDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.home.RecommendRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.home.RecommendRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.home.RecommendRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.home.RecommendRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPriceMin();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPriceMax();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getBudgetConsultation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getProposalCount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUserName();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getResizableProfileIcon();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getParentMasterCategoryId();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getChildMasterCategoryId();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpireDate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.home.RecommendRequest.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.home.RecommendRequest} returns this
*/
proto.home.RecommendRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.RecommendRequest} returns this
 */
proto.home.RecommendRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.RecommendRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue title = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.home.RecommendRequest.prototype.getTitle = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.home.RecommendRequest} returns this
*/
proto.home.RecommendRequest.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.RecommendRequest} returns this
 */
proto.home.RecommendRequest.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.RecommendRequest.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value price_min = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.home.RecommendRequest.prototype.getPriceMin = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.home.RecommendRequest} returns this
*/
proto.home.RecommendRequest.prototype.setPriceMin = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.RecommendRequest} returns this
 */
proto.home.RecommendRequest.prototype.clearPriceMin = function() {
  return this.setPriceMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.RecommendRequest.prototype.hasPriceMin = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value price_max = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.home.RecommendRequest.prototype.getPriceMax = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.home.RecommendRequest} returns this
*/
proto.home.RecommendRequest.prototype.setPriceMax = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.RecommendRequest} returns this
 */
proto.home.RecommendRequest.prototype.clearPriceMax = function() {
  return this.setPriceMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.RecommendRequest.prototype.hasPriceMax = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue budget_consultation = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.home.RecommendRequest.prototype.getBudgetConsultation = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.home.RecommendRequest} returns this
*/
proto.home.RecommendRequest.prototype.setBudgetConsultation = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.RecommendRequest} returns this
 */
proto.home.RecommendRequest.prototype.clearBudgetConsultation = function() {
  return this.setBudgetConsultation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.RecommendRequest.prototype.hasBudgetConsultation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value proposal_count = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.home.RecommendRequest.prototype.getProposalCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.home.RecommendRequest} returns this
*/
proto.home.RecommendRequest.prototype.setProposalCount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.RecommendRequest} returns this
 */
proto.home.RecommendRequest.prototype.clearProposalCount = function() {
  return this.setProposalCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.RecommendRequest.prototype.hasProposalCount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue user_name = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.home.RecommendRequest.prototype.getUserName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.home.RecommendRequest} returns this
*/
proto.home.RecommendRequest.prototype.setUserName = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.RecommendRequest} returns this
 */
proto.home.RecommendRequest.prototype.clearUserName = function() {
  return this.setUserName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.RecommendRequest.prototype.hasUserName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue resizable_profile_icon = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.home.RecommendRequest.prototype.getResizableProfileIcon = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.home.RecommendRequest} returns this
*/
proto.home.RecommendRequest.prototype.setResizableProfileIcon = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.RecommendRequest} returns this
 */
proto.home.RecommendRequest.prototype.clearResizableProfileIcon = function() {
  return this.setResizableProfileIcon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.RecommendRequest.prototype.hasResizableProfileIcon = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int32Value parent_master_category_id = 9;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.home.RecommendRequest.prototype.getParentMasterCategoryId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.home.RecommendRequest} returns this
*/
proto.home.RecommendRequest.prototype.setParentMasterCategoryId = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.RecommendRequest} returns this
 */
proto.home.RecommendRequest.prototype.clearParentMasterCategoryId = function() {
  return this.setParentMasterCategoryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.RecommendRequest.prototype.hasParentMasterCategoryId = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Int32Value child_master_category_id = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.home.RecommendRequest.prototype.getChildMasterCategoryId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.home.RecommendRequest} returns this
*/
proto.home.RecommendRequest.prototype.setChildMasterCategoryId = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.RecommendRequest} returns this
 */
proto.home.RecommendRequest.prototype.clearChildMasterCategoryId = function() {
  return this.setChildMasterCategoryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.RecommendRequest.prototype.hasChildMasterCategoryId = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.home.RecommendRequest.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.home.RecommendRequest} returns this
*/
proto.home.RecommendRequest.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.RecommendRequest} returns this
 */
proto.home.RecommendRequest.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.RecommendRequest.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp expire_date = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.home.RecommendRequest.prototype.getExpireDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.home.RecommendRequest} returns this
*/
proto.home.RecommendRequest.prototype.setExpireDate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.home.RecommendRequest} returns this
 */
proto.home.RecommendRequest.prototype.clearExpireDate = function() {
  return this.setExpireDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.home.RecommendRequest.prototype.hasExpireDate = function() {
  return jspb.Message.getField(this, 12) != null;
};


goog.object.extend(exports, proto.home);
