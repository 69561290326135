import { onMounted, onUnmounted, useContext } from '@nuxtjs/composition-api'

type ImpressionParamsType = { action: string; detail?: object }

export const useImpressionLog = () => {
  const { store, $sentry } = useContext()
  const addImpressionLog = ({ action, detail }: { action: string; detail?: object }) => {
    try {
      const view_id = store.state.big_query.viewId
      const params = {
        action,
        detailJson: JSON.stringify({ view_id, ...detail })
      }
      return store.dispatch('big_query/log_impression/addLogImpression', params)
    } catch (e) {
      $sentry.captureException(e)
    }
  }

  const addCloseImpressionLog = ({ action, detail }: ImpressionParamsType) => {
    const endpoint = '/api/web/logs/impression'
    try {
      const view_id = store.state.big_query.viewId
      const userId = store.state.auth.user.id || null
      const body = JSON.stringify({
        action,
        details: JSON.stringify({ view_id, ...detail }),
        userId
      })
      if (navigator.sendBeacon) {
        navigator.sendBeacon(endpoint, body)
      } else {
        fetch(endpoint, {
          body,
          method: 'POST',
          keepalive: true
        })
      }
    } catch (e) {
      $sentry.captureException(e)
    }
  }

  return { addImpressionLog, addCloseImpressionLog }
}

/**
 * ページクローズ（or リロード）時にimpressionログを登録するhooks
 * @param getParams
 *   impressionログを登録する値を関数で定義
 *   ページクローズ時の値を渡すために、関数を使用
 */
export const useAddCloseImpressionLog = (getParams: () => ImpressionParamsType) => {
  const { addCloseImpressionLog } = useImpressionLog()
  const callAddCloseImpressionLog = () => addCloseImpressionLog(getParams())

  onMounted(() => {
    window.addEventListener('unload', callAddCloseImpressionLog)
  })

  onUnmounted(() => {
    window.removeEventListener('unload', callAddCloseImpressionLog)
  })
}
