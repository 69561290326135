<template>
  <div
    class="c-beginnerBanners"
    :class="rootClass()"
  >
    <a
      class="c-beginnerBanner"
      :href="aboutPath"
    >
      <img
        class="c-beginnerBanner_image isPC"
        src="~/assets/images/components/BeginnerBanners/banner-beginner.png"
        alt="はじめての方へ"
        loading="lazy"
        decoding="async"
      />
      <img
        class="c-beginnerBanner_image isSP"
        src="~/assets/images/components/BeginnerBanners/banner-beginner-sp.png"
        alt="はじめての方へ"
        loading="lazy"
        decoding="async"
      />
    </a>

    <div
      v-if="!isLoggedIn"
      class="c-beginnerBanner"
    >
      <a :href="registrationURL">
        <picture>
          <source
            srcset="~/assets/images/components/BeginnerBanners/sp_board_202302_cp_f.png"
            media="(max-width: 959px)"
          />
          <img
            class="c-beginnerBanner_image"
            src="~/assets/images/components/BeginnerBanners/pc_board_202302_cp_f.png"
            alt="会員登録すると300円クーポンプレゼント"
            loading="lazy"
            decoding="async"
          />
        </picture>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

/** @type {import('vue').ComponentOptions} */
const beginnerBanners = {
  name: 'BeginnerBanners',
  props: {
    targetDevice: {
      type: String,
      default: 'ALL',
      validator(v) {
        return ['ALL', 'PC', 'SP'].includes(v)
      }
    },
    categoryId: {
      type: Number,
      default: null
    },
    pageType: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('pages/search/search', ['searchCondition']),
    aboutPath() {
      let path = '/pages/about?ref=category_about'
      if (this.categoryId) {
        path += `&master_category_id=${this.categoryId}`
      }
      if (this.searchCondition.serviceKind) {
        path += `&service_kind=${this.searchCondition.serviceKind}`
      }
      return path
    },
    registrationURL() {
      if (this.pageType === 'home') {
        return this.$coconala.registrationURL(this.$route.fullPath, { ref: 'top_register' })
      }
      if (this.pageType !== 'serviceSearch') {
        return this.$coconala.registrationURL()
      }

      // サービス検索・カテゴリページの場合、パラメータ追加
      const params = { ref: 'category_register' }
      if (this.categoryId) {
        params.master_category_id = this.categoryId
      }
      if (this.searchCondition.serviceKind) {
        params.service_kind = this.searchCondition.serviceKind
      }
      return this.$coconala.registrationURL(this.$route.fullPath, params)
    }
  },
  methods: {
    rootClass() {
      return `c-beginnerBanners-${this.targetDevice.toLowerCase()}`
    }
  }
}
export default beginnerBanners
</script>

<style lang="scss" scoped>
$banner_image_width: 472px;
$banner_image_height: 104px;

.isPC {
  display: block;
}
.isSP {
  display: none;
}

.c-beginnerBanners {
  display: flex;
  margin-top: 20px;
  &-sp {
    display: none;
  }
}

.c-beginnerBanner {
  @include link-effect;

  position: relative;
  width: $banner_image_width;
  height: $banner_image_height;

  &:nth-child(n + 2) {
    margin-left: 20px;
  }
  &::before {
    display: block;
    // PC用バナーの高さ: バナー横幅 * 画像のアスペクト比
    padding-top: calc(100% * 13 / 59);
    content: '';
  }
  &_image {
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (max-width: breakpoint(Home, M)) {
  .isPC {
    display: none;
  }
  .isSP {
    display: block;
  }

  .c-beginnerBanners {
    margin-top: 12px;

    justify-content: center;
    &-pc {
      display: none;
    }
    &-sp {
      display: flex;
    }
  }
  .c-beginnerBanner {
    width: 100%;
    height: 100%;

    &::before {
      // SP用バナーの高さ: バナー横幅 * 画像のアスペクト比
      padding-top: calc(100% * 19 / 71);
    }
    &:nth-child(n + 2) {
      margin-top: 0;
      margin-left: 20px;
    }
  }
}

@media (max-width: $breakpoint-s) {
  .c-beginnerBanners {
    flex-direction: column;

    align-items: center;
  }
  .c-beginnerBanner {
    &:nth-child(n + 2) {
      margin-top: 12px;
      margin-left: 0;
    }
  }
}
</style>
