<template>
  <NuxtLink
    :to="addQueryToUrlIfBusinessPreview($coconala.rootPathUrl(`/services/${service.id}`, linkRefs))"
    class="c-serviceCassette"
  >
    <div
      class="c-serviceCassette_rank"
      :class="`c-serviceCassette_rank-rank${itemNumber}`"
      v-if="isRanking"
    >
      {{ itemNumber }}
    </div>

    <div class="c-serviceCassette_flag">
      <img
        src="@/assets/images/common/icon-video-service.svg"
        alt=""
        loading="lazy"
        decoding="async"
        v-if="service.videoServiceFlag"
      />
      <img
        src="@/assets/images/common/icon-phone-service.svg"
        alt=""
        loading="lazy"
        decoding="async"
        v-if="isPhoneService"
      />
    </div>

    <div class="c-serviceCassette_image">
      <div
        class="c-serviceCassette_imageInner"
        v-if="!isSkeleton"
      >
        <img
          :src="$util.getYoutubeThumbnailURL(service.serviceMovie, 'hq')"
          :alt="service.name"
          loading="lazy"
          decoding="async"
          class="c-serviceCassette_imageMovie"
          v-if="service.moviePriorityFlag && service.serviceMovie"
        />
        <img
          :src="$coconala.serviceImageURL(service.serviceImage, 230, 190)"
          :alt="service.name"
          loading="lazy"
          decoding="async"
          class="c-serviceCassette_imageStatic"
          v-else-if="service.serviceImage"
        />
        <img
          :src="$util.getYoutubeThumbnailURL(service.serviceMovie, 'hq')"
          :alt="service.name"
          loading="lazy"
          decoding="async"
          class="c-serviceCassette_imageMovie"
          v-else-if="service.serviceMovie"
        />
        <img
          src="@/assets/images/common/no_image_item.png"
          :alt="service.name"
          loading="lazy"
          decoding="async"
          class="c-serviceCassette_imageStatic"
          v-else
        />
      </div>
    </div>
    <div class="c-serviceCassette_info">
      <div class="c-serviceCassette_infoTop">
        <div class="c-serviceCassette_title">
          {{ service.name }}
        </div>
        <div
          v-if="displayRating && service.ratings"
          class="c-serviceCassette_rating"
        >
          <CoconalaIcon
            class="c-serviceCassette_star"
            name="star"
            size="10px"
          />
          <div class="c-serviceCassette_ratingScore">
            {{ service.ratings.indicator ? service.ratings.indicator.toFixed(1) : '-' }}
          </div>
          <div
            class="c-serviceCassette_performanceCount"
            v-if="service.performanceCount > 0"
          >
            ({{ service.performanceCount }})
          </div>
        </div>
      </div>
      <div class="c-serviceCassette_infoRow">
        <CUserIconV2
          class="c-serviceCassette_userIcon"
          :file-path="service.provider.profileIconPath"
          :size="12"
          :width="24"
          :height="24"
          v-if="!isSkeleton"
        />
        <div
          class="c-serviceCassette_userName"
          data-ignore-translate
        >
          {{ service.provider.name }}
        </div>
        <!-- prettier-ignore -->
        <div class="c-serviceCassette_price">
          <div v-if="isPhoneService">
            <strong>{{ $util.numberWithDelimiter($translate.exchangePrice(service.priceWot)) }}</strong>
            {{ $translate.exchangePriceUnit() }}
            <span class="c-searchItemContentPrice_unit">/{{ $t('minute') }}</span>
          </div>
          <div v-else-if="service.unitTime">
            <strong>{{ $util.numberWithDelimiter($translate.exchangePrice(service.priceWot)) }}</strong>
            {{ $translate.exchangePriceUnit() }}
            <span class="c-searchItemContentPrice_unit">/{{ $util.numberWithDelimiter(service.unitTime) }}{{ $t('minute') }}</span>
          </div>
          <div v-else>
            <strong>{{ $util.numberWithDelimiter($translate.exchangePrice(service.priceWot)) }}</strong>
            {{ $translate.exchangePriceUnit() }}
          </div>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>
<script>
import LinkRefInjectee from '~/mixins/link-ref/LinkRefInjectee'
import businessMixin from '~/mixins/pages/business-mixin'

const SERVICE_CLASS_PHONE = 1

export default {
  name: 'ServiceCassette',
  mixins: [LinkRefInjectee, businessMixin],
  props: {
    service: {
      type: Object,
      required: true
    },
    itemNumber: {
      type: Number,
      default: null
    },
    isRanking: {
      type: Boolean,
      default: false
    },
    isSkeleton: {
      type: Boolean,
      default: false
    },
    displayRating: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isPhoneService() {
      return this.service.serviceClass === SERVICE_CLASS_PHONE
    }
  }
}
</script>
<style lang="scss" scoped>
@use 'sass:math';

// 画像アスペクト比
$image-aspect-ratio: math.div(106, 128);

.c-serviceCassette {
  @include link-effect;

  position: relative;
  display: block;
  overflow: hidden;
  border: 1px solid $ds2-color-gray-50;
  background-color: $color-white;

  &_rank {
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $ds2-color-gray-50;
    color: $ds2-color-gray-600;
    text-align: center;
    font-size: 11px;
    line-height: 20px;

    &-rank1 {
      background: $color-gold-300;
      color: $color-white;
    }

    &-rank2 {
      background: $ds2-color-gray-400;
      color: $color-white;
    }

    &-rank3 {
      background: $color-orange-900;
      color: $color-white;
    }
  }

  &_flag {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 36px;
    height: 36px;

    img {
      width: 36px;
      height: 36px;
    }
  }

  &_image {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    background: $ds2-color-gray-25;

    &::after {
      display: block;
      padding-top: 100% * $image-aspect-ratio;
      width: 100%;
      content: '';
    }
  }
  &_imageInner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &_info {
    padding: 8px 4px;
  }
  &_infoRow {
    display: flex;
    margin-top: 8px;

    align-items: center;
    justify-content: space-between;
  }
  &_infoTop {
    min-height: 44px;
  }

  &_title {
    @include line-clamp(2, 1.3em);

    margin-bottom: 4px;
    color: $ds2-color-gray-900;
    font-size: 11px;
  }

  &_rating {
    display: flex;
    margin-top: 4px;
    color: $ds2-color-star;
    font-size: 10px;

    align-items: center;
  }
  &_ratingScore {
    margin-left: 2px;
    line-height: 1;
  }
  &_performanceCount {
    margin-left: 4px;
    color: $ds2-color-gray-900;
    line-height: 1;
  }

  &_userIcon {
    flex: 0 0 12px;
    overflow: hidden;
    margin-right: 4px;
    width: 12px;
    height: 12px;
  }

  &_userName {
    flex: 1 1 auto;
    overflow: hidden;
    margin-right: 4px;
    min-width: 4px;
    color: $ds2-color-gray-600;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10px;
    line-height: 1.2;
  }

  &_price {
    flex: 0 0 auto;
    color: $ds2-color-gray-900;
    text-align: right;
    font-size: 10px;
    line-height: 1;

    strong {
      padding-right: 2px;
      font-weight: normal;
      font-size: 12px;
    }
  }
}
</style>
