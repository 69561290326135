
import { computed, defineComponent, PropType, toRefs, watch } from '@nuxtjs/composition-api'
import { Stripped } from '~/grpc/grpc-util'
import DField from '~/components/molecules/DS2/DField'
import DSelect from '~/components/atoms/DS2/DSelect'
import { EntryFormOrderFormat } from '~/stub/domain/enumeration/entry_form_order_format_pb'
import { SaveCorporationInformationBuyerRequest } from '~/stub/gw/attribute_info/attribute_info_service_pb'
import { BUDGET_LIST, PLANNED_TIME_LIST } from '~/constants/budget-list'

export default defineComponent({
  name: 'DRegisterOrderBudget',
  components: {
    DField,
    DSelect
  },
  props: {
    formInfo: {
      type: Object as PropType<Stripped<SaveCorporationInformationBuyerRequest.AsObject>>,
      required: true
    },
    errorMessages: {
      type: Object,
      required: true,
      validator: (v: Object) => {
        const properties = ['budgetType', 'plannedTime']
        return properties.every(p => !!v[p])
      }
    },
    isSubmitted: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const { formInfo } = toRefs(props)

    const budgetList = computed(() => {
      return BUDGET_LIST[formInfo.value.orderFormat] ?? []
    })

    const innerBudgetType = computed({
      get: () => props.formInfo.budgetType,
      set: budgetType => emit('change', { key: 'budgetType', input: budgetType })
    })
    const innerPlannedTime = computed({
      get: () => props.formInfo.plannedTime,
      set: plannedTime => emit('change', { key: 'plannedTime', input: plannedTime })
    })
    const subcontractingText = computed(() => {
      return props.formInfo.orderFormat ===
        EntryFormOrderFormat.ENTRY_FORM_ORDER_FORMAT_SUBCONTRACTING
        ? '（月あたり）'
        : ''
    })

    watch(
      () => formInfo.value.orderFormat,
      async (newValue, oldValue) => {
        if (newValue !== oldValue) {
          innerBudgetType.value = undefined
          innerPlannedTime.value = undefined
        }
      }
    )

    return {
      innerBudgetType,
      innerPlannedTime,
      subcontractingText,
      budgetList,
      PLANNED_TIME_LIST
    }
  }
})
