<template>
  <HomeCarousel
    class="c-carouselRequestList"
    :slides-count="slidesCount"
  >
    <CarouselRequestItem
      v-for="(request, index) in requests"
      :key="`request${index}`"
      class="c-carouselRequestList_item"
      :request="request"
      :current-date="currentDate"
      :item-number="index + 1"
    />
  </HomeCarousel>
</template>

<script>
import CarouselRequestItem from './CarouselRequestItem'
import HomeCarousel from './HomeCarousel'

const ITEMS_PER_SLIDE = 3

/** @type {import('vue').ComponentOptions} */
const carouselRequestList = {
  name: 'CarouselRequestList',
  components: {
    CarouselRequestItem,
    HomeCarousel
  },
  props: {
    requests: {
      type: Array,
      default: () => []
    },
    currentDate: {
      type: String,
      default: ''
    }
  },
  computed: {
    slidesCount() {
      return Math.ceil(this.requests.length / ITEMS_PER_SLIDE)
    }
  }
}
export default carouselRequestList
</script>

<style lang="scss" scoped>
.c-carouselRequestList {
  width: 960px;
  height: 221px;

  &_item {
    width: 309px;
    margin-right: 11px;
    flex: 0 0 auto;
  }
}
@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-carouselRequestList {
    width: 100%;
  }
}
</style>
