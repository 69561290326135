
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'

const ButtonTypes = [
  'default',
  'text',
  'outlined',
  'business',
  'businessOutlined',
  'primary'
] as const
type ButtonType = (typeof ButtonTypes)[number]

const ezTopButton = defineComponent({
  name: 'EzTopButton',
  props: {
    type: {
      type: String as PropType<ButtonType>,
      default: 'default'
    },
    href: {
      type: String,
      default: ''
    },
    targetBlank: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: 'auto'
    },
    spWidth: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: 'auto'
    },
    spHeight: {
      type: String,
      default: ''
    },
    borderRadius: {
      type: String,
      default: '8px'
    },
    spBorderRadius: {
      type: String,
      default: ''
    },
    padding: {
      type: String,
      default: '0 16px'
    },
    spPadding: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const styles = computed(() => {
      const { width, spWidth, height, spHeight, borderRadius, spBorderRadius, padding, spPadding } =
        props
      return {
        '--etb-width': width,
        '--etb-sp-width': spWidth || width,
        '--etb-height': height,
        '--etb-sp-height': spHeight || height,
        '--etb-border-radius': borderRadius,
        '--etb-sp-border-radius': spBorderRadius || borderRadius,
        '--etb-padding': padding,
        '--etb-sp-padding': spPadding || padding
      }
    })
    const classes = computed<string[]>(() => {
      const classes: string[] = []
      if (props.type !== 'default') {
        classes.push(`d-ezTopLinkButton-${props.type}`)
      }
      return classes
    })
    return { styles, classes }
  }
})
export default ezTopButton
