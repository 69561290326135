<template>
  <div class="c-homeCarousel">
    <div
      class="c-homeCarousel_content"
      ref="content"
    >
      <div
        class="c-homeCarousel_inner"
        :style="{
          transform: `translateX(${-currentSlide * slideWidth}px)`,
          'transition-duration': isDisabledAnimation ? '0s' : null
        }"
        ref="inner"
      >
        <div
          class="c-homeCarousel_slides"
          :style="{ width: slideWidth > 0 ? `${slideWidth * slidesCount}px` : null }"
        >
          <slot />
        </div>
        <!-- カルーセル実装のためのダミー部分。slotの複製を持つ。-->
        <div
          v-if="isSlidable && ($translate.isTranslatableTarget() || showDummy)"
          v-show="showDummy"
          class="c-homeCarousel_slides c-homeCarousel_slides-dummy"
        >
          <slot />
        </div>
      </div>
    </div>
    <div
      v-if="isSlidable"
      class="c-homeCarousel_nextButton"
      @click="slideToNext"
    >
      <CoconalaIcon
        class="c-homeCarousel_nextButtonIcon"
        name="chevron-right"
        size="12px"
        color="gray800"
      />
    </div>
    <div
      v-if="isBackable"
      class="c-homeCarousel_backButton"
      @click="slideToBack"
    >
      <CoconalaIcon
        class="c-homeCarousel_backButtonIcon"
        name="chevron-left"
        size="12px"
        color="gray800"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const wait = ms => {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

/** @type {import('vue').ComponentOptions} */
const homeCarousel = {
  name: 'HomeCarousel',
  props: {
    slidesCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      slideWidth: 0,
      currentSlide: 0,
      isDisabledAnimation: false,
      showDummy: false,
      isBackable: false
    }
  },
  computed: {
    ...mapGetters('ui', ['isPC']),
    isSlidable() {
      return this.slidesCount > 1
    }
  },
  watch: {
    // レスポンシブ対応
    isPC(newValue, oldValue) {
      // PC以外 -> PCのときにスクロール位置を戻す
      if (newValue && !oldValue) {
        this.$refs.content.scrollLeft = 0
        this.$nextTick(() => {
          this.updateSlideWidth()
        })
      }
    }
  },
  methods: {
    async slideToNext() {
      // スライドボタン初回操作までDummy部分は非表示
      this.showDummy = true

      if (this.currentSlide === this.slidesCount) {
        // アニメーションを無効化
        this.isDisabledAnimation = true
        /**
         * アニメーション無効化がViewに反映されるのを待つ
         * (反映に数フレームかかるのかnextTickだと早すぎる)
         */
        await this.$util.waitUntil(() => {
          return this.$refs.inner.style.transitionDuration === '0s'
        })
        // スライド位置をリセット
        this.currentSlide = 0
        // nextTickだとうまくいかないのでrequestAnimationFrameで1フレーム待つ
        await new Promise(resolve => window.requestAnimationFrame(() => resolve()))
        // アニメーションを有効化してからスライド位置をひとつ進める
        this.isDisabledAnimation = false
      }
      this.currentSlide++
      this.isBackable = true
    },
    async slideToBack() {
      // スライドボタン初回操作までDummy部分は非表示
      this.showDummy = true

      if (this.currentSlide === 0) {
        // アニメーションを無効化
        this.isDisabledAnimation = true
        /**
         * アニメーション無効化がViewに反映されるのを待つ
         * (反映に数フレームかかるのかnextTickだと早すぎる)
         */
        await this.$util.waitUntil(() => {
          return this.$refs.inner.style.transitionDuration === '0s'
        })
        // スライド位置をリセット
        this.currentSlide = this.slidesCount
        // nextTickだとうまくいかないのでrequestAnimationFrameで1フレーム待つ
        await new Promise(resolve => window.requestAnimationFrame(() => resolve()))
        // アニメーションを有効化してからスライド位置をひとつ進める
        this.isDisabledAnimation = false
      }
      this.currentSlide--
    },
    updateSlideWidth() {
      this.slideWidth = this.$el.getBoundingClientRect().width
    }
  },
  mounted() {
    this.updateSlideWidth()
  }
}
export default homeCarousel
</script>

<style lang="scss" scoped>
.c-homeCarousel {
  position: relative;
  user-select: none;

  &_content {
    overflow: hidden;
  }

  &_inner {
    display: flex;
    flex-wrap: nowrap;
    transition: all 0.3s ease-out;
  }

  &_slides {
    display: flex;
    flex-wrap: nowrap;
    flex: 0 0 auto;
  }

  &_nextButton,
  &_backButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 1px solid $ds2-color-gray-100;
    border-radius: 2px;
    background-color: $ds2-color-white;
    cursor: pointer;
  }

  &_nextButton {
    right: 0;
  }

  &_backButton {
    left: -5px;
  }

  &_nextButtonIcon,
  &_backButtonIcon {
    height: 12px;
  }

  &_nextButtonIcon {
    transform: translateX(2px);
  }

  &_backButtonIcon {
    transform: translateX(-2px);
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-homeCarousel {
    &_content {
      overflow-x: scroll;
      overscroll-behavior-x: none;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &_inner {
      margin-left: 12px;
      // SPでのスクロール表示になったときのためにstyle属性を打ち消す
      transform: translateX(0) !important;
      transition-property: none;
      transition-duration: 0;
      &::after {
        content: '';
        display: block;
        padding-right: 5px;
        height: 163px;
      }
    }

    &_slides {
      // SPでのスクロール表示になったときのためにstyle属性を打ち消す
      width: initial !important;
      &-dummy {
        display: none;
      }
    }

    &_nextButton {
      display: none;
    }

    &_backButton {
      display: none;
    }
  }
}
</style>
