<template>
  <HomeCarousel
    class="c-carouselBlogList"
    :slides-count="slidesCount"
  >
    <CarouselBlogItem
      v-for="(blog, index) in blogs"
      :key="`blog${index}`"
      class="c-carouselBlogList_item"
      :blog="blog"
      :item-number="index + 1"
    />
  </HomeCarousel>
</template>

<script>
import CarouselBlogItem from './CarouselBlogItem'
import HomeCarousel from './HomeCarousel'

const ITEMS_PER_SLIDE = 4

/** @type {import('vue').ComponentOptions} */
const carouselBlogList = {
  name: 'CarouselBlogList',
  components: {
    CarouselBlogItem,
    HomeCarousel
  },
  props: {
    blogs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    slidesCount() {
      return Math.ceil(this.blogs.length / ITEMS_PER_SLIDE)
    }
  }
}
export default carouselBlogList
</script>

<style lang="scss" scoped>
.c-carouselBlogList {
  width: 960px;
  height: 140px;

  &_item {
    width: 231px;
    margin-right: 9px;
    flex: 0 0 auto;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-carouselBlogList {
    width: 100%;
  }
}
</style>
