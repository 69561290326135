<template>
  <div
    class="c-homeAsideContent"
    data-translate
  >
    <CategoryList
      class="c-homeAsideContent_categoryList"
      :class="{ 'c-homeAsideContent_categoryList-lastOrder': privateAccountInfo.isMzhc }"
      :opened-category-ids="openedCategoryIds"
      :cn-clicked="isPC ? 'top_left' : 'categories'"
    />
    <SidebarBanners
      v-if="!privateAccountInfo.isMzhc"
      class="c-homeAsideContent_sidebarBanners"
    />
  </div>
</template>

<script>
import CategoryList from '~/components/organisms/CategoryList'
import SidebarBanners from './components/SidebarBanners'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'HomeAsideContent',
  components: {
    CategoryList,
    SidebarBanners
  },
  data() {
    return {
      openedCategoryIds: []
    }
  },
  computed: {
    ...mapGetters('ui', ['isPC']),
    ...mapState('my/header', ['privateAccountInfo'])
  }
}
</script>

<style lang="scss" scoped>
.c-homeAsideContent {
  &_categoryList {
    padding-bottom: 8px;
    border-bottom: 1px solid $ds2-color-gray-100;
    &-lastOrder {
      border-bottom: 0;
    }
  }
  &_sidebarBanners {
    margin-top: 24px;
  }
}
@media (max-width: breakpoint(Home, M)) {
  .c-homeAsideContent {
    &_categoryList {
      padding-bottom: 0;
      border-bottom: 0;
    }
    &_sidebarBanners {
      display: none;
    }
  }
}
</style>
