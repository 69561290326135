/**
 * 離脱アラート
 */
export default {
  data() {
    return {
      disablePreventSecession: false // 離脱でない次ページへの遷移時や、フォーム入力がなければ無効にしたいときなどにtrueにする
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.preventSecession)
  },
  beforeRouteLeave(to, from, next) {
    if (this.disablePreventSecession) {
      return next()
    }
    // NuxtLinkで移動するときはこちらを出す
    const isOk = window.confirm(
      'このページから移動しますか？\n移動した場合、入力情報が保存されない可能性があります。'
    )
    next(isOk)
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.preventSecession)
  },
  methods: {
    preventSecession(e) {
      if (this.disablePreventSecession) {
        return
      }
      e.preventDefault()
      e.returnValue = 'このページから移動しますか？' // 実際はブラウザの固定文言が表示される
    }
  }
}
