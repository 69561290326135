<template>
  <div class="c-carouselBlog">
    <ContentHeadline
      class="c-carouselBlogTitle"
      :title-tag="titleTag"
      :title-icon="titleIcon"
      :title-text="title"
      :right-link="headlineLink"
    />

    <div class="c-carouselBlogContent">
      <CarouselBlogList :blogs="blogs" />
    </div>
  </div>
</template>
<script>
import LinkRefProvider from '../mixins/LinkRefProvider'
import ContentHeadline from '~/components/molecules/ContentHeadline'
import CarouselBlogList from './CarouselBlogList'

export default {
  name: 'CarouselBlog',
  mixins: [LinkRefProvider],
  components: {
    ContentHeadline,
    CarouselBlogList
  },
  props: {
    blogs: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    titleTag: {
      type: String,
      default: 'div'
    },
    titleIcon: {
      type: String,
      default: ''
    },
    titleUrlMore: {
      type: String,
      default: ''
    },
    titleUrlMoreSp: {
      type: String,
      default: ''
    },
    urlMore: {
      type: String,
      default: ''
    },
    isNuxtLinkHeadline: {
      type: Boolean,
      default: false
    },
    position: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      headlineLink: {
        text: this.titleUrlMore,
        textSp: this.titleUrlMoreSp,
        link: this.urlMore,
        isNuxtLink: this.isNuxtLinkHeadline
      }
    }
  }
}
</script>
<style lang="scss" scoped>
a {
  @include link-effect;
  color: $ds2-color-brand-secondary-700;
}

.c-carouselBlog {
  position: relative;
}

.c-carouselBlogContent {
  height: 140px;
  margin-top: 12px;
  position: relative;
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-carouselBlog {
    margin-right: 0;

    &_swiper {
      padding: 16px 0 0 12px;
    }
  }

  .c-carouselBlogTitle {
    padding: 0 12px;
  }

  .c-carouselBlogContent {
    margin-top: 8px;
  }
}
</style>
