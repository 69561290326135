
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import DModal from '~/components/molecules/DS2/DModal'
import DSimpleServiceCard from '~/components/molecules/DS2/DSimpleServiceCard'
import DButton from '~/components/atoms/DS2/DButton'
import { ValueOf } from '~/types/util'
import { ServiceCount } from '~/constants/ez-top'

export const REGISTER_COMPLETE_TYPE = {
  BUYER: 'buyer',
  PROVIDER: 'provider'
} as const

type REGISTER_COMPLETE_TYPE_VALUE = ValueOf<typeof REGISTER_COMPLETE_TYPE>

const CONTENTS = {
  [REGISTER_COMPLETE_TYPE.BUYER]: {
    title: '',
    afterTitle: 'ココナラ共通会員へのご登録<br class="isSP">ありがとうございます！',
    isCsmCaption: false,
    caption:
      '選択したカテゴリの発注には<br>ココナラスキルマーケットが<br class="isSP">ご利用いただけます',
    services: [
      {
        imgPath: require('~/assets/images/components/DS2/DRegisterCompleteModal/service-image-1.png'),
        title: '商品のPR動画を制作します',
        rating: 5,
        price: 100000,
        type: ''
      },
      {
        imgPath: require('~/assets/images/components/DS2/DRegisterCompleteModal/service-image-2.png'),
        title: '英会話のレッスンをします',
        rating: 5,
        price: 20000,
        type: 'unitTime'
      }
    ],
    text: `${ServiceCount}の出品サービスの中からご希望に<br class="isSP">合ったものを選んで、<br class="isPC">すぐに発注できます`,
    isSpTextLeft: false,
    btnText: 'スキルマーケットで探す'
  },
  [REGISTER_COMPLETE_TYPE.PROVIDER]: {
    title: 'すぐに案件を受注したい方へ',
    afterTitle: '',
    isCsmCaption: true,
    caption: '',
    services: [
      {
        imgPath: require('~/assets/images/components/DS2/DRegisterCompleteModal/service-image-3.png'),
        title: '魅力が伝わる名刺を作成します',
        rating: 5,
        price: 30000,
        type: ''
      },
      {
        imgPath: require('~/assets/images/components/DS2/DRegisterCompleteModal/service-image-4.png'),
        title: '企業ホームページを作成します',
        rating: 5,
        price: 150000,
        type: ''
      }
    ],
    text: 'ココナラスキルマーケットなら、今すぐサービスを作成して<br class="isPC">販売することができます。お急ぎの方はご利用をご検討ください。',
    isSpTextLeft: true,
    btnText: 'スキルマーケットを見る'
  }
}

export default defineComponent({
  name: 'DRegisterCompleteModal',
  components: {
    DModal,
    DSimpleServiceCard,
    DButton,
    CoconalaLogo: () => import('~/components/atoms/CoconalaLogo')
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    registerCompleteType: {
      type: String as PropType<REGISTER_COMPLETE_TYPE_VALUE>,
      required: true
    }
  },
  setup(props, { emit }) {
    const onClose = () => {
      emit('close')
    }

    return {
      displayContent: CONTENTS[props.registerCompleteType],
      onClose
    }
  }
})
