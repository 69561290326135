<template>
  <div
    v-bind="$attrs"
    v-on="$listeners"
    class="d-field"
    :class="classes"
  >
    <div
      v-if="title"
      class="d-field_titleArea"
    >
      <span class="d-field_title">
        {{ title }}
      </span>
      <span
        v-if="isPrivate"
        class="d-field_conditions"
      >
        (非公開)
      </span>
      <span
        v-if="!required && !isOptionTextHidden"
        class="d-field_conditions"
      >
        (任意)
      </span>
      <span
        v-if="attention"
        class="d-field_conditions"
      >
        {{ attention }}
      </span>
    </div>
    <div v-else-if="$slots.titleEl">
      <div class="d-field_title">
        <slot name="titleEl" />
      </div>
    </div>
    <slot :required="required" />
    <ul
      v-if="messages.length || isCounterPositionRight"
      class="d-field_messages"
      :class="{ 'd-field_messages-counterPositionRight': isCounterPositionRight }"
    >
      <li
        v-for="(message, index) in messages"
        :key="index"
      >
        <span
          class="d-field_message"
          :class="`d-field_message-${message.type}`"
        >
          <!--
            NOTE::
            「通常フォーム」の場合は、アイコンを出さない。（条件を後出しで出しており、こちら都合のエラーなので、バツアイコンを出すとユーザー体験が悪い）
            「入力条件フォーム」の場合は、アイコンを出す。（予め条件を提示しており、それにそぐわない状態で入力を完了しているユーザーに対してアイコンを提示するのは問題ない）
          -->
          <template v-if="!onlyErrorMessage">
            <CoconalaIcon :name="typeIconName(message.type)" />
            {{ message.message.alwaysDisplayedMessage || '' }}
          </template>
          <template v-else>
            {{ message.message.validateMessage || '' }}
          </template>
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'DField',
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    title: {
      type: String
    },
    attention: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    isPrivate: {
      type: Boolean,
      default: false
    },
    onlyErrorMessage: {
      type: Boolean,
      default: true
    },
    isOptionTextHidden: {
      type: Boolean,
      default: false
    },
    isCounterPositionRight: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    typeIconName(type) {
      return type === 'error' ? 'cross' : 'check'
    }
  },
  computed: {
    classes() {
      return {
        'is-error': this.messages.find(message => message.type === 'error') ? true : false,
        'only-error-message': this.onlyErrorMessage
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.d-field {
  &_messages {
    &-counterPositionRight {
      min-height: 23px;
    }
  }
  &_message {
    font-size: 11px;
    line-height: 1.2;

    &-success {
      color: $ds2-color-brand-primary;
    }
    &-error {
      color: $ds2-color-red;
    }
    &-info {
      color: $ds2-color-gray-400;
    }
  }
  &_titleArea {
    display: flex;
    margin-bottom: 4px;

    align-items: center;
  }
  &_title {
    margin-right: 4px;
    color: $ds2-color-gray-900;
    letter-spacing: 0.05em;
    font-weight: bold;
    font-size: 13px;
  }
  &_conditions {
    color: $ds2-color-gray-400;
    letter-spacing: 0.05em;
    font-size: 11px;
  }
  // inputをwrapしてる時の定義
  &.is-error {
    ::v-deep .d-input {
      // textarea対応
      border-color: $ds2-color-red;
      .input,
      .textarea {
        border-color: $ds2-color-red;
      }
    }
    ::v-deep .d-radio {
      border-color: $ds2-color-red;
    }
    // selectをwrapしてる時の定義,orugaにclass名がついていないためselect直指定
    ::v-deep select {
      border-color: $ds2-color-red !important;
    }
  }
  &.only-error-message {
    .d-field {
      &_message {
        &-success {
          display: none;
        }
        &-error {
          color: $ds2-color-red;
        }
        &-info {
          display: none;
        }
      }
    }
  }
}
</style>
