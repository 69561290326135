import Util from '~/assets/javascripts/util'

const CAMPAIGN_START_DATE = '2022-07-14'
// NOTE: 月末がキャンペーン終了日(固定)
const CAMPAIGN_END_DATE = Util.getDefaultTzDayjs(CAMPAIGN_START_DATE)
  .endOf('M')
  .format('YYYY-MM-DD')
const INCREASED_AMOUNT_CAMPAIGN_START_DATE = '2023-12-28'
const INCREASED_AMOUNT_CAMPAIGN_END_DATE = '2024-01-04'

/**
 * 今日がキャンペーン期間中かどうかを返却する
 */
export function isViralCampaign() {
  return Util.isDuring(CAMPAIGN_START_DATE, CAMPAIGN_END_DATE)
}
/**
 * 今日が友達招待ポイント増額キャンペーン期間中かどうかを返却する
 */
export function isIncreasedAmountViralCampaign() {
  return Util.isDuring(INCREASED_AMOUNT_CAMPAIGN_START_DATE, INCREASED_AMOUNT_CAMPAIGN_END_DATE)
}
/**
 * 友達招待キャンペーンの開始日を曜日付きで返却する
 */
export function formatStartDate() {
  return Util.getDefaultTzDayjs(CAMPAIGN_START_DATE).format('YYYY/MM/DD（ddd）')
}
/**
 * 友達招待キャンペーンの終了日を曜日付きで返却する
 */
export function formatEndDate() {
  return Util.getDefaultTzDayjs(CAMPAIGN_END_DATE).format('YYYY/MM/DD（ddd）')
}
/**
 * 友達招待キャンペーンの翌日月初の日付を返却する
 */
export function campaignNextMonth() {
  return Util.getDefaultTzDayjs(CAMPAIGN_START_DATE).add(1, 'M').startOf('M')
}
/**
 * 友達招待のリンクなどに表示する文言を返却する
 */
export function campaignText() {
  return isViralCampaign()
    ? { main: '友達招待(最大10万円獲得)', sub: '期間限定!' }
    : isIncreasedAmountViralCampaign()
    ? { main: '友達招待', sub: '1,500ポイント獲得・手数料無料' }
    : { main: '友達招待', sub: '1,000ポイント獲得・手数料無料' }
}
