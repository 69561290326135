import { render, staticRenderFns } from "./CarouselRecommendRequests.vue?vue&type=template&id=be813fc0&scoped=true&"
import script from "./CarouselRecommendRequests.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CarouselRecommendRequests.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CarouselRecommendRequests.vue?vue&type=style&index=0&id=be813fc0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be813fc0",
  null
  
)

export default component.exports