import { FetchBookmarkDetailRequest } from '~/stub/gw/outsource/bookmark_service_pb'
import GrpcAccessorBase from '../grpc-accessor-base'
import { Stripped, toBoolValue, toInt32Value } from '~/grpc/grpc-util'
import grpcClient from '~/grpc/grpc-client'
import { BookmarkService } from '~/stub/gw/outsource/bookmark_service_pb_service'

export type BookmarkCarouselType = {
  id?: number | string
  categoryName?: string
  title?: string
  priceMin?: number
  priceMax?: number
  expireDate?: string
  applicationCount?: number
  workingDayType?: string
  paymentMethodType?: string
}

export default class BookmarkServiceAccessor extends GrpcAccessorBase {
  fetchBookmarks(params: Stripped<FetchBookmarkDetailRequest.AsObject>) {
    const metadata = this.getMetadata()
    const request = new FetchBookmarkDetailRequest()

    request.setPage(toInt32Value(params.page))
    request.setPerPage(toInt32Value(params.perPage))
    request.setIsExpiredIncluded(toBoolValue(params.isExpiredIncluded))

    return grpcClient({
      method: BookmarkService.FetchBookmark,
      request,
      metadata,
      strip: true
    })
  }
}
