
import {
  defineComponent,
  useContext,
  computed,
  ref,
  toRefs,
  PropType,
  useStore,
  watch
} from '@nuxtjs/composition-api'
import DField from '~/components/molecules/DS2/DField'
import DInput from '~/components/atoms/DS2/DInput'
import DSelect from '~/components/atoms/DS2/DSelect'
import DRadio from '~/components/atoms/DS2/DRadio'
import { Prefecture } from '~/stub/profileservice/master_pb.d'
import { RegisterType, REGISTER_TYPE } from '~/constants/register'
import { state } from '~/store/master'
import formMixin from '~/mixins/form-mixin'
import {
  requiredField,
  between,
  anyMaxLength,
  katakana,
  isPast,
  alphaNumMix,
  alphaNumSymbol,
  sameAs,
  isParentalConsent,
  noSymbolName,
  noSpace
} from '~/assets/javascripts/ds2/validators'
import RegisterAccessor, { ListCountry } from '~/api/accessors/auth/register-accessor'
import { toTranslatedArrayText } from '~/components/logics/translate/translate'

export type FormInfo = {
  pre_auth_hash: string
  email: string
  password: string
  confirmation_password: string
  last_name: string
  first_name: string
  last_kana_name: string
  first_kana_name: string
  year: number | null
  month: number | null
  day: number | null
  guardian_check: boolean | null
  prefecture_name: string | null
  country_name: string | null
  sex: 'M' | 'F' | 'O' | null
  registration_purpose: number
  private_corporation: number
}

const MAX_NAME_LENGTH = 45
const MAX_PASSWORD_LENGTH = 50
const MIN_PASSWORD_LENGTH = 6
export const PREF_BESIDES_JAPAN = '日本以外'

export default defineComponent({
  name: 'RegisterBasicInfoForm',
  mixins: [formMixin],
  components: {
    DField,
    DInput,
    DSelect,
    DRadio,
    DCheckbox: () => import('~/components/atoms/DS2/DCheckbox')
  },
  data() {
    return {
      translatedBirthdayErrorMessage: [],
      translatedNameErrorMessage: [],
      translatedGuardianCheckErrorMessage: []
    }
  },
  watch: {
    'value.year': function () {
      if (this.value.day > this.birthDays) {
        this.value.day = null
      }
    },
    'value.month': function () {
      if (this.value.day > this.birthDays) {
        this.value.day = null
      }
    },
    async birthdayErrorMessage() {
      this.translatedBirthdayErrorMessage = await this.translatedErrorMessages(
        this.birthdayErrorMessage
      )
    },
    async nameErrorMessage() {
      this.translatedNameErrorMessage = await this.translatedErrorMessages(this.nameErrorMessage)
    },
    async guardianCheckErrorMessage() {
      this.translatedGuardianCheckErrorMessage = await this.translatedErrorMessages(
        this.guardianCheckErrorMessage
      )
    }
  },
  computed: {
    birthdayErrorMessage() {
      const errorMessages = this.getErrorMessages('value.year', this.isSubmitted).concat(
        this.getErrorMessages('value.month', this.isSubmitted),
        this.getErrorMessages('value.day', this.isSubmitted)
      )
      return this.$util.deleteDuplicateObj(errorMessages)
    },
    nameErrorMessage() {
      const errorMessages = this.getErrorMessages('value.last_name', this.isSubmitted).concat(
        this.getErrorMessages('value.first_name', this.isSubmitted)
      )
      return this.$util.deleteDuplicateObj(errorMessages)
    },
    nameKanaErrorMessage() {
      const errorMessages = this.getErrorMessages('value.last_kana_name', this.isSubmitted).concat(
        this.getErrorMessages('value.first_kana_name', this.isSubmitted)
      )
      return this.$util.deleteDuplicateObj(errorMessages)
    },
    prefectureErrorMessage() {
      const errorMessages = [
        ...this.getErrorMessages('value.prefecture_name', this.isSubmitted),
        ...(this.showSelectCountry
          ? this.getErrorMessages('value.country_name', this.isSubmitted)
          : [])
      ]
      return this.$util.deleteDuplicateObj(errorMessages)
    },
    guardianCheckErrorMessage() {
      return this.isIndividual && !this.isAdultClone
        ? this.getErrorMessages('value.guardian_check', this.isSubmitted)
        : []
    }
  },
  methods: {
    async translatedErrorMessages(errorMessages) {
      const errors = await toTranslatedArrayText(errorMessages.map(v => v.message.validateMessage))
      return errorMessages.map(v => {
        v.message.validateMessage = errors[v.message.validateMessage] ?? v.message.validateMessage
        return v
      })
    }
  },
  props: {
    value: {
      type: Object as PropType<FormInfo>,
      required: true
    },
    isSubmitted: {
      type: Boolean,
      required: true
    },
    showSelectCountry: {
      type: Boolean,
      required: true
    },
    showSelectPrefecture: {
      type: Boolean,
      required: true
    },
    isIndividual: {
      type: Boolean,
      required: true
    },
    isCorporate: {
      type: Boolean,
      required: true
    },
    isAdult: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const { $util, $sentry } = useContext()

    const store = useStore<{
      master: {
        prefectures: Prefecture.AsObject[]
        sexesRaw: ReturnType<typeof state>['sexesRaw']
      }
      register: {
        type: RegisterType
      }
    }>()

    const { value, showSelectCountry } = toRefs(props)

    const hideEmail = computed(() => {
      const registerType = store.state.register.type
      return registerType === REGISTER_TYPE.EMAIL || registerType === REGISTER_TYPE.APPLE
    })
    const showPassword = computed(() => {
      return store.state.register.type === REGISTER_TYPE.EMAIL
    })
    const isAdultClone = computed(() => {
      const now = new Date()
      const nowYear = now.getFullYear()
      const nowMonth = now.getMonth() + 1 // 0始まりのため
      const nowDate = now.getDate()
      const yearThreshold = nowYear - 18 // 18歳から成人
      // 年で判断
      if (value.value.year && value.value.year > yearThreshold) {
        return false
      }
      // 年が同じ場合は月で判断
      if (value.value.year === yearThreshold && value.value.month && value.value.month > nowMonth) {
        return false
      }
      // 年・月が同じ場合は日で判断、当日は18歳なので許容
      if (
        value.value.year === yearThreshold &&
        value.value.month === nowMonth &&
        value.value.day &&
        value.value.day > nowDate
      ) {
        return false
      }

      return true
    })

    watch(isAdultClone, bool => emit('update:isAdult', bool))

    const nameLabel = computed(() => (props.isCorporate ? 'ご担当者名' : '氏名'))
    const nameLabelKana = computed(() =>
      props.isCorporate ? 'ご担当者名（カナ）' : '氏名（カナ）'
    )

    const prefList: string[] = [
      ...store.state.master.prefectures
        .filter(pref => pref.name !== 'その他')
        .map(pref => pref.name),
      PREF_BESIDES_JAPAN
    ]

    const registerAccessor = new RegisterAccessor(store.state)
    const countryList = ref<ListCountry | []>([])

    const setCountryList = () => {
      if (!showSelectCountry || countryList.value.length !== 0) return
      registerAccessor
        .fetchCountry()
        .then(countriesList => {
          countryList.value = countriesList
        })
        .catch(err => {
          $sentry.captureException(err)
        })
    }

    if (showSelectCountry) setCountryList()
    watch(showSelectCountry, () => {
      setCountryList()
    })

    const currentYear = $util.dayjs().year()

    const birthYears = $util.range(currentYear - 100, currentYear).reverse()
    const birthMonths = $util.range(1, 12)
    const birthDays = computed(() => {
      return value.value.year && value.value.month
        ? new Date(value.value.year!, value.value.month!, 0).getDate()
        : 31
    })

    return {
      hideEmail,
      showPassword,
      isAdultClone,
      nameLabel,
      nameLabelKana,
      birthYears,
      birthMonths,
      birthDays,
      prefList,
      countryList,
      sexesRaw: store.state.master.sexesRaw
    }
  },
  validations() {
    const rules = {
      value: {
        last_name: {
          required: requiredField(this.nameLabel),
          maxLength: anyMaxLength(MAX_NAME_LENGTH),
          noSpace,
          noSymbolName
        },
        first_name: {
          required: requiredField(this.nameLabel),
          maxLength: anyMaxLength(MAX_NAME_LENGTH),
          noSpace,
          noSymbolName
        },
        year: {
          requiredChoice: requiredField('生年月日'),
          isPast
        },
        month: {
          requiredChoice: requiredField('生年月日'),
          isPast
        },
        day: {
          requiredChoice: requiredField('生年月日'),
          isPast
        },
        sex: {
          requiredChoice: requiredField('性別')
        }
      }
    }

    if (this.isIndividual && !this.isAdultClone) {
      Object.assign(rules.value, {
        guardian_check: {
          isParentalConsent
        }
      })
    }
    if (this.showPassword) {
      Object.assign(rules.value, {
        password: {
          required: requiredField('パスワード'),
          between: between(MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH),
          alphaNumMix,
          alphaNumSymbol
        },
        confirmation_password: {
          required: requiredField('パスワード確認'),
          sameAs: sameAs(function () {
            return this.value.password
          })
        }
      })
    }
    if (this.showSelectPrefecture) {
      Object.assign(rules.value, {
        prefecture_name: {
          requiredChoice: requiredField('都道府県')
        }
      })
    }
    if (this.showSelectPrefecture && this.showSelectCountry) {
      Object.assign(rules.value, {
        country_name: {
          requiredChoice: requiredField('国名')
        }
      })
    }
    if (!this.$translate.isTranslatableTarget()) {
      Object.assign(rules.value, {
        last_kana_name: {
          required: requiredField(this.nameLabelKana),
          katakana,
          noSpace,
          maxLength: anyMaxLength(MAX_NAME_LENGTH)
        },
        first_kana_name: {
          required: requiredField(this.nameLabelKana),
          katakana,
          noSpace,
          maxLength: anyMaxLength(MAX_NAME_LENGTH)
        }
      })
    }

    return rules
  }
})
