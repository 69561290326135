/** @type {import('vue').ComponentOptions} */
const LinkRefProvider = {
  provide() {
    const { linkRef, linkRefKind, linkRefSort, linkRefCategory } = this
    return {
      linkRef,
      linkRefKind,
      linkRefSort,
      linkRefCategory
    }
  },
  props: {
    linkRef: {
      type: String,
      default: ''
    },
    linkRefKind: {
      type: String,
      default: ''
    },
    linkRefSort: {
      type: String,
      default: ''
    },
    linkRefCategory: {
      type: Number,
      default: null
    }
  }
}
export default LinkRefProvider
