import grpcClient from '~/grpc/grpc-client'
import { HomeService } from '~/stub/apigateway/home/home_pb_service'
import GrpcAccessorBase from '../grpc-accessor-base'
import { GetMyRecommendRequestsRequest } from '~/stub/apigateway/home/home_pb'
import { toStringValue } from '~/grpc/grpc-util'

class HomeAccessor extends GrpcAccessorBase {
  /**
   * おすすめの募集を取得する
   * @returns おすすめの募集の募集情報
   */
  async getMyRecommendRequests() {
    const request = new GetMyRecommendRequestsRequest()
    const metadata = this.getMetadata()
    request.setAlgorithm(toStringValue('vector_recommend'))
    return await grpcClient({
      method: HomeService.GetMyRecommendRequests,
      request,
      metadata,
      strip: true
    })
  }
}
export default HomeAccessor
