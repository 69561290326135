<template>
  <ORadio
    v-bind="$attrs"
    v-on="$listeners"
    v-model="innerValue"
    :native-value="nativeValue"
    class="d-radio"
    :class="{ 'is-checked': isChecked }"
    :disabled="isDisabled"
  >
    <slot
      v-for="slotName in Object.keys($slots)"
      :name="slotName"
      :slot="slotName"
    />
  </ORadio>
</template>
<script>
export default {
  name: 'DRadio',
  props: {
    value: [String, Number, Boolean, Function, Object, Array, Symbol],
    nativeValue: [String, Number, Boolean],
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    isChecked() {
      return this.innerValue == this.nativeValue
    }
  }
}
</script>
<style lang="scss" scoped>
.b-radio.radio {
  $radioButtonSize: 20px;

  padding: 0;
  min-height: auto;
  color: $ds2-color-gray-900;
  font-size: 13px;
  line-height: 1em;

  ::v-deep {
    .control-label {
      padding-left: 6px !important;
    }
    input[type='radio'] {
      position: relative;
      z-index: 1;
      display: flex;
      padding: unset;
      width: $radioButtonSize !important;
      height: $radioButtonSize !important;
      border: solid 2px $ds2-color-gray-600 !important;
      border-radius: 50%;
      opacity: 1;
      cursor: pointer;
      transition: opacity 0.15s ease-out;

      flex-shrink: 0;

      &::before {
        margin-bottom: calc(-1 * #{$radioButtonSize} / 2) !important;
        margin-left: calc(-1 * #{$radioButtonSize} / 2) !important;
        width: $radioButtonSize !important;
        height: $radioButtonSize !important;
      }
      &:focus {
        border-color: $ds2-color-brand-primary !important;
      }
      &:hover {
        border-color: $ds2-color-gray-100 !important;
      }
      &.is-checked {
        border-color: $ds2-color-brand-primary;
      }
      &.is-disabled {
        border-color: $ds2-color-border-base;
      }
      &:checked {
        border-color: $ds2-color-brand-primary !important;
        box-shadow: none !important;
        &::before {
          left: 50%;
          background-color: $ds2-color-brand-primary;
          transform: scale(0.5) !important;
        }
      }
    }
  }
}
</style>
