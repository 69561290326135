<template>
  <nuxt-link
    v-if="isNuxtLink"
    :to="link"
    class="c-switchLink"
  >
    <slot></slot>
  </nuxt-link>
  <a
    v-else
    :href="link"
    :target="isExternalLink ? '_blank' : false"
    class="c-switchLink"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'SwitchLink',
  props: {
    link: {
      type: String,
      required: true
    },
    isNuxtLink: {
      type: Boolean,
      default: false
    },
    isExternalLink: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-switchLink {
  @include link-effect;
}
</style>
