// package: business
// file: apigateway/business/business_registration.proto

import * as apigateway_business_business_registration_pb from "../../apigateway/business/business_registration_pb";
export class BusinessRegistrationService {
  static serviceName = "business.BusinessRegistrationService";
}
export namespace BusinessRegistrationService {
  export class GetAccount {
    static readonly methodName = "GetAccount";
    static readonly service = BusinessRegistrationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_business_business_registration_pb.GetAccountRequest;
    static readonly responseType = apigateway_business_business_registration_pb.GetAccountReply;
  }
  export class UpdateBusinessCorporation {
    static readonly methodName = "UpdateBusinessCorporation";
    static readonly service = BusinessRegistrationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_business_business_registration_pb.UpdateBusinessCorporationRequest;
    static readonly responseType = apigateway_business_business_registration_pb.UpdateBusinessCorporationReply;
  }
  export class UpdateBusinessUser {
    static readonly methodName = "UpdateBusinessUser";
    static readonly service = BusinessRegistrationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_business_business_registration_pb.UpdateBusinessUserRequest;
    static readonly responseType = apigateway_business_business_registration_pb.UpdateBusinessUserReply;
  }
  export class GetInvitationStatus {
    static readonly methodName = "GetInvitationStatus";
    static readonly service = BusinessRegistrationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_business_business_registration_pb.GetInvitationStatusRequest;
    static readonly responseType = apigateway_business_business_registration_pb.GetInvitationStatusReply;
  }
  export class VerifyInvitation {
    static readonly methodName = "VerifyInvitation";
    static readonly service = BusinessRegistrationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_business_business_registration_pb.VerifyInvitationRequest;
    static readonly responseType = apigateway_business_business_registration_pb.VerifyInvitationReply;
  }
}
