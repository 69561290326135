import { ValueOf } from '~/types/util'

/** 経済兼TOPで扱うサービス数(公表値と別の値を表示したいらしいので) */
export const ServiceCount = '60万'

export const EzTopCategoryGroups = {
  /** IT・開発・プログラミング、デザイン */
  A: 1,
  /** イラスト、マーケティング・Web集客、ビジネス代行・アシスタント、コンサルティング・士業、ライティング・翻訳 */
  B: 2,
  /** Webサイト制作、動画制作・編集、音楽・ナレーション */
  C: 3,
  /** 占い、悩み相談、学習・就職・資格、ライフスタイル、オンラインレッスン */
  D: 4
} as const
export type EzTopCategoryGroup = ValueOf<typeof EzTopCategoryGroups>

export type EzTopCategory = {
  id: number
  image: string
  name: string
  subText: string
  /** 診断ページに表示する際に上書きする内容 */
  overrideWith?: Pick<EzTopCategory, 'name' | 'subText'>
  group: EzTopCategoryGroup
  /** 単発でプロフェッショナルの有無フラグ */
  hasSingleProfessional?: boolean
}

export const ezTopBusinessCategories: readonly EzTopCategory[] = [
  {
    id: 1,
    image: require('~/assets/images/ez-top/categories/it_develop.png'),
    name: 'IT・開発・プログラミング',
    subText: 'システム開発・プログラミング',
    group: EzTopCategoryGroups.A
  },
  {
    id: 2,
    image: require('~/assets/images/ez-top/categories/design.png'),
    name: 'デザイン',
    subText: 'ロゴ・チラシ・UI/UXデザイン',
    group: EzTopCategoryGroups.A,
    hasSingleProfessional: true
  },
  {
    id: 3,
    image: require('~/assets/images/ez-top/categories/web_creation.png'),
    name: 'Webサイト制作',
    subText: 'Webサイト・LP・EC運用',
    group: EzTopCategoryGroups.C
  },
  {
    id: 4,
    image: require('~/assets/images/ez-top/categories/illustration.png'),
    name: 'イラスト・<br class="isSP">漫画',
    subText: 'イラスト・アイコン・モデリング',
    group: EzTopCategoryGroups.B
  },
  {
    id: 5,
    image: require('~/assets/images/ez-top/categories/movie.png'),
    name: '動画制作・<br class="isSP">編集',
    subText: '編集・動画制作・MV・PV',
    group: EzTopCategoryGroups.C
  },
  {
    id: 6,
    image: require('~/assets/images/ez-top/categories/marketing.png'),
    name: 'マーケティング・Web集客',
    subText: '戦略・集客・SNS・動画・広告',
    group: EzTopCategoryGroups.B
  },
  {
    id: 7,
    image: require('~/assets/images/ez-top/categories/assistant.png'),
    name: 'ビジネス代行・アシスタント',
    subText: '事務・経理・人事・営業・CS',
    group: EzTopCategoryGroups.A
  },
  {
    id: 8,
    image: require('~/assets/images/ez-top/categories/consulting.png'),
    name: 'コンサルティング・士業',
    subText: '経営・人事・IT・財務・士業',
    group: EzTopCategoryGroups.B
  },
  {
    id: 9,
    image: require('~/assets/images/ez-top/categories/music.png'),
    name: '音楽・ナレーション',
    subText: '作曲・作詞・ナレーション',
    group: EzTopCategoryGroups.C
  },
  {
    id: 10,
    image: require('~/assets/images/ez-top/categories/writing.png'),
    name: 'ライティング・翻訳',
    subText: '記事作成・翻訳・文章校正',
    group: EzTopCategoryGroups.A
  }
]

export const ezTopPrivateCategories: readonly EzTopCategory[] = [
  {
    id: 11,
    image: require('~/assets/images/ez-top/categories/divination.png'),
    name: '占い（チャット・電話）',
    subText: '恋愛・結婚・人生・総合・仕事',
    group: EzTopCategoryGroups.D
  },
  {
    id: 12,
    image: require('~/assets/images/ez-top/categories/private-consultation.png'),
    name: '悩み相談・<br class="isSP">法律相談',
    subText: '話し相手・恋愛・対人・仕事',
    group: EzTopCategoryGroups.D
  },
  {
    id: 13,
    image: require('~/assets/images/ez-top/categories/study.png'),
    name: '学習・就職・資格',
    subText: '受験・就活・面接対策・資格取得',
    group: EzTopCategoryGroups.D
  },
  {
    id: 14,
    image: require('~/assets/images/ez-top/categories/life-style.png'),
    name: 'ライフ<br class="isSP">スタイル',
    subText: '住まい・美容・生活・趣味',
    overrideWith: {
      name: 'ライフスタイル・マネー',
      subText: '住まい・美容・生活・マネー'
    },
    group: EzTopCategoryGroups.D
  },
  {
    id: 15,
    image: require('~/assets/images/ez-top/categories/online-lesson.png'),
    name: 'オンライン<br class="isSP">レッスン',
    subText: '語学・家庭教師・IT・楽器',
    group: EzTopCategoryGroups.D
  }
] as const

/** 職種種別 */
export const EzTopJobTypes = {
  Tech: 1,
  Assistant: 2,
  Consultant: 3,
  None: 4
} as const
export type EzTopJobType = ValueOf<typeof EzTopJobTypes>

export type EzTopJob = {
  id: number
  type: EzTopJobType
  name: string
  /** 業務内容(各業務内容で区切って改行するため配列で定義) */
  businessContents: string[]
}

export const ezTopJobs: EzTopJob[] = [
  {
    id: 1,
    type: EzTopJobTypes.Tech,
    name: 'エンジニア',
    businessContents: ['Web/スマホアプリ開発', '業務システム開発']
  },
  {
    id: 2,
    type: EzTopJobTypes.Tech,
    name: 'デザイナー',
    businessContents: ['UI/UXデザイン', 'Webデザイン']
  },
  {
    id: 3,
    type: EzTopJobTypes.None,
    name: 'PM・PO・ディレクター',
    businessContents: ['プロダクトマネジメント', 'プロジェクトマネジメント']
  },
  {
    id: 4,
    type: EzTopJobTypes.Consultant,
    name: '経営者・CxO',
    businessContents: ['CEO', 'COO', 'CFO']
  },
  {
    id: 5,
    type: EzTopJobTypes.Consultant,
    name: 'マーケティング',
    businessContents: ['マーケティング戦略立案', 'ブランディング', 'PR']
  },
  {
    id: 6,
    type: EzTopJobTypes.Consultant,
    name: '企画・事業開発',
    businessContents: ['新規事業開発', '経営企画', '営業企画']
  },
  {
    id: 7,
    type: EzTopJobTypes.Consultant,
    name: 'コンサルタント・専門家',
    businessContents: ['経営', 'ファイナンス', '組織']
  },
  {
    id: 8,
    type: EzTopJobTypes.Consultant,
    name: '士業',
    businessContents: ['弁護士', '司法書士', '行政書士', '税理士', '社労士']
  },
  {
    id: 9,
    type: EzTopJobTypes.Assistant,
    name: '採用・人事',
    businessContents: ['求人媒体管理', '応募者対応', '勤怠管理支援']
  },
  {
    id: 10,
    type: EzTopJobTypes.Assistant,
    name: '経理・労務',
    businessContents: ['決算業務', '経理事務', '給与計算']
  },
  {
    id: 11,
    type: EzTopJobTypes.Assistant,
    name: '事務・アシスタント・CS',
    businessContents: ['一般事務', '業務サポート/代行', 'ユーザーサポート']
  },
  {
    id: 12,
    type: EzTopJobTypes.Assistant,
    name: '営業',
    businessContents: ['法人営業（テレアポ/メール営業）', '営業事務']
  }
]

/** 発注方法種別 */
export const OrderMethodTypes = {
  /** 単発 */
  Single: 1,
  /** 業務委託 */
  Subcontracting: 2
} as const
export type OrderMethodType = ValueOf<typeof OrderMethodTypes>

/** マッチング方法 */
export const MatchingMethodTypes = {
  /** スキルマーケット */
  SkillMarket: 1,
  /** 公開募集 */
  Request: 2,
  /** プロフェッショナル */
  Professional: 3
} as const
export type MatchingMethodType = ValueOf<typeof MatchingMethodTypes>
