<template>
  <div class="c-carouselLegal">
    <ContentHeadline
      class="c-carouselLegalTitle"
      :title-tag="titleTag"
      title-icon="category-legal"
      :title-text="title"
      :title-url="`${LEGAL_URL}${this.titleUrl}`"
      is-external-link-title
      :right-link="headlineLink"
    />

    <div class="c-carouselLegalContent">
      <CarouselLegalList
        :legal-qas="legalQas"
        :is-ranking="isRanking"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ContentHeadline from '~/components/molecules/ContentHeadline'
import CarouselLegalList from './CarouselLegalList'

export default {
  name: 'CarouselLegal',
  components: {
    ContentHeadline,
    CarouselLegalList
  },
  props: {
    legalQas: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    titleTag: {
      type: String,
      default: 'div'
    },
    titleUrl: {
      type: String,
      default: ''
    },
    titleUrlMore: {
      type: String,
      default: ''
    },
    urlMore: {
      type: String,
      default: ''
    },
    isRanking: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('constants', ['LEGAL_URL']),
    headlineLink() {
      return {
        text: this.titleUrlMore,
        link: `${this.LEGAL_URL}${this.urlMore}`,
        isExternalLink: true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
a {
  @include link-effect;
  color: $ds2-color-brand-secondary-700;
}

.c-carouselLegal {
  position: relative;
}

.c-carouselLegalContent {
  margin-top: 12px;
  position: relative;
}

@media (max-width: breakpoint(Home, M)) {
  .c-carouselLegal {
    margin-right: 0;

    &_swiper {
      padding: 16px 0 0 12px;
    }
  }

  .c-carouselLegalTitle {
    padding: 0 12px;
  }

  .c-carouselLegalContent {
    margin-top: 8px;
  }
}
</style>
