
import {
  defineComponent,
  ref,
  computed,
  toRefs,
  PropType,
  onMounted,
  onUnmounted,
  watch,
  useContext
} from '@nuxtjs/composition-api'
import DInput from '~/components/atoms/DS2/DInput'
import { SuggestItem } from './types'

const CONVERT_WIDTH_TYPE = Object.freeze([null, 'to-half', 'to-full']) // 全角、半角の変換(to-half: 半角へ変換、to-full: 全角へ変換)

export default defineComponent({
  name: 'DSuggest',
  components: {
    DInput
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String
    },
    placeholder: {
      type: String
    },
    data: {
      type: Array as PropType<SuggestItem[]>,
      required: true
    },
    disabledData: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    minInputLengthToShowSuggestList: {
      type: Number,
      default: 2
    },
    isError: {
      type: Boolean,
      default: false
    },
    allowClearInput: {
      type: Boolean,
      default: true
    },
    noFilter: {
      type: Boolean,
      default: false
    },
    isFloat: {
      type: Boolean,
      default: false
    },
    convertWidthType: {
      // 全角、半角の変換
      type: String,
      default: null,
      validator(v: (typeof CONVERT_WIDTH_TYPE)[0]) {
        return CONVERT_WIDTH_TYPE.includes(v)
      }
    }
  },
  setup(props, { emit }) {
    const { $util } = useContext()
    const { value, data, disabledData, minInputLengthToShowSuggestList } = toRefs(props)
    const dSuggest = ref()
    const suggestList = ref()
    const isShowSuggests = ref(false)
    const focusedSuggestIndex = ref<number | null>(null)

    const innerValue = computed({
      get: () => {
        return value.value
      },
      set: val => {
        emit('input', val)
      }
    })

    const isShowClearButton = computed(() => innerValue.value.length > 0)

    const suggestResult = computed<SuggestItem[]>(() => {
      if (innerValue.value.length < minInputLengthToShowSuggestList.value) return []

      if (props.noFilter) return data.value

      const escapeVal = $util.escapeRegex(innerValue.value)
      return data.value.filter(
        item =>
          new RegExp(escapeVal, 'i').test(item.name) && !disabledData.value.includes(item.name)
      )
    })

    watch(suggestResult, () => (focusedSuggestIndex.value = null))

    onMounted(() => {
      window.addEventListener('click', onBlurInput)
    })
    onUnmounted(() => {
      window.removeEventListener('click', onBlurInput)
    })

    const onFocusInput = () => {
      isShowSuggests.value = true
      emit('focus-input')
    }
    const onBlurInput = event => {
      if (dSuggest.value && dSuggest.value.contains(event.target)) return
      isShowSuggests.value = false
      emit('blur-input')
    }

    // フォーカスしている項目
    const listItemOfFocusedSuggestIndex = computed(() => {
      if (focusedSuggestIndex.value === null) return null
      const listItem: HTMLElement[] = suggestList.value.getElementsByClassName('d-suggestList_item')
      return listItem[focusedSuggestIndex.value]
    })

    const suggestUp = () => {
      if (!suggestResult.value.length || focusedSuggestIndex.value === 0) return

      if (focusedSuggestIndex.value === null) {
        // 選択肢が何もフォーカスされていない時に「↑」を押下した場合は選択肢の一番最後をフォーカスする
        focusedSuggestIndex.value = suggestResult.value.length - 1
        suggestList.value.scrollTop = listItemOfFocusedSuggestIndex.value?.offsetTop || 0
        return
      }
      focusedSuggestIndex.value--
      const scrollTop = listItemOfFocusedSuggestIndex.value?.offsetTop || 0
      if (scrollTop - suggestList.value.scrollTop > 0) return
      suggestList.value.scrollTop = scrollTop
    }

    const suggestDown = () => {
      if (
        !suggestResult.value.length ||
        focusedSuggestIndex.value === suggestResult.value.length - 1
      )
        return

      if (focusedSuggestIndex.value === null) {
        focusedSuggestIndex.value = 0
      } else {
        focusedSuggestIndex.value++
      }
      const scrollBottom =
        (listItemOfFocusedSuggestIndex.value?.offsetTop || 0) +
        (listItemOfFocusedSuggestIndex.value?.offsetHeight || 0)
      const scrollTop =
        scrollBottom > suggestList.value.offsetHeight
          ? scrollBottom - suggestList.value.offsetHeight
          : 0
      if (scrollTop - suggestList.value.scrollTop < 0) return
      suggestList.value.scrollTop = scrollTop
    }

    const onKeyDown = e => {
      isShowSuggests.value = true

      const keyCode = e.keyCode
      switch (keyCode) {
        case 9: // Tab
          isShowSuggests.value = false
          emit('blur-input')
          break
        case 13: // Enter
          if (focusedSuggestIndex.value === null) break
          select(suggestResult.value[focusedSuggestIndex.value])
          break
      }
    }

    const onKeyUp = e => {
      const keyCode = e.keyCode
      switch (keyCode) {
        case 27: // Escape
          clearInput()
          break
        case 38: // 上
          e.preventDefault()
          suggestUp()
          break
        case 40: // 下
          e.preventDefault()
          suggestDown()
          break
      }
    }

    const onMouseOverSuggest = (index: number) => {
      focusedSuggestIndex.value = index
    }
    const onMouseLeaveSuggest = () => {
      focusedSuggestIndex.value = null
    }

    const isFocusedSuggest = (index: number) => {
      return focusedSuggestIndex.value === index
    }

    const clearInput = () => emit('input', '')

    const select = (suggestItem: SuggestItem) => {
      emit('select', suggestItem.name)
      if (props.allowClearInput) {
        clearInput()
      }
      isShowSuggests.value = false
      onMouseLeaveSuggest()
    }

    return {
      dSuggest,
      suggestList,
      innerValue,
      isShowClearButton,
      isShowSuggests,
      suggestResult,
      onFocusInput,
      onBlurInput,
      onKeyDown,
      onKeyUp,
      onMouseOverSuggest,
      onMouseLeaveSuggest,
      isFocusedSuggest,
      clearInput,
      select
    }
  }
})
