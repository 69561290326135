<template>
  <div class="c-categoryList">
    <div class="c-categoryList_heading">
      {{ headingTitle }}
    </div>
    <ul
      class="c-categoryList_list"
      v-intersect-translate.once
    >
      <!-- PRO認定 -->
      <li
        class="c-categoryList_listItem c-categoryList_listItem-pro"
        :class="{ 'c-categoryList_listItem-opened': isOpened(0) }"
      >
        <component
          :is="isPC ? 'nuxt-link' : 'div'"
          class="c-categoryList_listItemLink"
          :to="`/search?${
            isFromSearchService ? 'ref_c=1&' : 'ref=top_categories&search_form=sidebar_category_pc&'
          }pro_priority_display=1`"
          @click="toggleCategoryAccordion($event, 0)"
        >
          <span>
            <img
              class="c-categoryList_listItemIcon c-categoryList_listItemIcon-certification"
              :src="$coconala.fileURL('/staticfiles/images/certification/pro/icon.svg')"
              alt="PRO"
              loading="lazy"
              aria-hidden="true"
            />
            <span class="c-categoryList_listItemName">PRO認定</span>
          </span>
          <CoconalaIcon
            class="c-categoryList_listItemChevron"
            :class="{ 'c-categoryList_listItemChevron-link': isPC }"
            name="chevron-right"
            :size="isSP ? '12px' : '10px'"
            color="ds2Gray600"
          />
        </component>
        <SubCategoryList
          v-if="isPC"
          class="c-categoryList_subCategories"
          :category="proCertificatedCategory"
          is-pro-cert
          :legal-url="legalUrl"
        />
        <CollapseSubCategories
          v-if="ssrSubCategoriesSpTb || isSP || isTB"
          class="c-categoryList_collapseSubCategories"
          :category="proCertificatedCategory"
          :is-opened="isOpened(0)"
          is-pro-cert
          :is-from-search-service="isFromSearchService"
          :legal-url="legalUrl"
          :is-business-before-login="isBusinessBeforeLogin"
          :defer-search-queries="deferSearchQueries"
        />
      </li>
      <li
        v-if="!isBusiness && !isBusinessBeforeLogin"
        class="c-categoryList_listItem"
        :class="{ 'c-categoryList_listItem-opened': isOpened('useScenes') }"
      >
        <div
          class="c-categoryList_listItemLink c-categoryList_listItemLink-useScenes"
          @click="toggleCategoryAccordion($event, 'useScenes')"
        >
          <span>
            <img
              class="c-categoryList_listItemIcon"
              src="~/assets/images/common/category/icon/use-scenes.svg"
              alt=""
              aria-hidden="true"
            />
            <span class="c-categoryList_listItemName">人気のご利用シーン</span>
          </span>
          <CoconalaIcon
            class="c-categoryList_listItemChevron"
            name="chevron-right"
            :size="isSP ? '12px' : '10px'"
            color="ds2Gray600"
          />
        </div>
        <SubCategoryListUseScenes
          v-if="isPC"
          class="c-categoryList_subCategories"
        />
        <CollapseSubCategoriesUseScenes
          v-if="ssrSubCategoriesSpTb || isSP || isTB"
          class="c-categoryList_collapseSubCategories"
          :is-opened="isOpened('useScenes')"
        />
      </li>
      <li
        v-for="category in categories"
        :key="category.id"
        class="c-categoryList_listItem"
        :class="{ 'c-categoryList_listItem-opened': isOpened(category.id) }"
      >
        <component
          :is="categoryTag(category)"
          class="c-categoryList_listItemLink"
          :to="`/categories/${category.id}${
            isFromSearchService ? '?ref_c=1' : '?ref=top_categories'
          }`"
          @click="toggleCategoryAccordion($event, category.id)"
        >
          <span class="c-categoryList_listItemWrapper">
            <CoconalaIcon
              class="c-categoryList_listItemIcon"
              :name="`category-${category.romaji}`"
              size="20px"
              color="ds2Gray900"
              aria-hidden="true"
            />
            <span class="c-categoryList_listItemName">{{ category.name }}</span>
            <DNewLabel
              v-if="category.isNewLabel"
              class="c-categoryList_newLabel"
            />
          </span>
          <CoconalaIcon
            class="c-categoryList_listItemChevron"
            :class="{ 'c-categoryList_listItemChevron-link': isNuxtLink(category) }"
            name="chevron-right"
            :size="isSP ? '12px' : '10px'"
            color="ds2Gray600"
          />
        </component>
        <SubCategoryList
          v-if="isPC"
          class="c-categoryList_subCategories"
          :category="category"
          :parent-category-id="category.id"
          :legal-url="legalUrl"
        />
        <CollapseSubCategories
          v-if="ssrSubCategoriesSpTb || isSP || isTB"
          class="c-categoryList_collapseSubCategories"
          :category="category"
          :parent-category-id="category.id"
          :is-opened="isOpened(category.id)"
          :is-from-search-service="isFromSearchService"
          :legal-url="legalUrl"
          :is-business-before-login="isBusinessBeforeLogin"
          :defer-search-queries="deferSearchQueries"
        />
      </li>
      <!-- 法律相談 -->
      <li
        v-if="!privateAccountInfo.isMzhc"
        class="c-categoryList_listItem"
      >
        <a
          class="c-categoryList_listItemLink"
          :href="legalUrl"
          target="_blank"
        >
          <span>
            <CoconalaIcon
              class="c-categoryList_listItemIcon"
              name="category-legal"
              size="20px"
              color="gray800"
              aria-hidden="true"
            />
            <span class="c-categoryList_listItemName">弁護士検索・法律Q&amp;A（法律相談）</span>
          </span>
          <CoconalaIcon
            name="external-link"
            :size="isSP ? '12px' : '10px'"
            color="ds2Gray600"
          />
        </a>
      </li>
      <li
        v-if="!privateAccountInfo.isMzhc && $translate.showJapaneseOnly()"
        class="c-categoryList_listItem"
      >
        <a
          class="c-categoryList_listItemLink"
          :href="aiAssistUrl"
          target="_blank"
        >
          <span>
            <CoconalaIcon
              class="c-categoryList_listItemIcon"
              name="comment-w"
              size="20px"
              color="gray800"
              aria-hidden="true"
            />
            <span class="c-categoryList_listItemName">購入アシストで探す</span>
          </span>
          <CoconalaIcon
            name="external-link"
            :size="isSP ? '12px' : '10px'"
            color="ds2Gray600"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

/**
 * @type {import('vue').ComponentOptions}
 */
const categoryList = {
  name: 'CategoryList',
  components: {
    SubCategoryList: () => import('./components/SubCategoryList'),
    SubCategoryListUseScenes: () => import('./components/SubCategoryListUseScenes'),
    CollapseSubCategories: () => import('./components/CollapseSubCategories'),
    CollapseSubCategoriesUseScenes: () => import('./components/CollapseSubCategoriesUseScenes'),
    DNewLabel: () => import('~/components/atoms/DS2/DNewLabel')
  },
  props: {
    openedCategoryIds: {
      type: Array,
      default: () => []
    },
    ssrSubCategoriesSpTb: {
      type: Boolean,
      default: false
    },
    isFromSearchService: {
      type: Boolean,
      default: false
    },
    isBusiness: {
      type: Boolean,
      default: false
    },
    isBusinessBeforeLogin: {
      type: Boolean,
      default: false
    },
    // 法律相談のリンクのコンバージョンクリック元
    cnClicked: {
      type: String
    },
    deferSearchQueries: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('master', ['masterCategories']),
    ...mapState('my/header', ['privateAccountInfo']),
    ...mapState('auth', ['user']),
    ...mapGetters('master', ['businessMasterCategories']),
    ...mapGetters('ui', ['isPC', 'isSP', 'isTB']),
    categories() {
      return this.isBusiness || this.privateAccountInfo.isMzhc
        ? this.businessMasterCategories
        : this.masterCategories
    },
    proCertificatedCategory() {
      const subCategories =
        this.isBusiness || this.privateAccountInfo.isMzhc
          ? this.masterCategories.filter(c => c.isPro && c.businessFlag)
          : this.masterCategories.filter(c => c.isPro)
      return {
        name: 'PRO認定',
        romaji: 'pro',
        subCategories
      }
    },
    headingTitle() {
      // PCの場合はFirst Viewで見える位置にあるため、
      // デバイス判定前後でテキストが変わらないようにする
      return this.isSP || this.isTB
        ? this.$t('search_service.sp_heading_title')
        : this.$t('search_service.pc_heading_title')
    },
    legalUrl() {
      return this.$coconala.legalUrl(this.cnClicked, this.isPC, this.isBusiness)
    },
    aiAssistUrl() {
      const params = {
        user_id: this.user.id || undefined,
        ref: this.isSP || this.isTB ? 'categories' : 'toppage_left'
      }
      return this.$coconala.aiAssistUrl(params)
    }
  },
  methods: {
    /**
     * @param {Event} e
     * @param {number} categoryId
     */
    toggleCategoryAccordion(e, categoryId) {
      if (!this.isPC) {
        const idx = this.openedCategoryIds.indexOf(categoryId)
        if (idx >= 0) {
          this.openedCategoryIds.splice(idx, 1)
        } else {
          this.openedCategoryIds.push(categoryId)
        }
      }
    },
    isOpened(categoryId) {
      return this.openedCategoryIds.includes(categoryId)
    },
    isNuxtLink(category) {
      return category.subCategories.length === 0 || this.isPC
    },
    categoryTag(category) {
      return this.isNuxtLink(category) ? 'nuxt-link' : 'div'
    }
  }
}
export default categoryList
</script>

<style lang="scss" scoped>
.c-categoryList {
  &_heading {
    padding-bottom: 4px;
    border-bottom: 1px solid $ds2-color-gray-100;
    color: $ds2-color-gray-900;
    font-size: 12px;
    line-height: 1.5em;
  }
  &_list {
    margin-top: 8px;
  }
  &_listItem {
    position: relative;
  }
  &_listItem:hover > &_subCategories {
    display: block;
  }
  &_listItemLink {
    @include link-effect;

    position: relative;
    display: flex;
    padding: 4px 8px 4px 0;
    color: $color-black;
    line-height: 24px;

    align-items: center;
    justify-content: space-between;
    &:hover {
      background-color: $ds2-color-gray-25;
    }

    &-useScenes {
      cursor: pointer;
    }
  }
  &_listItemIcon {
    display: none;
  }
  &_listItemWrapper {
    position: relative;
    display: flex;

    align-items: center;
  }
  &_newLabel {
    margin-left: 4px;
  }
  &_subCategories {
    position: absolute;
    top: -58px;
    left: calc(100% - 1px);
    display: none;
  }
  &_collapseSubCategories {
    display: none;
  }
}
@media (max-width: breakpoint(Home, M)) {
  .c-categoryList {
    &_heading {
      @include typography-label-medium;

      padding: 16px 8px 8px;
      border-bottom: none;
      color: $ds2-color-gray-900;
    }
    &_list {
      margin-top: 0;
      background-color: $ds2-color-white;
    }
    &_listItem {
      &-opened {
        border-bottom: 1px solid $ds2-color-gray-25;
      }
    }
    &_listItemLink {
      padding: 0 12px;
      height: 44px;
      border-bottom: 1px solid $ds2-color-gray-25;
      background-color: $ds2-color-white;
      color: $ds2-color-gray-900;
      &:hover {
        background-color: $ds2-color-white;
      }
    }
    &_listItemIcon {
      display: initial;
      margin-top: 2px;
      margin-right: 8px;
      width: 20px;
      &-certification {
        margin-right: 4px;
      }
    }
    &_listItemName {
      vertical-align: -2px;
    }
    &_listItemChevron {
      transition: transform 0.3s ease-out;
      transform: rotateZ(90deg);
      &-link {
        transform: rotateZ(0);
      }
    }
    &_listItem-opened &_listItemChevron {
      transform: rotateZ(-90deg);
    }
    &_collapseSubCategories {
      display: block;
    }
    &_newLabel {
      margin-left: 8px;
    }
  }
}
</style>
