<template>
  <HomeCarousel
    :slides-count="slidesCount"
    class="c-carouselServiceList"
  >
    <ServiceCassette
      v-for="(service, index) in services"
      :key="`service${index}`"
      class="c-carouselServiceList_item"
      :service="service"
      :item-number="index + 1"
      :is-ranking="isRanking"
    />
  </HomeCarousel>
</template>

<script>
import ServiceCassette from '~/components/molecules/ServiceCassette'
import HomeCarousel from './HomeCarousel'

const ITEMS_PER_SLIDE = 6

/** @type {import('vue').ComponentOptions} */
const carouselServiceList = {
  name: 'CarouselServiceList',
  components: {
    ServiceCassette,
    HomeCarousel
  },
  props: {
    services: {
      type: Array,
      required: true
    },
    isRanking: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    slidesCount() {
      return Math.ceil(this.services.length / ITEMS_PER_SLIDE)
    }
  }
}
export default carouselServiceList
</script>

<style lang="scss" scoped>
.c-carouselServiceList {
  width: 959px;

  &_item {
    flex: 0 0 auto;
    margin-right: 7px;
    width: 153px;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-carouselServiceList {
    width: 100%;

    &_item {
      width: 138px;
    }
  }
}
</style>
