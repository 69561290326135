<template>
  <OModal
    class="d-modal"
    :class="{
      'd-modal-full': isSpFullScreen,
      'd-modal-isRounded': isRounded,
      'd-modal-isOverflowVisible': isOverflowVisible,
      'd-modal-isBackgroundInvisible': isBackgroundInvisible,
      'd-modal-isOnGuide': isOnGuide
    }"
    :animation="animationName"
    :active="active"
    content-class="modal-content"
    v-bind="$attrs"
    @close="close"
    @after-leave="$emit('after-leave')"
  >
    <div
      class="d-modal_title"
      v-intersect-translate.once
    >
      <span v-html="$sanitize(title, sanitizeOptions)"></span>
      <slot name="after-title"></slot>
    </div>
    <p
      v-if="$slots.text"
      class="d-modal_textBelowTitle"
    >
      <slot name="text"></slot>
    </p>
    <div
      id="modal_contentWrapper"
      class="d-modal_contentWrapper"
      v-intersect-translate.once
    >
      <div class="d-modal_content">
        <slot />
      </div>
    </div>
    <!--
      TODO アイコンFIX後に差し替え
      https://www.figma.com/file/bpRorPtaeZqtwq84n6Ytxf/PJ_%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88?node-id=1755%3A910
     -->
    <button
      v-if="hasBackButton"
      class="d-modal_backButton"
      @click="back"
    >
      <CoconalaIcon
        class="d-modal_backButtonIcon"
        name="arrow-back"
        size="20px"
      />
    </button>
    <div
      class="d-modal_footerWrapper"
      v-if="this.$slots.stickyButtonsFooter"
      v-intersect-translate.once
    >
      <div class="d-modal_footer">
        <slot name="stickyButtonsFooter"></slot>
      </div>
    </div>
  </OModal>
</template>

<script>
import Vue from 'vue'
import sanitizeHTML from 'sanitize-html'

export const ANIMATION_TYPES = Object.freeze(['', 'none', 'zoomIn', 'slowly'])

Vue.prototype.$sanitize = sanitizeHTML

const sanitizeOptions = {
  allowedTags: ['br'],
  allowedClasses: {
    // isSPは共通ユーティリティ作成する場合変更の必要あり(cssも含め)
    br: ['isPC', 'isSP']
  }
}

/** @type {import('vue').ComponentOptions} */
const dModal = {
  name: 'DModal',
  props: {
    title: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    hasBackButton: {
      type: Boolean,
      default: false
    },
    isSpFullScreen: {
      type: Boolean,
      default: true
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    isOverflowVisible: {
      type: Boolean,
      default: false
    },
    closeOnBack: {
      type: Boolean,
      default: true
    },
    animation: {
      type: String,
      default: '',
      validator(v) {
        return ANIMATION_TYPES.includes(v)
      }
    },
    isBackgroundInvisible: {
      type: Boolean,
      default: false
    },
    isOnGuide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sanitizeOptions,
      alreadyExistedModalFlag: false
    }
  },
  computed: {
    animationName() {
      switch (this.animation) {
        case 'none':
          return 'd-modalAnimationNone'
        case 'zoomIn':
          return 'd-modalAnimationScaleUp'
        case 'slowly':
          return 'd-modalAnimationSlowly'
        default:
          return 'd-modalAnimation'
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.$emit('update:active', false)
    },
    back() {
      this.$emit('back')
      if (this.closeOnBack) {
        this.$emit('update:active', false)
      }
    }
  },
  mounted() {
    // モーダル読み込みと同時にactiveがtrueとなる場合は、activeの初期値がtrueとなりwatchが効かない為ここでスクロール制御する
    if (this.active) document.body.classList.add('is-noscroll')
  },
  beforeDestroy() {
    // モーダルをv-if切り替えで開閉する場合を考慮して、スクロール固定の解除を入れておく
    document.body.classList.remove('is-noscroll')
  },
  watch: {
    active(v) {
      if (v) {
        // モーダルonモーダルの場合には一番下の画面のスクロール制御を変更したくない為、既にモーダルがあるかどうかを判定
        if (document.body.classList.contains('is-noscroll')) {
          this.alreadyExistedModalFlag = true
          return
        }

        // モーダルがない場合のみ'is-noscroll'クラスを適用する
        if (!this.alreadyExistedModalFlag) {
          this.$nextTick(() => {
            document.body.classList.add('is-noscroll')
          })
        }
      } else {
        // モーダルがない場合のみ'is-noscroll'クラスを取り除く
        if (!this.alreadyExistedModalFlag) document.body.classList.remove('is-noscroll')
        this.alreadyExistedModalFlag = false

        setTimeout(() => {
          this.$emit('after-leave')
        }, 150)
      }
    }
  }
}
export default dModal
</script>

<style lang="scss" scoped>
.d-modal {
  &_title {
    display: flex;
    padding: 24px 46px;
    color: $ds2-color-gray-900;
    letter-spacing: 0.5px;
    font-weight: bold;
    font-size: 20px;

    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    ::v-deep .isPC {
      display: block;
    }
    ::v-deep .isSP {
      display: none;
    }
    &:has(+ .d-modal_textBelowTitle) {
      padding-bottom: 16px;
    }
  }
  &_textBelowTitle {
    margin: 0;
  }
  &_backButton {
    position: absolute;
    top: 15px;
    left: 12px;
    border: 0;
    background: transparent;
    color: $ds2-color-gray-600;
  }
  &_backButtonIcon {
    &::before {
      border-radius: 50%;
    }
    &:hover {
      &::before {
        background-color: $ds2-color-gray-200;
      }
    }
  }
  &_contentWrapper {
    overflow-y: auto;
  }
  &_content {
    padding: 0 24px 24px;
  }
  &-isRounded {
    ::v-deep .animation-content {
      border-radius: 8px;
    }
  }
  &-isOverflowVisible {
    .d-modal_contentWrapper {
      overflow: visible !important;
    }
    ::v-deep .animation-content {
      overflow: visible !important;
    }
  }
  &-isBackgroundInvisible {
    ::v-deep .modal-background {
      background: none;
    }

    ::v-deep .animation-content {
      box-shadow: 0 2px 6px rgba(2, 16, 66, 0.1);
    }
  }
  &-isOnGuide {
    z-index: z(popup-on-guide, overlay);
  }

  ::v-deep {
    .animation-content {
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin: 0 auto;
      // OModalに依ってstyle属性に設定されてるためimportantにしないと効かない
      max-width: calc(100vw - 32px) !important;
      max-height: calc(100vh - 32px);
      width: 568px;
      background-color: $ds2-color-white;
    }
    .modal-close {
      position: absolute;
      top: 16px;
      right: 16px;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      width: 20px;
      height: 20px;
      &::after,
      &::before {
        background-color: $ds2-color-gray-600;
      }
      &::before {
        width: 100%;
      }
      &::after {
        height: 100%;
      }
    }
    &.is-active {
      display: flex;
    }
  }
  &_footer {
    display: flex;
    padding: 12px 0;

    justify-content: space-around;
  }
}

@media (max-width: $ds2-breakpoint-s) {
  .d-modal {
    &_title {
      font-size: 16px;
      ::v-deep .isPC {
        display: none;
      }
      ::v-deep .isSP {
        display: block;
      }
    }
    &_content {
      margin-top: 16px;
      padding-right: 16px;
      padding-left: 16px;
    }
    &-full {
      ::v-deep {
        .animation-content {
          max-width: 100vw !important;
          max-height: 100vh;
          width: 100vw;
          height: 100vh;
        }
      }
      &.d-modal-isRounded {
        ::v-deep .animation-content {
          border-radius: 0;
        }
      }
    }
  }
}
</style>

<style lang="scss">
// ディープセレクタを使用してもスタイルがあたらないためここに定義
.d-modal {
  &.d-modalAnimation {
    &-enter-active,
    &-leave-active {
      display: flex;
      transition: opacity 0.15s ease-out;
      .animation-content {
        transition: transform 0.15s ease-out;
      }
    }

    &-enter,
    &-leave-to {
      opacity: 0;
      .animation-content {
        transform: scale(1.05);
      }
    }
  }
  // アニメーションなしのモーダル表示
  &.d-modalAnimationNone {
    &-enter-active,
    &-leave-active {
      transition: none;
      .animation-content {
        transition-property: none;
      }
    }
    &-enter,
    &-leave-to {
      .animation-content {
        transform: none;
      }
    }
  }
  // ZOOMINするアニメーションのモーダル表示
  &.d-modalAnimationScaleUp {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.15s ease-out;
      .animation-content {
        transition: transform 0.15s ease-out;
      }
    }

    &-enter,
    &-leave-to {
      opacity: 0;
      .animation-content {
        transform: scale(0.95);
      }
    }
  }

  // ゆっくり開くアニメーションのモーダル表示
  &.d-modalAnimationSlowly {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.5s ease-out;
      .animation-content {
        transition: transform 0.5s ease-out;
      }
    }

    &-enter,
    &-leave-to {
      opacity: 0;
      .animation-content {
        transform: scale(1.05);
      }
    }
  }
}

@media (max-width: $ds2-breakpoint-s) {
  .d-modal {
    &-full {
      &.d-modalAnimation {
        &-enter-active,
        &-leave-active {
          transition: all 0.2s ease-out;
          transform: translateY(0);
          .animation-content {
            transition-property: none;
          }
        }
        &-enter,
        &-leave-to {
          transform: translateY(100vh);
          .animation-content {
            transform: none;
          }
        }
      }
    }
  }
}
</style>
