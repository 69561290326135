// source: domain/enumeration/category_ranking_default_sort.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coconala.protobuf.domain.enumeration.CategoryRankingDefaultSort', null, global);
/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.enumeration.CategoryRankingDefaultSort = {
  CATEGORY_RANKING_DEFAULT_SORT_UNSPECIFIED: 0,
  CATEGORY_RANKING_DEFAULT_SORT_RECOMMEND: 1,
  CATEGORY_RANKING_DEFAULT_SORT_NEW: 2,
  CATEGORY_RANKING_DEFAULT_SORT_RANKING: 3,
  CATEGORY_RANKING_DEFAULT_SORT_FAVORITE: 4,
  CATEGORY_RANKING_DEFAULT_SORT_BEGINNER: 5
};

goog.object.extend(exports, proto.coconala.protobuf.domain.enumeration);
