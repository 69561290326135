import { useFetch, watch, useContext } from '@nuxtjs/composition-api'
import { NuxtError } from '@nuxt/types'

export default (callback: () => Promise<void>): ReturnType<typeof useFetch> => {
  const { error } = useContext()
  const result = useFetch(callback)

  watch(
    () => result.fetchState.error,
    () => {
      // NOTE: エラー画面でheaderを出すためにCSRでerror関数を呼び出す
      if (process.client) {
        const { statusCode, grpcCode } = result.fetchState.error as NuxtError
        error({
          statusCode,
          grpcCode
        })
      }
    }
  )

  return result
}
