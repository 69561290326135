/** 人気のご利用シーン画像サイズ */
const USE_SCENES_IMAGE_SIZES = Object.freeze({
  pc: '464x260',
  sp: '568x318'
})

/** @type {import('vue').ComponentOptions} */
const popularUseScenesImageResizeMixin = {
  computed: {
    resizedImageUrlPrefix() {
      const baseUrl = process.env.config['coconala']['image-url']
      return `${baseUrl}/resize/use_scene_crop/`
    }
  },
  methods: {
    /**
     * リサイズされた画像URLを取得
     * @param {string} imagePath APIから返却された画像URLの相対パス
     * @param {'pc'|'sp'} device デバイス指定
     * @returns {string} 画像URL
     */
    getResizedImageUrl(imagePath, device = 'pc') {
      const size = USE_SCENES_IMAGE_SIZES[device]
      return `${this.resizedImageUrlPrefix}${size}${imagePath}`
    }
  }
}
export default popularUseScenesImageResizeMixin
