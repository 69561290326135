<template>
  <NuxtLink
    :to="$coconala.rootPathUrl(`/blogs/${blog.user.id}/${blog.id}`, linkRefs)"
    class="c-blogItem"
  >
    <div class="c-blogItemTitle">
      {{ blog.title }}
    </div>

    <div class="c-blogItemCategory">
      <span>{{ blog.blogCategoryName }}</span>
    </div>

    <div class="c-blogItemInfo">
      <div class="c-blogItemInfo_left">
        <div class="c-blogItemInfo_price">
          <span v-if="blog.paidFg">{{ blog.price | withDelimiter }}円</span>
        </div>
        <div class="c-blogItemInfo_user">
          <CUserIconV2
            :file-path="blog.user.profileIconPath"
            :size="26"
            :v-if="!isSkeleton"
          />
          <div class="c-blogItemInfo_writerDetail">
            <div class="c-blogItemInfo_writerName">{{ $util.truncate(blog.user.name, 6) }}</div>
            <div class="c-blogItemInfo_postDate">
              {{ $util.dayjs.unix(blog.openedAt).format('YYYY/MM/DD') }}
            </div>
          </div>
        </div>
      </div>
      <div class="c-blogItemInfo_right">
        <img
          :src="$coconala.blogCoverImageURL(blog.resizableCoverImagePath, 210, 118)"
          alt=""
          class="c-blogItemInfo_coverImage"
          loading="lazy"
          decoding="async"
          v-if="!isSkeleton && blog.resizableCoverImagePath"
        />
      </div>
    </div>
  </NuxtLink>
</template>
<script>
import { mapState } from 'vuex'
import LinkRefInjectee from '../mixins/LinkRefInjectee'

export default {
  name: 'CarouselBlogItem',
  mixins: [LinkRefInjectee],
  props: {
    blog: {
      type: Object,
      required: true
    },
    itemNumber: {
      type: Number,
      default: null
    },
    isSkeleton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('pages/blogs', ['constants']),
    kindText() {
      return this.$store.getters['pages/blogs/constants/kindText'](this.blog.kind)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-blogItem {
  @include link-effect;

  position: relative;
  display: block;
  overflow: hidden;
  padding: 16px 16px 12px;
  width: 231px;
  height: 140px;
  border: 1px solid $ds2-color-gray-50;
}

.c-blogItemTitle {
  @include line-clamp(2, 1.6em);

  color: $ds2-color-gray-900;
  font-weight: bold;
  font-size: 14px;
}

.c-blogItemCategory {
  overflow: hidden;
  margin: 4px 0 8px;
  color: $ds2-color-gray-400;
  font-size: 10px;
  line-height: 1;
}

.c-blogItemInfo {
  display: flex;
  margin-top: 4px;
  color: $ds2-color-gray-900;
  font-size: 12px;

  justify-content: space-between;

  &_right {
    flex: 0 0 auto;
    margin-left: 8px;
    max-width: 70px;
  }

  &_price {
    display: flex;
    margin-bottom: 4px;
    height: 10px;
    color: $ds2-color-gray-900;
    font-weight: bold;
    font-size: 10px;
    line-height: 1;

    align-items: center;
  }

  &_user {
    display: flex;

    align-items: center;
  }

  &_writerDetail {
    margin-left: 4px;
    line-height: 1;
  }

  &_writerName {
    color: $ds2-color-gray-900;
    font-size: 11px;
  }

  &_postDate {
    margin-top: 4px;
    color: $ds2-color-gray-400;
    font-size: 10px;
  }

  &_coverImage {
    width: 70px;
    border-radius: 3px;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
}
</style>
