<template>
  <div
    class="c-popularUseScenesSwiper swiper-container"
    v-intersect-on
    @intersect="initSwiper"
  >
    <div
      class="c-popularUseScenesSwiper_wrapper swiper-wrapper"
      :class="{ 'c-popularUseScenesSwiper_wrapper-beforeInit': !isInitialized }"
    >
      <a
        class="c-popularUseScenesSwiper_slide swiper-slide"
        :class="{ 'c-popularUseScenesSwiper_slide-final': isFinalSlide(idx) }"
        v-for="(useScene, idx) in useScenesList"
        :href="useScene.url"
        :key="idx"
      >
        <img
          class="c-slideContent"
          :src="getResizedImageUrl(useScene.image, 'sp')"
          :alt="useScene.title"
          loading="lazy"
          decoding="async"
        />
      </a>
    </div>
    <div class="c-popularUseScenesSwiper_swiperPagination"></div>
  </div>
</template>

<script>
import PopularUseScenesImageResizeMixin from './PopularUseScenesImageResizeMixin'
import Swiper from 'swiper/dist/js/swiper.esm.bundle'

export default {
  name: 'PopularUseScenesSwiper',
  mixins: [PopularUseScenesImageResizeMixin],
  data() {
    return {
      swiper: null,
      isInitialized: false
    }
  },
  props: {
    useScenesList: {
      type: Array,
      required: true
    }
  },
  methods: {
    getSwiperOptions() {
      return {
        loop: true,
        slidesPerView: 'auto',
        loopedSlides: 5,
        centeredSlides: true,
        pagination: {
          el: '.c-popularUseScenesSwiper_swiperPagination'
        },
        navigation: {
          disabledClass: 'c-swiper-button-disabled'
        },
        on: {
          init: () => {
            this.isInitialized = true
          }
        }
      }
    },
    isFinalSlide(index) {
      return index === this.useScenesList.length - 1
    },
    initSwiper() {
      if (!this.swiper) {
        this.swiper = new Swiper(this.$el, this.getSwiperOptions())
      }
    }
  },
  beforeDestroy() {
    this.swiper?.destroy()
  }
}
</script>

<style lang="scss" scoped>
@use 'sass:math';

$slide-width: 284px;

.c-popularUseScenesSwiper {
  width: 100%;
  &_swiperPagination {
    display: flex;
    justify-content: center;
    padding-top: 16px;
    ::v-deep .swiper-pagination-bullet {
      margin: 0 6px;
      background-color: $ds2-color-gray-200;
      &-active {
        background-color: $ds2-color-gray-600;
      }
    }
  }
  &_slide {
    margin: 0 4px;
  }
  &_wrapper {
    &-beforeInit {
      // Swiper初期化前のスライド位置調整
      // 画面幅の半分 - (スライド幅1.5枚分の幅 + スライド間のマージン分調整)
      margin-left: calc(50% - #{math.div($slide-width * 3, 2) + 12px});
      .c-popularUseScenesSwiper_slide {
        &-final {
          order: -1;
        }
      }
    }
  }
}
.swiper-slide {
  width: $slide-width;
  height: 159px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-slideContent {
  max-width: 100%;
  max-height: 100%;
}
</style>
